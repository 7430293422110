import React from "react";
import "./error404.scss";

export const Error404 = () => {
  return (
    <div className="container__error">
      Lo sentimos, no hemos encontrado este página
    </div>
  );
};
