import React from "react";
import "./sucess.scss";

export const Sucess = () => {
  return (
    <div className="sucess">
      {/* <button onClick={closeModal}>X</button> */}
      <img
        src="https://user-images.githubusercontent.com/55818246/138987691-6cb740f4-23a4-445e-a8d6-45ff1613b024.png"
        alt=""
      />
    </div>
  );
};
