import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectCoverflow, Pagination } from "swiper/core";
import { NavLink } from "react-router-dom";
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "./homeslider.scss";
import slide0 from "../../assets/img/home/planeta-teleton23.png";
import slide1 from "../../assets/img/home/luna-de-juegos-min.png";
import slide2 from "../../assets/img/home/academia-solar-min.png";
// import slide3 from "../../assets/img/nabilzpap2022/PLANETA4.png";
//import slide3 from "../../assets/img/home/planeta-reciclaje-min.png";
//import slide4 from "../../assets/img/animales/animales-planeta-min.png";
SwiperCore.use([EffectCoverflow, Pagination]);

export const HomeSlider = () => {
  return (
    <div>
      <Swiper
        init={false}
        loop={true}
        speed={800}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        coverflowEffect={{
          rotate: 30,
          stretch: 0,
          depth: 200,
          modifier: 1,
        }}
        pagination={true}
        breakpoints={{
          320: {
            slidesPerView: 1.5,
            spaceBetween: 5,
          },
          375: {
            slidesPerView: 1.4,
            spaceBetween: 5,
          },
          1023: {
            slidesPerView: 2.5,
            spaceBetween: 0,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <NavLink to="/teleton23">
            <img src={slide0} alt="" />
          </NavLink>
        </SwiperSlide>
        <SwiperSlide>
          <NavLink to="/luna-de-juegos">
            <img src={slide1} alt="" />
          </NavLink>
        </SwiperSlide>
        <SwiperSlide>
          <NavLink to="/academia-solar">
            <img src={slide2} alt="" />
          </NavLink>
        </SwiperSlide>

        {/* <SwiperSlide>
          <NavLink to="/planeta-reciclaje">
            <img src={slide3} alt="" />
          </NavLink>
        </SwiperSlide> */}
      </Swiper>
    </div>
  );
};
