export const data = {
  "courses": [
    {
      "id": "1",
      "titleImg": "https://user-images.githubusercontent.com/55818246/132407379-95e7de3d-7287-45b4-8550-8d905cb51b29.png",
      "cards": [
        {
          "id": "1",
          "url": "https://www.youtube.com/embed/9zqAwvW7fqQ?rel=0",
          "titleDescription": "EXPERIMENTOS DIVERTIDOS",
          "description": "¡En este taller aprenderás cómo hacer increíbles experimentos y lo mejor es que podrás hacerlos en tu casa!"
        },
        {
          "id": "2",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "3",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "4",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "5",
          "url": "",
          "titleDescription": "",
          "description": ""
        }
      ],
      "questionary": [
        {
          "id": "",
          "question": "",
          "answers": "",
          "value": ""
        }
      ],
      "form": {
        "url": "",
        "certificate": ""
      }
    },
    {
      "id": "2",
      "titleImg": "",
      "cards": [
        {
          "id": "1",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "2",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "3",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "4",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "5",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "5",
          "url": "",
          "titleDescription": "",
          "description": ""
        }
      ],
      "questionary": [
        {
          "id": "",
          "question": "",
          "answers": "",
          "value": ""
        }
      ],
      "form": {
        "url": "",
        "certificate": ""
      }
    },
    {
      "id": "3",
      "titleImg": "",
      "cards": [
        {
          "id": "1",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "2",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "3",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "4",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "5",
          "url": "",
          "titleDescription": "",
          "description": ""
        }
      ],
      "questionary": [
        {
          "id": "",
          "question": "",
          "answers": "",
          "value": ""
        }
      ],
      "form": {
        "url": "",
        "certificate": ""
      }
    },
    {
      "id": "4",
      "titleImg": "",
      "cards": [
        {
          "id": "1",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "2",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "3",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "4",
          "url": "",
          "titleDescription": "",
          "description": ""
        },
        {
          "id": "5",
          "url": "",
          "titleDescription": "",
          "description": ""
        }
      ],
      "questionary": [
        {
          "id": "",
          "question": "",
          "answers": "",
          "value": ""
        }
      ],
      "form": {
        "url": "",
        "certificate": ""
      }
    }
  ]
}
