import React from "react";
import "./academy.scss";
import { NavLink } from "react-router-dom";
import { PlanetHome } from "../../SmartComponents/PlanetHome/PlanetHome";

export const Academy = () => {
  return (
    <div className="container__academy">
      <div className="container__academy__hero">
        <NavLink to="/cursos">
          <PlanetHome
            planet="https://user-images.githubusercontent.com/55818246/131954425-bbea211c-8109-414c-acc0-4f93e6c11fa3.png"
            text="¡Saca todo el talento que llevas dentro en este increíble planeta
            lleno de talleres para toda la familia!"
          />
        </NavLink>
        <NavLink to="/">
          <button className="return">
            <img
              src="https://user-images.githubusercontent.com/55818246/138624865-eb5ec3e3-586d-4a60-8364-b1747c38174c.png"
              alt=""
            />
          </button>
        </NavLink>
      </div>
    </div>
  );
};
