import React from "react";
import "./card.scss";

export const Card = (props) => {
  return (
    <div className="workshop__card">
      <div className="workshop__card--video">
        <iframe
          loading="lazy"
          src={props.workshopVideo}
          title="YouTube video"
          width="100%"
          height="100%"
          allowFullScreen
        ></iframe>
      </div>
      <div className="workshop__card--text">
        <h4>{props.workshopTitleText}</h4>
        <p>{props.workshoptDescriptionText}</p>
      </div>
    </div>
  );
};
