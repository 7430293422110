import React, { useState } from "react";
import { KitchenSlider } from "../../KitchenSlider/KitchenSlider";
import { QuestionaryKitchen } from "../../../../SmartComponents/QuestionaryKitchen/QuestionaryKitchen";
import { Form } from "../../../../SmartComponents/Form/Form";
import "./kitchen.scss";

export const Kitchen = () => {
  const [currentItem, setCurrentItem] = useState("workshop");

  return (
    <div className="kitchen">
      <div className="workshop__t">
        <img
          src="https://user-images.githubusercontent.com/55818246/138815074-cd8cf8b2-de3c-4f00-b5f3-d3a33fb76adf.png"
          alt=""
        />
      </div>
      {currentItem === "workshop" && (
        <KitchenSlider onFinished={setCurrentItem} />
      )}
      {currentItem === "questionary" && (
        <QuestionaryKitchen onFinished={setCurrentItem} />
      )}
      {currentItem === "form" && <Form onFinished={setCurrentItem} />}
    </div>
  );
};
