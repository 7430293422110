import React from "react";
import "./modal.scss";

export const Modal = ({ isOpen, closeModal, children }) => {
  const handleModalTips = (e) => {
    e.stopPropagation();
    document.getElementById("myVideoPlayer");
    document.getElementsByTagName("video")[0].pause();
  };

  return (
    <div className={`modal ${isOpen && "modalopen"}`} onClick={closeModal}>
      <div className="modal__tips" onClick={handleModalTips}>
        <button className="modal__tips--close" onClick={closeModal}>
          X
        </button>
        {children}
      </div>
    </div>
  );
};
