import React, { createContext, useState } from "react";

export const Context = createContext();

//componente Provider el cual recibe como props los children
const Provider = ({ children }) => {
//creamos el props 'courseMode' y a su vez la vamos a guardar en el LS
  const [courseMode, setCourseMode] = useState(() => {
    const val = window.localStorage.getItem("courseMode");

//este if es porque cuando obtenemos datos del LS, este viene desde un JSON lo cual se parsea como un string, pero ahora queremos un boolean
    if (!val) return true;
    else return false;
  });

//'value' es nuestro objeto con los valores y sus respectivas funciones de alteracion de los mismos aqui están todas las props que queramos compartir y las funciones para cambiar sus valores
  const value = {
    courseMode,
    activateCourseMode: (value) => {
      setCourseMode(value);
      window.localStorage.setItem("courseMode", value);
    },
  };
//finalmente retornamos el componente 'Context.Provider' y la pasamos como props el value (son las props globales que queremos)
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

//exportamos nuestro componente 'Provider', lo vamos a usar para proveer nuestro Context en la "app"
export default Provider;
