import React, { useState } from "react";
import { CraftsSlider } from "../../CraftsSlider/CraftsSlider";
import { QuestionaryCrafts } from "../../../../SmartComponents/QuestionaryCrafts/QuestionaryCrafts";
import { Form } from "../../../../SmartComponents/Form/Form";
import "./crafts.scss";

export const Crafts = (props) => {
  const [currentItem, setCurrentItem] = useState("workshop");

  return (
    <div className="crafts">
      <div className="workshop__t">
        <img
          src="https://user-images.githubusercontent.com/55818246/138815069-98963929-7433-4797-86ca-c3d8bf11192d.png"
          alt=""
        />
      </div>
      {currentItem === "workshop" && (
        <CraftsSlider onFinished={setCurrentItem} />
      )}
      {currentItem === "questionary" && (
        <QuestionaryCrafts onFinished={setCurrentItem} />
      )}
      {currentItem === "form" && <Form onFinished={setCurrentItem} />}
    </div>
  );
};
