import React, { useEffect, useRef, useState } from "react";
import "./rodolfa.scss";

import Slider from "./slider/Slider";
import ControlPanel from "./controls/ControlPanel";

import rodolfa from "../../../assets/img/animales/rodolfa.png";
import textrodolfa from "../../../assets/img/animales/txtrodolfa.png";
import sonido from "../../../assets/img/animales/sonido.png";
import audiorodolfa from "../../../assets/img/animales/BilzyPap-Rodolfa.mp3";
import logobyp from "../../../assets/img/logobyp.png";
import logoanimals from "../../../assets/img/animales/logofundacion.png";

export const Rodolfa = ({ hideHeader, handle }) => {
  // Ocultar header - footer
  useEffect(() => {
    handle(false);
    return () => handle(true);
  }, [handle]);
  useEffect(() => {
    hideHeader(false);
    return () => hideHeader(true);
  }, [hideHeader]);

  //player
  const [percentage, setPercentage] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const audioRef = useRef();

  const onChange = (e) => {
    const audio = audioRef.current;
    audio.currentTime = (audio.duration / 100) * e.target.value;
    setPercentage(e.target.value);
  };

  const play = () => {
    const audio = audioRef.current;
    audio.volume = 0.1;

    if (!isPlaying) {
      setIsPlaying(true);
      audio.play();
    }

    if (isPlaying) {
      setIsPlaying(false);
      audio.pause();
    }
  };

  const getCurrDuration = (e) => {
    const percent = (
      (e.currentTarget.currentTime / e.currentTarget.duration) *
      100
    ).toFixed(2);
    const time = e.currentTarget.currentTime;

    setPercentage(+percent);
    setCurrentTime(time.toFixed(2));
  };

  return (
    <div className="container__rodolfa">
      <main className="rodolfa">
        <section className="section section__rodolfa">
          <img className="rodolfa" src={rodolfa} alt="Rodolfa" />
          <img
            className="textrodolfa"
            src={textrodolfa}
            alt="Hola, soy Rodolfa - ¡Escucha mi historia aquí!"
          />
        </section>
        <section className="section section__player">
          <img src={sonido} alt="" />

          <div className="audioplayer">
            <Slider percentage={percentage} onChange={onChange} />
            <audio
              ref={audioRef}
              onTimeUpdate={getCurrDuration}
              onLoadedData={(e) => {
                setDuration(e.currentTarget.duration.toFixed(2));
              }}
              src={audiorodolfa}
            ></audio>
            <ControlPanel
              play={play}
              isPlaying={isPlaying}
              duration={duration}
              currentTime={currentTime}
            />
          </div>
        </section>
        <section className="section section__logo">
          <div className="logos">
            <img src={logobyp} alt="BilzyPap" />
            <img src={logoanimals} alt="" />
          </div>
        </section>
      </main>
    </div>
  );
};
