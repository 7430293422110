import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import playOne from "../../../assets/img/img-lunadejuegos/img-tuberia.png";
import playTwo from "../../../assets/img/img-lunadejuegos/img-edificio.png";
import playThree from "../../../assets/img/img-lunadejuegos/img-ola.png";
import playFour from "../../../assets/img/img-lunadejuegos/img-puente.png";
import playFive from "../../../assets/img/img-lunadejuegos/Match-de-sabor-min.jpg";
import playSix from "../../../assets/img/img-lunadejuegos/Palabras-desordenadas-min.jpg";
import playSeven from "../../../assets/img/img-lunadejuegos/El-puzzle-de-Bily-min.jpg";
import "./games.scss";
import { NavLink } from "react-router-dom";

SwiperCore.use([Pagination, Navigation]);

export const Games = () => {
  return (
    <div className="container__games">
      <h4>¡Elige uno y empieza a jugar!</h4>
      <div className="games">
        <div className="games__swiper">
          <Swiper
            navigation={true}
            className="mySwiper2"
            spaceBetween={30}
            slidesPerView={1}
            speed={800}
            grabCursor={true}
            centeredSlides={true}
            pagination={{
              clickable: true,
            }}
          >
            <SwiperSlide>
              <div className="games__card">
                <div className="games__card--game">
                  <a
                    className="games__card--img"
                    href="https://sitio.bilzypap.cl/tuberias/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={playOne} alt="" />
                  </a>
                </div>
                <div className="games__card--text">
                  <h3>Tuberías de otro mundo</h3>
                  <p>
                    ¡Bily y Maik necesitan tu ayuda para que todo salga
                    perfecto! Ordena las tuberías de otro mundo y llena las
                    botellas de Bilz y Pap para avanzar de nivel.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="games__card">
                <div className="games__card--game">
                  <a
                    className="games__card--img"
                    href="https://sitio.bilzypap.cl/edificio_infinito/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={playTwo} alt="" />
                  </a>
                </div>
                <div className="games__card--text">
                  <h3>Edificio infinito</h3>
                  <p>
                    ¡Construye el edificio más alto del mundo con la ayuda de
                    Bily y Maik, pero cuidado si colocas mal una pieza porque se
                    puede venir abajo!
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="games__card">
                <div className="games__card--game">
                  <a
                    className="games__card--img"
                    href="https://sitio.bilzypap.cl/HolaOla/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={playThree} alt="" />
                  </a>
                </div>
                <div className="games__card--text">
                  <h3>Hola ola</h3>
                  <p>
                    ¡Hora de entrar y surfear con Bily! Conviértete en el rey de
                    las olas en un juego de otro mundo.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="games__card">
                <div className="games__card--game">
                  <a
                    className="games__card--img"
                    href="https://sitio.bilzypap.cl/puentes/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={playFour} alt="" />
                  </a>
                </div>
                <div className="games__card--text">
                  <h3>Puentes de otro mundo</h3>
                  <p>
                    Maik necesita llegar a su destino y los puentes aún no están
                    listos. ¡Ayúdalo a cumplir su misión y no mires abajo!
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="games__card">
                <div className="games__card--game">
                  <a
                    className="games__card--img"
                    href="https://juegos.bilzypap.cl/match_sabor/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={playFive} alt="" />
                  </a>
                </div>
                <div className="games__card--text">
                  <h3>Match de sabor</h3>
                  <p>
                    Junta 3 figuras iguales, hacia arriba, abajo o hacia los
                    lados y haz desaparecer la mayor cantidad de filas y
                    columnas posibles.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="games__card">
                <div className="games__card--game">
                  <a
                    className="games__card--img"
                    href="https://juegos.bilzypap.cl/palabras_desordenadas/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={playSix} alt="" />
                  </a>
                </div>
                <div className="games__card--text">
                  <h3>Palabras desordenadas</h3>
                  <p>
                    Encuentra la palabra utilizando todas las letras antes de
                    que acabe el tiempo.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="games__card">
                <div className="games__card--game">
                  <a
                    className="games__card--img"
                    href="https://juegos.bilzypap.cl/palabras_desordenadas/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={playSeven} alt="" />
                  </a>
                </div>
                <div className="games__card--text">
                  <h3>El Puzzle de Bily</h3>
                  <p>Ordena las piezas en el menor tiempo posible.</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <NavLink to="/luna-de-juegos">
        <button className="return">
          <img
            src="https://user-images.githubusercontent.com/55818246/138624865-eb5ec3e3-586d-4a60-8364-b1747c38174c.png"
            alt=""
          />
        </button>
      </NavLink>
    </div>
  );
};
