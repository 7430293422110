import React, { useState } from "react";
import "./QuestionaryTiktok.scss";

import maik from "../../assets/img/maik.png";
import bily from "../../assets/img/bily.png";
import left from "../../assets/img/left.png";

export const QuestionaryTiktok = ({ onFinished }) => {
  const [correct, setCorrect] = useState(false);
  const [incorrect, setIncorrect] = useState("#52aa81");
  const [wrong, setWrong] = useState("#52aa81");
  const [visible, setVisible] = useState(true);

  let showCorrect = () => {
    setCorrect(!correct);
  };

  let showIncorrect = {
    background: `${incorrect}`,
  };

  let showWrong = {
    background: `${wrong}`,
  };

  let showButton = () => {
    setVisible(!visible);
  };
  return (
    <>
      <div className="layout__questionarytiktok">
        <div className="questionarytiktok">
          <h4>
            ¡Ya casi terminas el taller! <br /> Responde la siguiente pregunta
            para avanzar:
          </h4>
          <img src={bily} alt="" className="questionarytiktok__bily" />
          <img src={maik} alt="" className="questionarytiktok__maik" />
          <div className="questionarytiktok__card">
            <h5>¿CUÁNTOS PASOS APRENDIMOS EN EL TALLER DE BAILE?</h5>
            <ul className="">
              <li
                className={incorrect && "question--incorrect"}
                style={showIncorrect}
                onClick={() => setIncorrect("#d05050")}
                onMouseLeave={() => setIncorrect("#52aa81")}
                touchend={() => setIncorrect("#52aa81")}
              >
                <label htmlFor="selection">A</label>
                <button name="selection">2</button>
              </li>
              <li
                className={wrong && "question--incorrect"}
                style={showWrong}
                onClick={() => setWrong("#d05050")}
                onMouseLeave={() => setWrong("#52aa81")}
                touchend={() => setIncorrect("#52aa81")}
              >
                <label htmlFor="selection">B</label>
                <button name="selection">10</button>
              </li>
              <li
                className={correct && "question--correct"}
                onClick={() => {
                  showButton();
                  showCorrect();
                }}
              >
                <label htmlFor="selection">C</label>
                <button name="selection">4</button>
              </li>
            </ul>
          </div>
        </div>
        {visible <= "" ? (
          <button onClick={() => onFinished("form")} className="next__button">
            PASAR AL SIGUIENTE TALLER
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="44.25"
              height="49.25"
              viewBox="0 0 44.25 49.25"
            >
              <path
                id="Triángulo_699"
                data-name="Triángulo 699"
                className="cls-1"
                d="M757.939,1577.76a6,6,0,0,1,9-5.19l32.24,18.62a6,6,0,0,1,0,10.39l-32.233,18.61a6,6,0,0,1-9-5.2Z"
                transform="translate(-757.938 -1571.75)"
              />
            </svg>
          </button>
        ) : (
          ""
        )}
        <button onClick={() => onFinished("workshop")} className="return">
          <img src={left} alt="" />
        </button>
      </div>
    </>
  );
};
