import React, { useEffect } from "react";
import "./mascotasGracias.scss";
import ReactPixel from "react-facebook-pixel";
import logoMascotas from "../../../assets/img/mascotas-gracias/animales-b&m-mascotas-gracias-min.png";
import textoGenial from "../../../assets/img/mascotas-gracias/genial-min.png";
import logo from "../../../assets/img/mascotas-gracias/logo-mascotas-gracias-min.png";

export const MascotasGracias = ({ handle, hideHeader }) => {
  useEffect(() => {
    const options = {
      autoConfig: false,
      debug: true,
    };
    ReactPixel.init("351626500304749", null, options);
    ReactPixel.pageView();
    ReactPixel.track("CompleteRegistration");
    window.gtag("event", "conversion", {
      send_to: "AW-1011404979/6tYVCJyTmYYDELOho-ID",
    });
  }, []);

  useEffect(() => {
    window.gtag("config", "AW-1011404979");
    /*  window.gtag("event", "conversion", {
      send_to: "AW-1011404979/6tYVCJyTmYYDELOho-ID",
    }); */
  });

  // Ocultar header - footer generales del sitio
  useEffect(() => {
    handle(false);
    return () => handle(true);
  }, [handle]);

  useEffect(() => {
    hideHeader(false);
    return () => hideHeader(true);
  }, [hideHeader]);

  return (
    <div className="bg-mascotas-gracias">
      <main className="main-macotas-gracias">
        <section className="section-macotas-gracias__info">
          <img
            className="section-macotas-gracias__info--logo-mascotas"
            src={logoMascotas}
            alt="Mascotas"
          />
          <img
            className="section-macotas-gracias__info--texto-genial"
            src={textoGenial}
            alt="Genial"
          />
          <div className="section-macotas-gracias__info--container">
            <h1>
              Tus datos fueron ingresados correctamente
              <br /> y tu asistencia ya está confirmada.
            </h1>
            <h2>TE ESPERAMOS</h2>
          </div>
          <img
            className="section-macotas-gracias__info--logo"
            src={logo}
            alt=""
          />
        </section>
      </main>
    </div>
  );
};
