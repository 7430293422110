import React, { useState } from "react";
import { SucessNewsletter } from "./SucessNewsletter";
import "./newsletter.scss";
import { NavLink } from "react-router-dom";

export const Newsletter = () => {
  const [modalShow, setModalShow] = useState(false);

  const modalSucess = () => {
    setModalShow(!modalShow);
  };

  const closeModal = () => {
    setModalShow(false);
  };

  const [datos, setDatos] = useState({
    nameForm: "",
    emailForm: "",
    dateForm: "",
  });

  const handleInput = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const enviarDatos = async (event) => {
    event.preventDefault();
    event.target.reset();

    fetch("https://hooks.zapier.com/hooks/catch/2792106/bt0gk8b/", {
      method: "POST",
      body: JSON.stringify(datos),
    })
      .then(async (response) => {
        modalSucess();
      })
      .catch((err) => {
        console.log("Algo sucedió");
      });
  };

  return (
    <div className="container__newsletter">
      <NavLink activeClassName="is-active" to="/">
        <div
          className={`modalSucess${modalShow ? " active" : ""}`}
          onClick={closeModal}
        >
          <SucessNewsletter />
        </div>
      </NavLink>
      <div className="group__title__newsletter">
        <p>Suscríbete</p>
      </div>
      <div className="container__newsletter__form">
        <form className="newsletter__form" onSubmit={enviarDatos} action="">
          <div className="group-input">
            <label htmlFor="">Nombre y apellido:</label>
            <input
              type="text"
              name="nameForm"
              id=""
              onChange={handleInput}
              required
            />
          </div>
          <div className="group-input">
            <label htmlFor="">Mail:</label>
            <input
              type="email"
              name="emailForm"
              id=""
              onChange={handleInput}
              required
            />
          </div>
          <div className="group-input">
            <label htmlFor="">Fecha de nacimiento:</label>
            <input
              type="date"
              name="dateForm"
              id=""
              onChange={handleInput}
              required
            />
          </div>
          <div className="group-checkbox">
            <input
              type="checkbox"
              name=""
              id=""
              onChange={handleInput}
              required
            />
            <label htmlFor="">
              <a
                href="https://sitio.bilzypap.cl/bases/bases.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Acepto los términos y condiciones
              </a>
            </label>
          </div>
          <button type="submit">Enviar</button>
        </form>
      </div>
    </div>
  );
};
