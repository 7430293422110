import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "../layout/Home/Home";
import { Academy } from "../layout/Academy/Academy";
import { Courses } from "../layout/Academy/Courses/Courses";
import { Header } from "../DumbComponents/Header/Header";
import { Footer } from "../DumbComponents/Footer/Footer";
import { Workshop } from "../layout/Academy/Workshop/Workshop";
import { Science } from "../layout/Academy/Courses/Science/Science";
import { Crafts } from "../layout/Academy/Courses/Crafts/Crafts";
import { Kitchen } from "../layout/Academy/Courses/Kitchen/Kitchen";
import { Tiktok } from "../layout/Academy/Courses/Tiktok/Tiktok";
import { Questionary } from "../SmartComponents/Questionary/Questionary";
import { Form } from "../SmartComponents/Form/Form";
import { Modal } from "../SmartComponents/Modal/Modal";
import { Newsletter } from "../layout/Newsletter/Newsletter";
import { MoonGames } from "../layout/MoonGames/MoonGames";
import { Games } from "../layout/MoonGames/Games/Games";
import { ChildrensDayForm } from "../layout/ChildrensDay/ChildrensDayForm";
import { Bases } from "../layout/Bases/Bases";
import { Error404 } from "../layout/Error404/Error404";
import { Rodolfa } from "../layout/Animals/Puppies/Rodolfa";
import { Wandita } from "../layout/Animals/Puppies/Wandita/Wandita";
import { Dasher } from "../layout/Animals/Puppies/Dasher/Dasher";
import { Mia } from "../layout/Animals/Puppies/Mia/Mia";
import { Tamy } from "../layout/Animals/Puppies/Tamy/Tamy";
import { Cometa } from "../layout/Animals/Puppies/Cometa/Cometa";
import { Joe } from "../layout/Animals/Puppies/Joe/Joe";
import { Mascotas } from "../layout/Mascotas/Mascotas";
import { MascotasGracias } from "../layout/Mascotas/MascotasGracias/MascotasGracias";
import {Teleton2023} from '../layout/Teleton2023'

import {HappyBirthday} from '../layout/Cumpleanios'

import DogDetails from "../layout/Dogs/DogDetails/DogDetails";

export const Routes = () => {
  const [footerFlag, setFooterFlag] = React.useState(true);
  const [headerFlag, setHeaderFlag] = React.useState(true);

  return (
    <Router>
      <Header headerFlag={headerFlag} />
      <Switch>
        <Route exact path="/"render={(props) => {
            return (
              <Teleton2023
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        />

        {/* <Route exact path="/">
          <Home />
        </Route> */}

        <Route path="/academia-solar">
          <Academy />
        </Route>

        <Route path="/cursos">
          <Courses />
        </Route>

        <Route
          path="/cumple"
          render={(props) => {
            return (
              <HappyBirthday
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        ></Route>

        {/*
        <Route path="/planeta-reciclaje">
          <Recycling />
        </Route> */}

        {/* <Route path="/brigada">
          <Brigade />
        </Route> */}

        <Route path="/taller">
          <Workshop />
        </Route>
        {/*
        <Route path="/comencemos-a-reciclar">
          <HomeRecycling />
        </Route> */}

        <Route path="/taller-de-ciencias">
          <Science />
        </Route>

        <Route path="/taller-de-manualidades">
          <Crafts />
        </Route>

        <Route path="/taller-de-baile-tiktok">
          <Tiktok />
        </Route>

        <Route path="/taller-de-cocina">
          <Kitchen />
        </Route>

        {/*  <Route path="/formulario-brigada">
          <BrigadeForm />
        </Route> */}

        {/*   <Route path="/ideas-reciclaje">
          <TipsForm />
        </Route> */}

        {/*  <Route path="/tips">
          <Tips />
        </Route> */}

        <Route path="/cuestionario">
          <Questionary />
        </Route>

        <Route path="/formulario-solar">
          <Form />
        </Route>

        <Route path="/modal">
          <Modal />
        </Route>

        <Route path="/newsletter">
          <Newsletter />
        </Route>

        <Route path="/luna-de-juegos">
          <MoonGames />
        </Route>

        <Route path="/juegos">
          <Games />
        </Route>

        <Route path="/dia-del-nino">
          <ChildrensDayForm />
        </Route>

        <Route path="/bases-legales">
          <Bases />
        </Route>

        {/*    <Route
          path="/animales"
          render={(props) => {
            return (
              <Animals
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        />
 */}
        {/*  <Route
          path="/teleton21"
          render={(props) => {
            return (
              <Teleton
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        /> */}

         <Route
          path="/teleton24"
          render={(props) => {
            return (
              <Teleton2023
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        />

        <Route
          path="/rodolfa"
          render={(props) => {
            return (
              <Rodolfa
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        />

        <Route
          path="/wandita"
          render={(props) => {
            return (
              <Wandita
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        />

        <Route
          path="/dasher"
          render={(props) => {
            return (
              <Dasher
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        />

        <Route
          path="/mia"
          render={(props) => {
            return (
              <Mia
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        />

        <Route
          path="/tamy"
          render={(props) => {
            return (
              <Tamy
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        />

        <Route
          path="/cometa"
          render={(props) => {
            return (
              <Cometa
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        />

        <Route
          path="/joe"
          render={(props) => {
            return (
              <Joe
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        />

        {/* <Route
          path="/nabilzpap"
          render={(props) => {
            return (
              <Nabilzpap2022
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        /> */}

        <Route
          path="/mascotas"
          render={(props) => {
            return (
              <Mascotas
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        />

        <Route
          path="/mascotas-gracias"
          render={(props) => {
            return (
              <MascotasGracias
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        />

        <Route
          path="/listamascotas/:name"
          render={(props) => {
            return (
              <DogDetails
                {...props}
                handle={setFooterFlag}
                hideHeader={setHeaderFlag}
              />
            );
          }}
        />

        <Route exact path="*">
          <Error404 />
        </Route>
      </Switch>
      <Footer footerFlag={footerFlag} />
    </Router>
  );
};
