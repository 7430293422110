import React, { useState, useRef} from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

import ImagePin from "./assets/images/pin.png";
import PlaceholderImage from './assets/images/Icono-punto0.png'
import Arrow from './assets/images/arrow.png'

const MapContainer = ({ google }) => {
  const mapRef = useRef(null);
  const [comunaFilter, setComunaFilter] = useState("");

  const [locations] = useState([
    {
      comuna: "ARICA",
      region: "ARICA",
      name: "Instituto Teletón Arica",
      addres: "Diego Portales 2471",
      image: PlaceholderImage,
      lat: -18.485014056859600,
      lng: -7029089801877510
    },
    {
      comuna: "ARICA",
      region: "ARICA",
      name: "PL RESIMPLE ARICA 1 LIDER",
      addres: "Av Sta María 2985, Arica, Chile",
      image: PlaceholderImage,
      lat: -18.46004175310940,
      lng: -7029661871203160
    },
    {
      comuna: "ARICA",
      region: "ARICA",
      name: "PL RESIMPLE ARICA 2 SODIMAC",
      addres: "Av Diego Portales 2291, 1000000 Arica, Arica y Parinacota, Chile",
      image: PlaceholderImage,
      lat: -18.483146268180500,
      lng: -7029295507547140
    },
    {
      comuna: "Putre",
      region: "ARICA",
      name: "Plaza Putre",
      addres: "José Miguel Carrera 1500-1598, 1000932 Arica, Arica y Parinacota",
      image: PlaceholderImage,
      lat: -18.482361411037700,
      lng: -7030318011638770
    },
    {
      comuna: "IQUIQUE",
      region: "TARAPACA",
      name: "Instituto Teletón Iquique",
      addres: "JJ Pérez 999, Iquique",
      image: PlaceholderImage,
      lat: -20.221955510334000,
      lng: -7014602303068390
    },
    {
      comuna: "IQUIQUE",
      region: "TARAPACA",
      name: "PL RESIMPLE IQUIQUE 1 LIDER",
      addres: "Avenida Héroes de la Concepción 2653, Iquique, Chile",
      image: PlaceholderImage,
      lat: -20.235032328052300,
      lng: -7014351540324090
    },
    {
      comuna: "IQUIQUE",
      region: "TARAPACA",
      name: "PL RESIMPLE IQUIQUE 2 LIDER",
      addres: "Teresa Wilms Mont 2263, Iquique, Tarapacá, Chile",
      image: PlaceholderImage,
      lat: -20.26913214751920,
      lng: -7012669331786450
    },
    {
      comuna: "ANTOFAGASTA",
      region: "ANTOFAGASTA",
      name: "Instituto Teletón Antofagasta",
      addres: "Oficina Francisco Puelma 238",
      image: PlaceholderImage,
      lat: -23.551831546883400,
      lng: -7039401901876170
    },
    {
      comuna: "CALAMA",
      region: "ANTOFAGASTA",
      name: "Instituto Teletón Calama",
      addres: "Teniente Merino 3551",
      image: PlaceholderImage,
      lat: -22.443983544961400,
      lng: -6890199423068390
    },
    {
      comuna: "Calama",
      region: "ANTOFAGASTA",
      name: "Parque Manuel Rodriguez",
      addres: "Vicuña Mackenna 1971, 1394247 Calama, Antofagasta",
      image: PlaceholderImage,
      lat: -22.4633755267051,
      lng: -6892529394532470
    },
    {
      comuna: "COPIAPO",
      region: "ATACAMA",
      name: "PL SODIMAC COPIAPO",
      addres: "Panamericana Norte 140, Copiapó, Región de Atacama",
      image: PlaceholderImage,
      lat: -27.37342707022380,
      lng: -7033552193098920
    },
    {
      comuna: "VALLENAR",
      region: "ATACAMA",
      name: "PL SODIMAC VALLENAR",
      addres: "Avenida Huasco 175",
      image: PlaceholderImage,
      lat: -28.57499999991170,
      lng: -7078193097618670
    },
    {
      comuna: "COPIAPO",
      region: "ATACAMA",
      name: "Instituto Teletón Atacama",
      addres: "La Cruz 760, Población La Pradera, Copiapó",
      image: PlaceholderImage,
      lat: -27.349363862671000,
      lng: -7034924798465800
    },
    {
      comuna: "COPIAPO",
      region: "ATACAMA",
      name: "PL RESIMPLE LOS LOROS",
      addres: "Av Los Loros 1918, Copiapó",
      image: PlaceholderImage,
      lat: -27.37015636409330,
      lng: -7031167429772930
    },
    {
      comuna: "Caldera",
      region: "ATACAMA",
      name: "Plaza Carlos Condell",
      addres: "Ossa Cerda 472, Caldera, Atacama",
      image: PlaceholderImage,
      lat: -27.067489192207300,
      lng: -7082229193775870
    },
    {
      comuna: "Alto del Carmen",
      region: "ATACAMA",
      name: "ACOPIO TERRENO MUNICIPAL",
      addres: "LA HUERTA S/N",
      image: PlaceholderImage,
      lat: -28.758640564632700,
      lng: -7048491888612690
    },
    {
      comuna: "Alto del Carmen",
      region: "ATACAMA",
      name: "EDIFICIO CONSISTORIAL",
      addres: "PADRE ALONSO GARCIA S/N",
      image: PlaceholderImage,
      lat: -28.759839868829500,
      lng: -7048727421059270
    },
    {
      comuna: "LA SERENA",
      region: "COQUIMBO",
      name: "PL SODIMAC LA SERENA",
      addres: "Av Balmaceda 2885, La Serena, Región de Coquimbo",
      image: PlaceholderImage,
      lat: -29.92533821347370,
      lng: -7125772840395310
    },
    {
      comuna: "LA SERENA",
      region: "COQUIMBO",
      name: "Instituto Teletón Coquimbo",
      addres: "Avenida Cuatro Esquinas 060, La Serena",
      image: PlaceholderImage,
      lat: -29.929015204288700,
      lng: -712728099054106
    },
    {
      comuna: "La Serena",
      region: "COQUIMBO",
      name: "Sub Delegación Municipal Las Compañías",
      addres: "Calle Alejandro Flores / Plaza San Bartolomé - Las Compañías",
      image: PlaceholderImage,
      lat: -29.8868498256639,
      lng: -7123704965503850
    },
    {
      comuna: "La Serena",
      region: "COQUIMBO",
      name: "Delegación Municipal Antena",
      addres: "Av 18 de Septiembre s/n - La Antena",
      image: PlaceholderImage,
      lat: -29.91539415357300,
      lng: -7121863579862840
    },
    {
      comuna: "La Serena",
      region: "COQUIMBO",
      name: "Delegación Municipal Av Del Mar ",
      addres: "Av Costanera 2600 /2900",
      image: PlaceholderImage,
      lat: -29.958575120016600,
      lng: -7131266500289280
    },
    {
      comuna: "La Serena",
      region: "COQUIMBO",
      name: "Delegación Muncicipal Pampa",
      addres: "Larrain Alcalde 3500 Local 7 Edificio Sonora",
      image: PlaceholderImage,
      lat: -29.934843163871000,
      lng: -712570865207912
    },
    {
      comuna: "La Serena",
      region: "COQUIMBO",
      name: "Base CCU - Recinto Municipal",
      addres: "Calle Balmaceda - Sector Centro (Coordenadas: 29°54´0101\"S, 71°14´5816\"O)",
      image: PlaceholderImage,
      lat: -29.878682164225000,
      lng: -712511320908178
    },
    {
      comuna: "La Serena",
      region: "COQUIMBO",
      name: "Oficinas Municipales - Dirección de Servicio a la Comunidad",
      addres: "Calle Colón 824 - Sector Centro (Coordenadas: 29°53´5798\"S, 71°14´4009\"O)",
      image: PlaceholderImage,
      lat: -29.89937646736240,
      lng: -7124472407844770
    },
    {
      comuna: "La Serena",
      region: "COQUIMBO",
      name: "3 Puntos Móviles de Reciclaje itinerantes",
      addres: "Las Ubicaciones son semanales y se publican por redes sociales del Municipio",
      image: PlaceholderImage,
      lat: -29.906105611436800,
      lng: -712507035913959
    },
    {
      comuna: "Coquimbo",
      region: "COQUIMBO",
      name: "Punto limpio El Culebron",
      addres: "Av México 607, sector San Juan, COQUIMBO(Coordenadas: 29°58'4191\"S, 71°19'3427\"O)",
      image: PlaceholderImage,
      lat: -29.977526614962000,
      lng: -7132657210455250
    },
    {
      comuna: "Coquimbo",
      region: "COQUIMBO",
      name: "Banco de Chile",
      addres: "Melgarejo S/N (Plaza Gabriela Mistral, frente al Banco de Chile) Coordenadas: 29°57'080\"S 71°20'151\"O",
      image: PlaceholderImage,
      lat: -29.951964220264000,
      lng: -7133710331040720
    },
    {
      comuna: "Ovalle",
      region: "COQUIMBO",
      name: "Punto Limpio Ovalle",
      addres: "Los Industriales S/N, Barrio Industrial Ovalle (Coordenadas: 30°36'3533\"S, 71°11'3398\"O)",
      image: PlaceholderImage,
      lat: -30.609145235504500,
      lng: -7119050572799830
    },
    {
      comuna: "Punitaqui",
      region: "COQUIMBO",
      name: "Frontis Municipal",
      addres: "Caupolicán N°1147 (Coordenadas: 30°50'015\"S 71°15'286\"W)",
      image: PlaceholderImage,
      lat: -30.601157132112600,
      lng: -7118859476306690
    },
    {
      comuna: "Monte Patria",
      region: "COQUIMBO",
      name: "Punto Limpio Movil N°01",
      addres: "Los Flamenco con Las Aguiles S/N, Villa Esperanza Juvenil, El Palqui (Coordenadas: 30°47'026\"S 70°56'219\"O)",
      image: PlaceholderImage,
      lat: -30.58639126942540,
      lng: -7119819513799070
    },
    {
      comuna: "Monte Patria",
      region: "COQUIMBO",
      name: "Punto Limpio N°2",
      addres: "Calle diaguitas, Monte Patria (Coordenadas: 30°41'404\"S 70°57'272\"O)",
      image: PlaceholderImage,
      lat: -29.998810476330300,
      lng: -7126465708639970
    },
    {
      comuna: "Illapel",
      region: "COQUIMBO",
      name: "Punto Limpio Las Cañas 2",
      addres: "Caña 2 S/N (Coordenadas: 31°44'323\"S 71°12'265\"O)",
      image: PlaceholderImage,
      lat: -31.739432181485700,
      lng: -7120919660636570
    },
    {
      comuna: "Illapel",
      region: "COQUIMBO",
      name: "Punto Limpio Carcamo",
      addres: "Carcamo s/n (Coordenadas: 31°35'236\"S 71°04'016\"W)",
      image: PlaceholderImage,
      lat: -31.589876137471200,
      lng: -7106988811350980
    },
    {
      comuna: "Illapel",
      region: "COQUIMBO",
      name: "Escuela Especial La Colonia",
      addres: "La Colonia (Coordenadas: 31°36'158\"S 71°06'226\"W)",
      image: PlaceholderImage,
      lat: -31.60663566396790,
      lng: -7110886328999680
    },
    {
      comuna: "Illapel",
      region: "COQUIMBO",
      name: "Reciclaje ecologia siglo XXI",
      addres: "Felipe Ñigez (Coordenadas: 31°38'424\"S 71°11'204\"W)",
      image: PlaceholderImage,
      lat: -31.6443432353053,
      lng: -7118918871698410
    },
    {
      comuna: "Paihuano",
      region: "COQUIMBO",
      name: "Tres Cruces Alto",
      addres: "Coordenadas: 29°59'3299\"S, 70°31'5291\"O",
      image: PlaceholderImage,
      lat: -29.99247889789690,
      lng: -7053141048832830
    },
    {
      comuna: "Paihuano",
      region: "COQUIMBO",
      name: "Tres Cruces Bajo",
      addres: "Coordenadas: 30° 0'1872\"S, 70°31'4545\"O",
      image: PlaceholderImage,
      lat: -30.00509777434860,
      lng: -7052931316310130
    },
    {
      comuna: "Paihuano",
      region: "COQUIMBO",
      name: "Las Placetas, Horcon",
      addres: "Coordenadas: 30°10'5622\"S, 70°29'801\"O",
      image: PlaceholderImage,
      lat: -30.18220908017040,
      lng: -704856763226071
    },
    {
      comuna: "Paihuano",
      region: "COQUIMBO",
      name: "Horcon Bajo",
      addres: "Coordenadas: 30°11'5323\"S, 70°29'692\"O",
      image: PlaceholderImage,
      lat: -30.198091556314200,
      lng: -7048528779192530
    },
    {
      comuna: "Paihuano",
      region: "COQUIMBO",
      name: "Alcochuaz",
      addres: "Coordenadas: 30°13'4838\"S, 70°29'4240\"O",
      image: PlaceholderImage,
      lat: -30.22975274468040,
      lng: -7049256140968450
    },
    {
      comuna: "Paihuano",
      region: "COQUIMBO",
      name: "Quebrada Paihuano",
      addres: "Coordenadas: 30° 2'149\"S, 70°27'5430\"O",
      image: PlaceholderImage,
      lat: -30.035371130360000,
      lng: -7045013612033570
    },
    {
      comuna: "Paihuano",
      region: "COQUIMBO",
      name: "Villa Los Cantaros",
      addres: "Coordenadas: 30° 1'4955\"S, 70°31'615\"O",
      image: PlaceholderImage,
      lat: -30.030402709135200,
      lng: -7051837500542960
    },
    {
      comuna: "Paihuano",
      region: "COQUIMBO",
      name: "Punto Sector Chanchoqui",
      addres: "Coordenadas: 30° 1'4578\"S, 70°31'1900\"O",
      image: PlaceholderImage,
      lat: -30.028755600077000,
      lng: -7052437899435560
    },
    {
      comuna: "Paihuano",
      region: "COQUIMBO",
      name: "Quebrada Pinto",
      addres: "Coordenadas: 30° 4'442\"S, 70°29'4481\"O",
      image: PlaceholderImage,
      lat: -30.0634428209915,
      lng: -704925844441422
    },
    {
      comuna: "Paihuano",
      region: "COQUIMBO",
      name: "Monte Grande",
      addres: "Coordenadas: 30° 5'3765\"S, 70°29'4149\"O",
      image: PlaceholderImage,
      lat: -30.09410953025440,
      lng: -7049445035988210
    },
    {
      comuna: "Paihuano",
      region: "COQUIMBO",
      name: "Monte Gabriela",
      addres: "Coordenadas: 30° 5'1475\"S, 70°29'5900\"O",
      image: PlaceholderImage,
      lat: -30.09544803796780,
      lng: -7049510298244150
    },
    {
      comuna: "Paihuano",
      region: "COQUIMBO",
      name: "Cochiguaz",
      addres: "Coordenadas: 30° 8'3342\"S, 70°24'2140\"O",
      image: PlaceholderImage,
      lat: -30.142542450333600,
      lng: -7040591221891800
    },
    {
      comuna: "Paihuano",
      region: "COQUIMBO",
      name: "Pisco Elqui",
      addres: "Coordenadas: 30° 7'2517\"S, 70°29'3716\"O",
      image: PlaceholderImage,
      lat: -30.123648995028600,
      lng: -704936985207648
    },
    {
      comuna: "Paihuano",
      region: "COQUIMBO",
      name: "Población Los Nogales",
      addres: "Coordenadas: 30° 7'1732\"S, 70°29'2758\"O",
      image: PlaceholderImage,
      lat: -30.121394254390300,
      lng: -704910051342597
    },
    {
      comuna: "VALPARAISO",
      region: "VALPARAISO",
      name: "PL SODIMAC LA CALERA",
      addres: "J J Pérez 12010, La Calera, Calera, Región de Valparaíso",
      image: PlaceholderImage,
      lat: -32.790179591520500,
      lng: -7119110360019640
    },
    {
      comuna: "VIÑA DEL MAR",
      region: "VALPARAISO",
      name: "PL SODIMAC REÑACA VIÑA",
      addres: "Alessandri 1255",
      image: PlaceholderImage,
      lat: -32.99893343136350,
      lng: -7151338457320490
    },
    {
      comuna: "ZAPALLAR",
      region: "VALPARAISO",
      name: "PL ZAPALLAR MUNICIPALIDAD",
      addres: "Sector El Maitén (camino a Cachagua) ruta E30F KM 30",
      image: PlaceholderImage,
      lat: -32.58574914079980,
      lng: -7143670834873130
    },
    {
      comuna: "VALPARAISO",
      region: "VALPARAISO",
      name: "Instituto Teletón Valparaíso",
      addres: "Avda Francia 259",
      image: PlaceholderImage,
      lat: -33.04476369820130,
      lng: -7161207196136780
    },
    {
      comuna: "Olmué",
      region: "VALPARAISO",
      name: "Plaza de los Caballos",
      addres: "Av Granizo paradero 26 calle Lo Rojas",
      image: PlaceholderImage,
      lat: -32.99537311073140,
      lng: -7118106048987090
    },
    {
      comuna: "Olmué",
      region: "VALPARAISO",
      name: "Av Eastman paradero 17 (frente a almacenes)",
      addres: "Av Eastman paradero 17",
      image: PlaceholderImage,
      lat: -32.99678029975000,
      lng: -7122197545876200
    },
    {
      comuna: "Olmué",
      region: "VALPARAISO",
      name: "Granizo paradero 45, (frente al paradero)",
      addres: "Granizo paradero 45",
      image: PlaceholderImage,
      lat: -32.99414724065690,
      lng: -7117254845625270
    },
    {
      comuna: "Olmué",
      region: "VALPARAISO",
      name: "Entrada Narvaéz AV Alcalde Hugo Quinteros",
      addres: "Entrada Narvaéz AV Alcalde Hugo Quinteros",
      image: PlaceholderImage,
      lat: -33.001322625812700,
      lng: -7117121517152910
    },
    {
      comuna: "Olmué",
      region: "VALPARAISO",
      name: "Las Palmas",
      addres: "Frente al restaurant No me Olvides",
      image: PlaceholderImage,
      lat: -33.048564564584200,
      lng: -7109966897272300
    },
    {
      comuna: "Olmué",
      region: "VALPARAISO",
      name: "Santuario Niño Dios de Las Palmas",
      addres: "Las Palmas",
      image: PlaceholderImage,
      lat: -33.01840073322940,
      lng: -7105961372840780
    },
    {
      comuna: "Putaendo",
      region: "VALPARAISO",
      name: "Municipalidad de Putaendo",
      addres: "Av Prat 1",
      image: PlaceholderImage,
      lat: -32.62629682258350,
      lng: -7071576388439810
    },
    {
      comuna: "Putaendo",
      region: "VALPARAISO",
      name: "Direccion Medio Ambiente Aseo y Ornato",
      addres: "Av Alejandrina Carvajal 10779",
      image: PlaceholderImage,
      lat: -32.63060502384570,
      lng: -7072109342615470
    },
    {
      comuna: "Putaendo",
      region: "VALPARAISO",
      name: "Supermercado Carmen Mendez Donoso",
      addres: "Sarmiento 573",
      image: PlaceholderImage,
      lat: -32.62630982308810,
      lng: -7071826448742150
    },
    {
      comuna: "Putaendo",
      region: "VALPARAISO",
      name: "Liceo Manuel Marin Fritis",
      addres: "Calle Chacabuco 460",
      image: PlaceholderImage,
      lat: -32.62706233678650,
      lng: -7071470282857350
    },
    {
      comuna: "Putaendo",
      region: "VALPARAISO",
      name: "Liceo Rinconada de Silva",
      addres: "Calle El Carmen S/n",
      image: PlaceholderImage,
      lat: -32.662021918777000,
      lng: -7071643125613570
    },
    {
      comuna: "San Antonio",
      region: "VALPARAISO",
      name: "Frontis municipal",
      addres: "Av Ramón Barros Luco 1881",
      image: PlaceholderImage,
      lat: -33.59614244016920,
      lng: -7161398164542890
    },
    {
      comuna: "Zapallar",
      region: "VALPARAISO",
      name: "PUNTO LIMPIO CACHAGUA",
      addres: "RUTA F 30 AL FRENTE DEL CLUB DE GOLF",
      image: PlaceholderImage,
      lat: -32.585588473811,
      lng: -7143575005983170
    },
    {
      comuna: "Los Andes",
      region: "VALPARAISO",
      name: "CENTRO DE RECICLAJE \"PUNTO VERDE",
      addres: "CALLE SAN MARTIN S/N ESQUINA AVENIDA ARGENTINA ORIENTE",
      image: PlaceholderImage,
      lat: -32.832024533441200,
      lng: -7058936903244110
    },
    {
      comuna: "Los Andes",
      region: "VALPARAISO",
      name: "PLAZA DE ARMAS COSTADO ESCENARIO EVENTO TELETON",
      addres: "CALLE MAIPU, FRENTE A BANCO CHILE EN PLAZA DE ARMAS",
      image: PlaceholderImage,
      lat: -32.8338975059251,
      lng: -7059756884129750
    },
    {
      comuna: "CERRILLOS",
      region: "RM",
      name: "PL SODIMAC CERRILLOS",
      addres: "Avenida Américo Vespucio 1501",
      image: PlaceholderImage,
      lat: -33.518397,
      lng: -70714824
    },
    {
      comuna: "INDEPENDENCIA",
      region: "RM",
      name: "PL RESIMPLE INDEPENDENCIA",
      addres: "Presidente Eduardo Frei Montalva 641, Independencia",
      image: PlaceholderImage,
      lat: -33.42329411574050,
      lng: -7067279102953820
    },
    {
      comuna: "INDEPENDENCIA",
      region: "RM",
      name: "PL RESIMPLE WALTER LIHN",
      addres: "Walter Lihn 1934, Independencia",
      image: PlaceholderImage,
      lat: -33.42013833016900,
      lng: -7066598755197400
    },
    {
      comuna: "LA REINA",
      region: "RM",
      name: "PL SODIMAC LA REINA",
      addres: "Avenida Jorge Alessandri 1347",
      image: PlaceholderImage,
      lat: -33.46214112846800,
      lng: -7054661651633520
    },
    {
      comuna: "LA REINA",
      region: "RM",
      name: "WALMART PL PRINCIPE DE GALES",
      addres: "Príncipe de Gales 9140",
      image: PlaceholderImage,
      lat: -33.44070058956580,
      lng: -7053517825785320
    },
    {
      comuna: "LAS CONDES",
      region: "RM",
      name: "PL SODIMAC / MALL PLAZA LOS DOMINICOS",
      addres: "Padre Hurtado Sur 875",
      image: PlaceholderImage,
      lat: -33.41536628433370,
      lng: -7054029911737320
    },
    {
      comuna: "LAS CONDES",
      region: "RM",
      name: "UDD PL LAS CONDES",
      addres: "Avenida la Plaza 680",
      image: PlaceholderImage,
      lat: -33.39166026698990,
      lng: -7050097914620920
    },
    {
      comuna: "LAS CONDES",
      region: "RM",
      name: "ED CORPORATIVO VITACURA",
      addres: "Av Vitacura 2670, Las Condes",
      image: PlaceholderImage,
      lat: -33.41639993603360,
      lng: -7060459600267940
    },
    {
      comuna: "LAS CONDES",
      region: "RM",
      name: "PL KIDZANIA",
      addres: "Presidente Riesco 5330 Nivel Boulevard 2, Vida Parque",
      image: PlaceholderImage,
      lat: -33.403319935848400,
      lng: -7057507750653920
    },
    {
      comuna: "MAIPU",
      region: "RM",
      name: "PL PAJARITOS MAIPU",
      addres: "Av Pajaritos con 5 de Abril",
      image: PlaceholderImage,
      lat: -33.50972592637080,
      lng: -7075681000267630
    },
    {
      comuna: "MELIPILLA",
      region: "RM",
      name: "PL SODIMAC MELIPILLA",
      addres: "Av, Vicuña Mackenna 1415",
      image: PlaceholderImage,
      lat: -33.67540866860530,
      lng: -7116731843086090
    },
    {
      comuna: "ÑUÑOA",
      region: "RM",
      name: "PL IRARRAZAVAL",
      addres: "Av Irarrázaval 3579",
      image: PlaceholderImage,
      lat: -33.455140328075000,
      lng: -7059356258830620
    },
    {
      comuna: "PEDRO AGUIRRE CERDA",
      region: "RM",
      name: "PL RESIMPLE ENRIQUE MATTE",
      addres: "Enrique Matte 2280, PAC",
      image: PlaceholderImage,
      lat: -33.48157506521470,
      lng: -7066824280892520
    },
    {
      comuna: "PEÑALOLEN",
      region: "RM",
      name: "PL SODIMAC PEÑALOLEN",
      addres: "Avenida José Pedro Alessandri N°6402",
      image: PlaceholderImage,
      lat: -33.50887996672310,
      lng: -7058827765970010
    },
    {
      comuna: "PEÑALOLEN",
      region: "RM",
      name: "PL GIRO Centro Cívico San Luis",
      addres: "Av Las Torres 5555",
      image: PlaceholderImage,
      lat: -33.50227000417750,
      lng: -705776826573086
    },
    {
      comuna: "PEÑALOLEN",
      region: "RM",
      name: "PL GIRO Estadio Cultural Cordillera",
      addres: "Los Talladores 5724",
      image: PlaceholderImage,
      lat: -33.505782859158700,
      lng: -7056810226900310
    },
    {
      comuna: "PEÑALOLEN",
      region: "RM",
      name: "PL GIRO Quilín",
      addres: "Av Américo Vespucio 3100",
      image: PlaceholderImage,
      lat: -33.48588898353590,
      lng: -7057929136915990
    },
    {
      comuna: "PUENTE ALTO",
      region: "RM",
      name: "WALMART PL CORDILLERA",
      addres: "Avenida Los Toros 5441",
      image: PlaceholderImage,
      lat: -33.56715598559150,
      lng: -7055817520387620
    },
    {
      comuna: "QUILICURA",
      region: "RM",
      name: "PL SODIMAC QUILICURA",
      addres: "Avenida Manuel Antonio Matta 581",
      image: PlaceholderImage,
      lat: -33.36545271029900,
      lng: -7072713815785490
    },
    {
      comuna: "RECOLETA",
      region: "RM",
      name: "PL GIRO Bellavista",
      addres: "Bellavista 180",
      image: PlaceholderImage,
      lat: -33.43371235793950,
      lng: -7064243368354110
    },
    {
      comuna: "RENCA",
      region: "RM",
      name: "PL PLAZA RENCA",
      addres: "Blanco Encalada 1335",
      image: PlaceholderImage,
      lat: -33.404539459026100,
      lng: -707046364600594
    },
    {
      comuna: "SAN BERNARDO",
      region: "RM",
      name: "PL SODIMAC SAN BERNARDO",
      addres: "Avenida Jorge Alessandri Rodriguez 20040",
      image: PlaceholderImage,
      lat: -33.554063,
      lng: -70675761
    },
    {
      comuna: "SANTIAGO",
      region: "RM",
      name: "Instituto Teletón Santiago",
      addres: "Avda Libertador Bernardo O’Higgins 4620",
      image: PlaceholderImage,
      lat: -33.45614673683540,
      lng: -7069897578465800
    },
    {
      comuna: "VITACURA",
      region: "RM",
      name: "PL RESIMPLE PARQUE BICENTENARIO",
      addres: "Costanera 4275, Vitacura",
      image: PlaceholderImage,
      lat: -33.40501547501350,
      lng: -7060300607380750
    },
    {
      comuna: "Conchalí",
      region: "RM",
      name: "PUNTO VERDE DIMAO",
      addres: "AVENIDA LA PALMILLA 3673",
      image: PlaceholderImage,
      lat: -33.39226803834330,
      lng: -7066804443755940
    },
    {
      comuna: "La Florida",
      region: "RM",
      name: "DIMAAOO",
      addres: "Calle Tres 10050",
      image: PlaceholderImage,
      lat: -33.547500148214800,
      lng: -7061008778770530
    },
    {
      comuna: "Lo Barnechea",
      region: "RM",
      name: "Mun Lo Barnechea",
      addres: "El Rodeo 12777, Lo Barnechea, Región Metropolitana",
      image: PlaceholderImage,
      lat: -33.35337379424060,
      lng: -7051948853244860
    },
    {
      comuna: "Maipú",
      region: "RM",
      name: "PL PAJARITOS MAIPU",
      addres: "Av Pajaritos con 5 de Abril",
      image: PlaceholderImage,
      lat: -33.51056484656940,
      lng: -7075751240489610
    },
    {
      comuna: "Melipilla",
      region: "RM",
      name: "PL MUNICIPAL MELIPILLA",
      addres: "CALLE ADRIANA MIRANDA 1566-1572",
      image: PlaceholderImage,
      lat: -33.69879366919610,
      lng: -7121673162023060
    },
    {
      comuna: "Paine",
      region: "RM",
      name: "Municipalidad de paine",
      addres: "Av General Baquedano 490",
      image: PlaceholderImage,
      lat: -33.808395129384600,
      lng: -707421987146828
    },
    {
      comuna: "Quilicura",
      region: "RM",
      name: "Centro Inclusivo Municipal",
      addres: "Raimundo Romo 280",
      image: PlaceholderImage,
      lat: -33.36707065961260,
      lng: -7073154081201970
    },
    {
      comuna: "Quilicura",
      region: "RM",
      name: "Centro Cultural de Quilicura",
      addres: "Av Bernardo O Higgins 281",
      image: PlaceholderImage,
      lat: -33.36723513375060,
      lng: -7073078351945930
    },
    {
      comuna: "Quilicura",
      region: "RM",
      name: "Frontis Municipalidad de Quilicura",
      addres: "José Francisco 450",
      image: PlaceholderImage,
      lat: -33.36908383515870,
      lng: -707318728906239
    },
    {
      comuna: "Renca",
      region: "RM",
      name: "Plaza Mayor de Renca",
      addres: "Blanco Encalada 1335",
      image: PlaceholderImage,
      lat: -33.40454760397590,
      lng: -7070418406072320
    },
    {
      comuna: "Santiago",
      region: "RM",
      name: "Plaza de Bolsillo",
      addres: "Santo Domingo/Teatinos",
      image: PlaceholderImage,
      lat: -33.43639309436060,
      lng: -7065557120305110
    },
    {
      comuna: "Vitacura",
      region: "RM",
      name: "Punto Limpio Vitacura",
      addres: "Costanera Sur SJE de Balaguer 4275",
      image: PlaceholderImage,
      lat: -33.391131290316800,
      lng: -7059911871921980
    },
    {
      comuna: "RANCAGUA",
      region: "O'HIGGINS",
      name: "PL SODIMAC RANCAGUA",
      addres: "Avenida Koke 011",
      image: PlaceholderImage,
      lat: -34.16400443269540,
      lng: -7075196124434360
    },
    {
      comuna: "Paredones",
      region: "O'HIGGINS",
      name: "Municipalidad",
      addres: "Avenida DR Moore 15",
      image: PlaceholderImage,
      lat: -34.648450177400900,
      lng: -7190015805193990
    },
    {
      comuna: "Paredones",
      region: "O'HIGGINS",
      name: "Escuela Mercedes Urzua Diaz",
      addres: "Avenida DR Moore",
      image: PlaceholderImage,
      lat: -34.64449937031400,
      lng: -7189899023692820
    },
    {
      comuna: "Paredones",
      region: "O'HIGGINS",
      name: "Liceo Mirella Catalan Urzua",
      addres: "Ruta I-72",
      image: PlaceholderImage,
      lat: -34.65011741335020,
      lng: -7190678393398310
    },
    {
      comuna: "Paredones",
      region: "O'HIGGINS",
      name: "Escuela Union de Mujeres Americanas",
      addres: "Bucalemu",
      image: PlaceholderImage,
      lat: -34.642749625300900,
      lng: -7203609262106390
    },
    {
      comuna: "Paredones",
      region: "O'HIGGINS",
      name: "Escuela Los Copihues",
      addres: "El Calvario",
      image: PlaceholderImage,
      lat: -34.599163064060400,
      lng: -7187711887781980
    },
    {
      comuna: "Paredones",
      region: "O'HIGGINS",
      name: "Escuela Villa San Pedro",
      addres: "El Potrero",
      image: PlaceholderImage,
      lat: -34.54915217586890,
      lng: -7190675957689440
    },
    {
      comuna: "Paredones",
      region: "O'HIGGINS",
      name: "Escuela Las Carmelitas",
      addres: "El Quillay",
      image: PlaceholderImage,
      lat: -34.643156174059700,
      lng: -7190134345860390
    },
    {
      comuna: "Paredones",
      region: "O'HIGGINS",
      name: "Escuela Esmeralda",
      addres: "Cabeceras",
      image: PlaceholderImage,
      lat: -34.61639454885530,
      lng: -7198411873251340
    },
    {
      comuna: "Paredones",
      region: "O'HIGGINS",
      name: "Escuela Lo Valdivia",
      addres: "Lo Valdivia",
      image: PlaceholderImage,
      lat: -34.692849125143300,
      lng: -72012845412929
    },
    {
      comuna: "Paredones",
      region: "O'HIGGINS",
      name: "Escuela Santa Ana",
      addres: "La Poblacion",
      image: PlaceholderImage,
      lat: -34.71516130035180,
      lng: -7188578110985610
    },
    {
      comuna: "Paredones",
      region: "O'HIGGINS",
      name: "Escuela Luis Artemon Perez",
      addres: "El Peral",
      image: PlaceholderImage,
      lat: -34.749292684116000,
      lng: -7193933180635490
    },
    {
      comuna: "Paredones",
      region: "O'HIGGINS",
      name: "Escuela Rinconada de Los Briones",
      addres: "Los Briones",
      image: PlaceholderImage,
      lat: -34.74601658534510,
      lng: -7191403250631480
    },
    {
      comuna: "Paredones",
      region: "O'HIGGINS",
      name: "Escuela El Cardal",
      addres: "El Cardal",
      image: PlaceholderImage,
      lat: -34.70489525488370,
      lng: -7198046368229290
    },
    {
      comuna: "Paredones",
      region: "O'HIGGINS",
      name: "Escuela San Pedro de Alcantara",
      addres: "San Pedro de Alcantara",
      image: PlaceholderImage,
      lat: -34.76955557216990,
      lng: -7183661891609460
    },
    {
      comuna: "Paredones",
      region: "O'HIGGINS",
      name: "Escuela Albertina Valenzuela Toledo",
      addres: "Cutemu",
      image: PlaceholderImage,
      lat: -34.75526044969930,
      lng: -7188535970360150
    },
    {
      comuna: "Machalí",
      region: "O'HIGGINS",
      name: "Plaza de Armas Machalí",
      addres: "Plaza de Armas #11",
      image: PlaceholderImage,
      lat: -34.18247591858860,
      lng: -7065091872847610
    },
    {
      comuna: "Machalí",
      region: "O'HIGGINS",
      name: "Dimao",
      addres: "Av San Juan #1200 Machalí",
      image: PlaceholderImage,
      lat: -34.176443431851500,
      lng: -7065866582051880
    },
    {
      comuna: "Coinco",
      region: "O'HIGGINS",
      name: "Piscina Municipal",
      addres: "Piscina Municipal",
      image: PlaceholderImage,
      lat: -34.26996634757780,
      lng: -7094936252874600
    },
    {
      comuna: "COINCO",
      region: "O'HIGGINS",
      name: "PL COINCO",
      addres: "H-38, 250 Coinco",
      image: PlaceholderImage,
      lat: -34.26835501299150,
      lng: -7094928208702460
    },
    {
      comuna: "TALCA",
      region: "MAULE",
      name: "PL SODIMAC TALCA",
      addres: "avenida 2 nte 3344",
      image: PlaceholderImage,
      lat: -35.43141889914430,
      lng: -7162929133082100
    },
    {
      comuna: "TALCA",
      region: "MAULE",
      name: "Instituto Teletón Del Maule",
      addres: "10 Ote 3073, Talca, Maule",
      image: PlaceholderImage,
      lat: -35.405603853855400,
      lng: -716426327892781
    },
    {
      comuna: "CURICO",
      region: "MAULE",
      name: "PL RESIMPLE CURICÓ SODIMAC",
      addres: "Avenida Carlos Condell 1192, Curicó, Chile",
      image: PlaceholderImage,
      lat: -34.97706956276180,
      lng: -7122098843396210
    },
    {
      comuna: "ROMERAL",
      region: "MAULE",
      name: "PLAZA DE ROMERAL",
      addres: "IGNACIO CARRERA PINTO 1213",
      image: PlaceholderImage,
      lat: -34.960136609474600,
      lng: -7112492662323060
    },
    {
      comuna: "ROMERAL",
      region: "MAULE",
      name: "DIDECO",
      addres: "AV LIBERTAD",
      image: PlaceholderImage,
      lat: -34.959933939706700,
      lng: -7112691799278440
    },
    {
      comuna: "ROMERAL",
      region: "MAULE",
      name: "BOMBEROS",
      addres: "OSCAR BONILLA",
      image: PlaceholderImage,
      lat: -34.96205278322560,
      lng: -7112596714461940
    },
    {
      comuna: "HUALAÑÉ",
      region: "MAULE",
      name: "GIMNASIO MUNICIPAL",
      addres: "PEDRO AGUIRRE CERDA S/N ESQUINA AV 11 DE SEPTIEMBRE",
      image: PlaceholderImage,
      lat: -34.97334097142520,
      lng: -7180529487502140
    },
    {
      comuna: "TALCA",
      region: "MAULE",
      name: "La Florida",
      addres: "18 sur 5 1/2 Pte S/N",
      image: PlaceholderImage,
      lat: -35.4403980009806,
      lng: -7167819083980030
    },
    {
      comuna: "TALCA",
      region: "MAULE",
      name: "Las Colines",
      addres: "10 oriente 16 sur S/N",
      image: PlaceholderImage,
      lat: -35.448281205676200,
      lng: -7165603671047010
    },
    {
      comuna: "TALCA",
      region: "MAULE",
      name: "Carlos Trupp",
      addres: "30 oriente 9 1/2 sur S/N",
      image: PlaceholderImage,
      lat: -35.44543807046370,
      lng: -7165632347995270
    },
    {
      comuna: "TALCA",
      region: "MAULE",
      name: "Faustino Gonzales",
      addres: "17 oriente con 17 norte S/N",
      image: PlaceholderImage,
      lat: -35.417570216131400,
      lng: -7163627458975780
    },
    {
      comuna: "TALCA",
      region: "MAULE",
      name: "Villas unidas",
      addres: "12 1/2 oriente esquina 11 norte S/N",
      image: PlaceholderImage,
      lat: -35.41573616161230,
      lng: -7164628782653620
    },
    {
      comuna: "TALCA",
      region: "MAULE",
      name: "Circunvalacion",
      addres: "5 oriente con Circunvalacion",
      image: PlaceholderImage,
      lat: -35.39713518968670,
      lng: -7165146788500660
    },
    {
      comuna: "TALCA",
      region: "MAULE",
      name: "Bicentenario",
      addres: "32 oriente 22 1/2 norte B S/N",
      image: PlaceholderImage,
      lat: -35.4141433157151,
      lng: -7161157013830800
    },
    {
      comuna: "TALCA",
      region: "MAULE",
      name: "Centro de Acopio Talca Recicla",
      addres: "32 Ote 3475, Talca, Maule",
      image: PlaceholderImage,
      lat: -35.41374963391100,
      lng: -7161175657598600
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Depto medio ambiente",
      addres: "Tarapacá #464",
      image: PlaceholderImage,
      lat: -36.14234278008740,
      lng: -7183266817806080
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Fomento productivo",
      addres: "Anibal pinto #836",
      image: PlaceholderImage,
      lat: -36.141245872433900,
      lng: -7182074292039070
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Gimnasio municipal",
      addres: "Delicias norte #717",
      image: PlaceholderImage,
      lat: -36.14534437085190,
      lng: -7182383344553420
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Bodega medio ambiente Parral",
      addres: "Tarapacá 464",
      image: PlaceholderImage,
      lat: -36.14225613887130,
      lng: -7183267890689590
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Población Gabriela Mistral",
      addres: "Bandejón Población gabriela Mistral entre urrutia y buin",
      image: PlaceholderImage,
      lat: -36.138865636935900,
      lng: -7181907523388560
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Villa Portal Alameda",
      addres: "Camino a la montaña sector juegos Villa portal alameda",
      image: PlaceholderImage,
      lat: -36.13214839390580,
      lng: -7181396668175290
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Población los Valles de Parral 1",
      addres: "Pasaje 6 con calla san agustin",
      image: PlaceholderImage,
      lat: -36.14869676656570,
      lng: -7183425320348390
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Población Padre Hurtado",
      addres: "Roberto aranguiz con pasaje 5",
      image: PlaceholderImage,
      lat: -36.123836537087900,
      lng: -7181931293388660
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Villa Pilar 1",
      addres: "Plaza villa pilar 1",
      image: PlaceholderImage,
      lat: -36.12891904445420,
      lng: -7181663827437030
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Población Plaza de Armas",
      addres: "Calle igualdad con pasaje B",
      image: PlaceholderImage,
      lat: -36.13669544966240,
      lng: -718243417452923
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Población Bernardo Barriga",
      addres: "Calle 3 poniente esquina pasaje 2",
      image: PlaceholderImage,
      lat: -36.136508718043600,
      lng: -718386264314047
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Población Bicentenario",
      addres: "Juan pablo II y 2 poniente",
      image: PlaceholderImage,
      lat: -36.13903033474610,
      lng: -7183900000596970
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Plaza Arrau Mendez",
      addres: "Plaza Arrau mendez",
      image: PlaceholderImage,
      lat: -36.14359803269910,
      lng: -7183988858601840
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Calle 5 sur",
      addres: "5 sur esquina victoria",
      image: PlaceholderImage,
      lat: -36.15015648935410,
      lng: -7183204776424090
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Villa el sol",
      addres: "La sebastiana entre calle 2 y pasaje 2",
      image: PlaceholderImage,
      lat: -36.15354309819150,
      lng: -7183219683203890
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Plaza Los Olivos",
      addres: "Sector plaza sector los olivos",
      image: PlaceholderImage,
      lat: -36.147628826571700,
      lng: -718160940590288
    },
    {
      comuna: "PARRAL",
      region: "MAULE",
      name: "Terminal de buses Parral",
      addres: "Delicias norte y sur entre igualdad y arturo prat",
      image: PlaceholderImage,
      lat: -36.14507164562200,
      lng: -7182728989896080
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Municipalidad de Molina",
      addres: "Municipalidad de MolinaMunicipal",
      image: PlaceholderImage,
      lat: -35.11315767750690,
      lng: -7127923233268270
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Esq Maipú/Teniente Ponce",
      addres: "Esq Maipú/Teniente PonceMunicipal",
      image: PlaceholderImage,
      lat: -35.119664959524700,
      lng: -7128219218341190
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Diego Portales",
      addres: "Diego PortalesMunicipal",
      image: PlaceholderImage,
      lat: -35.0453746683882,
      lng: -7130725590320260
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "San Pedro",
      addres: "San PedroMunicipal",
      image: PlaceholderImage,
      lat: -35.10659836444310,
      lng: -712941452480233
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Maturana",
      addres: "MaturanaMunicipal",
      image: PlaceholderImage,
      lat: -35.11369953331850,
      lng: -7128629411492140
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Quechereguas Poste",
      addres: "Quechereguas PosteMunicipal",
      image: PlaceholderImage,
      lat: -35.115206658958100,
      lng: -7128103393821940
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Doctor Hurtado 3",
      addres: "Doctor Hurtado 3Municipal",
      image: PlaceholderImage,
      lat: -35.10868030422310,
      lng: -7129514545171450
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Don Leonardo Lontué",
      addres: "Don Leonardo LontuéMunicipal",
      image: PlaceholderImage,
      lat: -35.05255423522480,
      lng: -712703871877884
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Don Leonardo Lontué 2",
      addres: "Don Leonardo Lontué 2Municipal",
      image: PlaceholderImage,
      lat: -35.05193905509700,
      lng: -7127147862109460
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Estrella Azul",
      addres: "Estrella AzulMunicipal",
      image: PlaceholderImage,
      lat: -35.05062348968190,
      lng: -7127844256279230
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Los Laureles",
      addres: "Los LaurelesMunicipal",
      image: PlaceholderImage,
      lat: -35.11314000023980,
      lng: -712787276353409
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Villa Primavera",
      addres: "Villa PrimaveraMunicipal",
      image: PlaceholderImage,
      lat: -35.056593376337700,
      lng: -7126863489958470
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Plaza Lontue",
      addres: "Plaza LontueMunicipal",
      image: PlaceholderImage,
      lat: -35.052881529928500,
      lng: -7127964001861670
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Bicentenario Lontué",
      addres: "Bicentenario LontuéMunicipal",
      image: PlaceholderImage,
      lat: -35.05320189231940,
      lng: -7127404924497750
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Bicentenario Lontué 2",
      addres: "Bicentenario Lontué 2Municipal",
      image: PlaceholderImage,
      lat: -35.056870091059500,
      lng: -7127561531404180
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Lontué Limitada",
      addres: "Lontué LimitadaMunicipal",
      image: PlaceholderImage,
      lat: -35.05432320184340,
      lng: -7127577645663980
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "San Carlos Lontué",
      addres: "San Carlos LontuéMunicipal",
      image: PlaceholderImage,
      lat: -35.05135022865280,
      lng: -7126816945938770
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Villa Encina",
      addres: "Villa EncinaMunicipal",
      image: PlaceholderImage,
      lat: -35.85594639479810,
      lng: -715019983172079
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Restautant La Sureña",
      addres: "Restautant La SureñaMunicipal",
      image: PlaceholderImage,
      lat: -35.095481068286600,
      lng: -7131532801307690
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Cruce San Pedro",
      addres: "Cruce San PedroMunicipal",
      image: PlaceholderImage,
      lat: -35.09547508560120,
      lng: -7132065457443850
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Centro de Acopio Estación",
      addres: "Centro de Acopio EstaciónMunicipal",
      image: PlaceholderImage,
      lat: -35.10248458069520,
      lng: -7130284534929430
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Manuel Rodriguez",
      addres: "Manuel RodriguezMunicipal",
      image: PlaceholderImage,
      lat: -35.10846048086050,
      lng: -7129101511307610
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Altos San Pedro 2",
      addres: "Altos San Pedro 2Municipal",
      image: PlaceholderImage,
      lat: -35.10177719003810,
      lng: -713064525929113
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Altos San Pedro 3",
      addres: "Altos San Pedro 3Municipal",
      image: PlaceholderImage,
      lat: -35.10514771977610,
      lng: -7130301936567980
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Altos San Pedro",
      addres: "Altos San PedroMunicipal",
      image: PlaceholderImage,
      lat: -35.10514771977610,
      lng: -7129855617027890
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Villa Consistorial",
      addres: "Villa ConsistorialMunicipal",
      image: PlaceholderImage,
      lat: -35.10738791662880,
      lng: -7128514710854660
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Servicio Seguro Social 2",
      addres: "Servicio Seguro Social 2Municipal",
      image: PlaceholderImage,
      lat: -35.114333227591500,
      lng: -7128110298310460
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Servicio Seguro Social",
      addres: "Servicio Seguro SocialMunicipal",
      image: PlaceholderImage,
      lat: -35.11639968372580,
      lng: -7127885743306650
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Luisa Moreira",
      addres: "Luisa MoreiraMunicipal",
      image: PlaceholderImage,
      lat: -35.10621470635300,
      lng: -7129162051443750
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "María Auxiliadora",
      addres: "María AuxiliadoraMunicipal",
      image: PlaceholderImage,
      lat: -35.10943795972540,
      lng: -7127710413160700
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Villa Quechereguas",
      addres: "Villa QuechereguasMunicipal",
      image: PlaceholderImage,
      lat: -35.11576948542390,
      lng: -7128009791408120
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Casa Blanca",
      addres: "Casa BlancaMunicipal",
      image: PlaceholderImage,
      lat: -35.0764061060764,
      lng: -7125272742994770
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Casa Blanca 2",
      addres: "Casa Blanca 2Municipal",
      image: PlaceholderImage,
      lat: -35.07594666591710,
      lng: -7125617167028290
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Casa Blanca 3",
      addres: "Casa Blanca 3Municipal",
      image: PlaceholderImage,
      lat: -35.075040818966000,
      lng: -712592610411648
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Casa Blanca 4",
      addres: "Casa Blanca 4Municipal",
      image: PlaceholderImage,
      lat: -35.07273187615280,
      lng: -7125586527257450
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Casa Blanca 5",
      addres: "Casa Blanca 5Municipal",
      image: PlaceholderImage,
      lat: -35.075299637033500,
      lng: -7125913908299560
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Casa Blanca 7",
      addres: "Casa Blanca 7Municipal",
      image: PlaceholderImage,
      lat: -35.06720051019570,
      lng: -7125427946990180
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Casa Blanca 6",
      addres: "Casa Blanca 6Municipal",
      image: PlaceholderImage,
      lat: -35.07807488419080,
      lng: -7126159101494340
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Casa Blanca 8",
      addres: "Casa Blanca 8Municipal",
      image: PlaceholderImage,
      lat: -35.07602644453460,
      lng: -712592849066249
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Valles de Casa Blanca",
      addres: "Valles de Casa BlancaMunicipal",
      image: PlaceholderImage,
      lat: -35.072473583934700,
      lng: -7125608540684900
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Valles de Casa Blanca 2",
      addres: "Valles de Casa Blanca 2Municipal",
      image: PlaceholderImage,
      lat: -35.06463024418000,
      lng: -712515891445212
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Entre Ríos 2",
      addres: "Entre Ríos 2Municipal",
      image: PlaceholderImage,
      lat: -35.066708883003900,
      lng: -7125562103096080
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Entre Ríos 4",
      addres: "Entre Ríos 4Municipal",
      image: PlaceholderImage,
      lat: -35.06535755361330,
      lng: -7125445338511740
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Pirihuin",
      addres: "PirihuinMunicipal",
      image: PlaceholderImage,
      lat: -35.04424314543270,
      lng: -7126750847535880
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Villa Francia",
      addres: "Villa FranciaMunicipal",
      image: PlaceholderImage,
      lat: -35.45408630651990,
      lng: -7168658997193550
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Santa Amalia",
      addres: "Santa AmaliaMunicipal",
      image: PlaceholderImage,
      lat: -35.06157994186850,
      lng: -7126283487816750
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Avda 7 de Abril",
      addres: "Avda 7 de AbrilMunicipal",
      image: PlaceholderImage,
      lat: -35.05718197926410,
      lng: -7126953763764830
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Viña Lontue Errazuriz",
      addres: "Viña Lontue ErrazurizMunicipal",
      image: PlaceholderImage,
      lat: -35.04981738959560,
      lng: -7126589093211170
    },
    {
      comuna: "MOLINA",
      region: "MAULE",
      name: "Parroquia Lontué",
      addres: "Parroquia LontuéMunicipal",
      image: PlaceholderImage,
      lat: -35.05464137367770,
      lng: -7127003401011800
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Plazoleta Población Los Conquistadores",
      addres: "Lautaro esquina Francisco de Aguirre, Población Los Conquistadores",
      image: PlaceholderImage,
      lat: -35.98875559827710,
      lng: -7231509595314150
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Estadio Miguel Alarcón del Barrio Estación (Interior)",
      addres: "Ruta Los Conquistadores s/n",
      image: PlaceholderImage,
      lat: -35.98631203347090,
      lng: -7231942548325460
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Cesfam Armando Williams",
      addres: "Av Padre Alberto Hurtado esq Ramiro Méndez",
      image: PlaceholderImage,
      lat: -35.982615337306200,
      lng: -7231375723994750
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Plazoleta Población Augusto Pinochet",
      addres: "Ramiro Méndez esq Ercilla",
      image: PlaceholderImage,
      lat: -35.981591534710400,
      lng: -7231272956266680
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Frontis CFT San Agustín",
      addres: "Av Monseñor Enrique Alvear S/N",
      image: PlaceholderImage,
      lat: -35.98398361211390,
      lng: -7231783062040120
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Plazoleta Villa Contreaux Población Fernández:",
      addres: "Vía Central esq Pasaje Cinco",
      image: PlaceholderImage,
      lat: -35.95814376601710,
      lng: -7230069118972220
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Sede Vecinal JJVV Alto Las Condes",
      addres: "Edmundo Muñoz esq Los Paltos",
      image: PlaceholderImage,
      lat: -35.95951712210720,
      lng: -7229917756808130
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Plaza Vieja",
      addres: "Victoria s/n",
      image: PlaceholderImage,
      lat: -35.96706271734160,
      lng: -7231054386978830
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Gimnasio Municipal Silvio Ruiz",
      addres: "Maipú 401",
      image: PlaceholderImage,
      lat: -35.965028988831000,
      lng: -723132686204025
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Polideportivo Alcalde Aquiles Rodríguez Sandoval",
      addres: "San Francisco esq Maipú",
      image: PlaceholderImage,
      lat: -35.97017860630560,
      lng: -72313320303216
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Centro Diurno del Adulto Mayor",
      addres: "Maipú N°2",
      image: PlaceholderImage,
      lat: -35.97056340469190,
      lng: -7231306770506170
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Liceo Antonio Varas (Interior)",
      addres: "Claudina Urrutia 252",
      image: PlaceholderImage,
      lat: -35.96946670873610,
      lng: -7231430225875310
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Liceo Claudina Urrutia de Lavín (Interior)",
      addres: "Catedral 525",
      image: PlaceholderImage,
      lat: -35.96594395471340,
      lng: -7231628892040230
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Estadio Fiscal Manuel Moya Medel",
      addres: "San Francisco s/n",
      image: PlaceholderImage,
      lat: -35.97047396061910,
      lng: -7232411764000880
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Bajo Hospital",
      addres: "Calle General Lagos, entre Perez y Montt",
      image: PlaceholderImage,
      lat: -35.963974371067800,
      lng: -7232430588471050
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Sede Vecinal JJVV Población Ávila",
      addres: "Federico Albert esq Caiquén",
      image: PlaceholderImage,
      lat: -35.96178569257490,
      lng: -7232596840506230
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Plaza Prat",
      addres: "Antonio Varas, esquina Santa María",
      image: PlaceholderImage,
      lat: -35.96615992309340,
      lng: -723298459915673
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Sede JJVV Villa Sán Sebastían",
      addres: "Calle Pilén frente Escuela María Gonzalez Vera",
      image: PlaceholderImage,
      lat: -35.95843337396010,
      lng: -723300024435533
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Aníbal Pinto",
      addres: "Aníbal Pinto ingreso Villa Magisterio",
      image: PlaceholderImage,
      lat: -35.96453282234140,
      lng: -723343961080519
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Villa Los Presidentes",
      addres: "Antonio Varas esq Pje Gabriel González Videla",
      image: PlaceholderImage,
      lat: -35.96591742048430,
      lng: -7233366600506200
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Plazoleta Población Cauquenes",
      addres: "Plazoleta Galdames Pje Siete entre Calle Tres y Calle Dos",
      image: PlaceholderImage,
      lat: -35.9626880207998,
      lng: -7233371544174360
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Plazoleta Poblacion Cauquenes",
      addres: "Plazoleta el Atardecer Pje Diecisiete",
      image: PlaceholderImage,
      lat: -35.963525168268100,
      lng: -7233530151012360
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Villa Esperanza",
      addres: "Sector Telecentro calle Nueva Dos esq Av Norte",
      image: PlaceholderImage,
      lat: -35.95692540259820,
      lng: -7233324901743780
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Sede Vecinal JJVV Población Los Troncos",
      addres: "Calle Los Castaños",
      image: PlaceholderImage,
      lat: -35.955322048876700,
      lng: -7233664426273290
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Villa Don Rafael",
      addres: "Cucao con Dalcahue",
      image: PlaceholderImage,
      lat: -35.95485538771560,
      lng: -7234402463389780
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Villa Doña Delfina",
      addres: "Calle Artemio Arellano",
      image: PlaceholderImage,
      lat: -35.9528425426836,
      lng: -7234709757438200
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Villa Doña Delfina",
      addres: "Calle Puqueledón",
      image: PlaceholderImage,
      lat: -35.953671442191100,
      lng: -723468882166806
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Cancha Porongo",
      addres: "Pje Pablo de Rocka esq María Ruiz",
      image: PlaceholderImage,
      lat: -35.95304880222720,
      lng: -723494644896576
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Villa Los Robles",
      addres: "Acceso A Villa Camino a Chanco esq Calluranquil",
      image: PlaceholderImage,
      lat: -35.989784575073200,
      lng: -7230757796427050
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Villa Los Robles",
      addres: "Multicancha Calle Calluranquil esq Pje Huedque",
      image: PlaceholderImage,
      lat: -35.9908193907826,
      lng: -7231402597354350
    },
    {
      comuna: "Cauquenes",
      region: "MAULE",
      name: "Villa Los Trigales",
      addres: "Área Verde, Av Norte",
      image: PlaceholderImage,
      lat: -35.947299247975100,
      lng: -7234687554722360
    },
    {
      comuna: "LINARES",
      region: "MAULE",
      name: "Casa de la cultura",
      addres: "Av Valentín Letelier/ Manuel Rodríguez",
      image: PlaceholderImage,
      lat: -35.84913930550960,
      lng: -7159849549362120
    },
    {
      comuna: "LINARES",
      region: "MAULE",
      name: "Oficina de Turismo",
      addres: "Calle Kurt Moler / calle O “Higgins",
      image: PlaceholderImage,
      lat: -35.844636088033200,
      lng: -7159853686632370
    },
    {
      comuna: "LINARES",
      region: "MAULE",
      name: "Instituto Politécnico",
      addres: "Avda presidente Ibáñez S/N",
      image: PlaceholderImage,
      lat: -35.839356001118500,
      lng: -715979930681863
    },
    {
      comuna: "LINARES",
      region: "MAULE",
      name: "Escuela Artillería",
      addres: "Pdte Ibáñez 643",
      image: PlaceholderImage,
      lat: -35.837011874814800,
      lng: -7160905174740030
    },
    {
      comuna: "LINARES",
      region: "MAULE",
      name: "Dimaao Linares",
      addres: "Maipú # 1074",
      image: PlaceholderImage,
      lat: -35.84972750199230,
      lng: -7158726488972930
    },
    {
      comuna: "LINARES",
      region: "MAULE",
      name: "Escuela Carlos Ibáñez del Campo",
      addres: "Patricio Lynch S/N",
      image: PlaceholderImage,
      lat: -35.8575893861524,
      lng: -715812354725428
    },
    {
      comuna: "LINARES",
      region: "MAULE",
      name: "Escuela Margot Loyola",
      addres: "Avda Yungay 833",
      image: PlaceholderImage,
      lat: -35.840481107262500,
      lng: -7160203883390540
    },
    {
      comuna: "CURICO",
      region: "MAULE",
      name: "ESTADIO LA GRANJA",
      addres: "Avda Freire",
      image: PlaceholderImage,
      lat: -34.9741035691103,
      lng: -7122960902991810
    },
    {
      comuna: "TENO",
      region: "MAULE",
      name: "POBLACIÓN DON ALVARO I",
      addres: "Calle Arturo Prat esquina calle El Membrillo, Población don Álvaro 1, Teno urbano",
      image: PlaceholderImage,
      lat: -34.87496459276810,
      lng: -7116587885959600
    },
    {
      comuna: "CURICO",
      region: "MAULE",
      name: "PL CURICO EIRL",
      addres: "CObispo Enrique Chávez 245",
      image: PlaceholderImage,
      lat: -34.988511828940800,
      lng: -7124693519283230
    },
    {
      comuna: "Yungay",
      region: "ÑUBLE",
      name: "Departamento Aseo y Ornato/Bodega Municipal",
      addres: "Pisagua 181",
      image: PlaceholderImage,
      lat: -37.11947217766470,
      lng: -7202242114731420
    },
    {
      comuna: "Yungay",
      region: "ÑUBLE",
      name: "Polideportivo Municipal",
      addres: "Ignacio Carrera Pinto 348",
      image: PlaceholderImage,
      lat: -37.126031830138200,
      lng: -7201063740313820
    },
    {
      comuna: "Yungay",
      region: "ÑUBLE",
      name: "Delegación Municipal Campanario",
      addres: "Avenida Argentina 782",
      image: PlaceholderImage,
      lat: -37.13360443201710,
      lng: -7219060033566420
    },
    {
      comuna: "El Carmen",
      region: "ÑUBLE",
      name: "Punto Verde DIMAO",
      addres: "Balmaceda 320",
      image: PlaceholderImage,
      lat: -36.89787309416120,
      lng: -7202389006112480
    },
    {
      comuna: "San Fabian de Alico",
      region: "ÑUBLE",
      name: "Plaza de San Fabian",
      addres: "Calle Indepemndencia esquina Caupolican Plaza de Armas",
      image: PlaceholderImage,
      lat: -36.556577236026500,
      lng: -7154981171851720
    },
    {
      comuna: "San Fabian de Alico",
      region: "ÑUBLE",
      name: "Estacionamientos Cooperativa Agua Potable Paso Ancho",
      addres: "Ruta N31 kilometro 30",
      image: PlaceholderImage,
      lat: -36.46193555430080,
      lng: -716808550807103
    },
    {
      comuna: "Ranquil",
      region: "ÑUBLE",
      name: "Municipalidad de Ranquil",
      addres: "Nicolas leon 512",
      image: PlaceholderImage,
      lat: -36.604571003989700,
      lng: -725335846712221
    },
    {
      comuna: "Ranquil",
      region: "ÑUBLE",
      name: "Casa de la Cultura",
      addres: "Nicasio Alarcon 416",
      image: PlaceholderImage,
      lat: -36.60345215365240,
      lng: -7253547502405110
    },
    {
      comuna: "Ranquil",
      region: "ÑUBLE",
      name: "Plaza Población 10 de Julio",
      addres: "10 de julio",
      image: PlaceholderImage,
      lat: -36.60500190677660,
      lng: -7254472963996630
    },
    {
      comuna: "Trehuaco",
      region: "ÑUBLE",
      name: "Frontis Municipalidad de Trehuaco",
      addres: "Gonzalo Urrejola",
      image: PlaceholderImage,
      lat: -36.43001805668320,
      lng: -7266557400929730
    },
    {
      comuna: "Trehuaco",
      region: "ÑUBLE",
      name: "Costado Municipalidad de Trehuaco",
      addres: "Lautaro",
      image: PlaceholderImage,
      lat: -36.43068351667290,
      lng: -7266570902489250
    },
    {
      comuna: "Chillan",
      region: "ÑUBLE",
      name: "Parque Oriente",
      addres: "C Cerro Del León 118, Chillán",
      image: PlaceholderImage,
      lat: -36.61003451142330,
      lng: -7206928281666790
    },
    {
      comuna: "Chillan",
      region: "ÑUBLE",
      name: "Parque Quilamapu",
      addres: "Entrada Por La Espiga 999, Chillán",
      image: PlaceholderImage,
      lat: -36.589622371135600,
      lng: -72090933118515
    },
    {
      comuna: "Chillan",
      region: "ÑUBLE",
      name: "Punto Verde Municipal",
      addres: "Arturo Prat #153, Chillán",
      image: PlaceholderImage,
      lat: -36.60933896204550,
      lng: -721112209031733
    },
    {
      comuna: "Cobquecura",
      region: "ÑUBLE",
      name: "Plaza de Armas de la comuna",
      addres: "Calle Independencia entre calles Latorre y Ohiggins",
      image: PlaceholderImage,
      lat: -36.13172989586750,
      lng: -7279162690009030
    },
    {
      comuna: "Cobquecura",
      region: "ÑUBLE",
      name: "Punto verde Plaza de Buchupureo",
      addres: "Calle Diego de Portalez con Sargento Aldea",
      image: PlaceholderImage,
      lat: -36.07379441816240,
      lng: -7277664710632740
    },
    {
      comuna: "Pinto",
      region: "ÑUBLE",
      name: "Estadio Municipal",
      addres: "Balmaceda S/N",
      image: PlaceholderImage,
      lat: -36.70177014737310,
      lng: -7189772873811400
    },
    {
      comuna: "Pinto",
      region: "ÑUBLE",
      name: "Supermercado El Refugio",
      addres: "Las Trancas Km 72",
      image: PlaceholderImage,
      lat: -36.91489231414600,
      lng: -7150130299761560
    },
    {
      comuna: "Pinto",
      region: "ÑUBLE",
      name: "Escuela Javier Jarpa Sotomayor",
      addres: "Los Lleuques Km 64",
      image: PlaceholderImage,
      lat: -36.84590846343480,
      lng: -7165796077315800
    },
    {
      comuna: "Pinto",
      region: "ÑUBLE",
      name: "Sede Agua Potable El Chacay",
      addres: "El Chacay Km 42",
      image: PlaceholderImage,
      lat: -36.69980415681690,
      lng: -7189331925835850
    },
    {
      comuna: "Pinto",
      region: "ÑUBLE",
      name: "Sede Juntade Vecinos Valle De Recinto",
      addres: "Camino Termas De Chillan Km 48",
      image: PlaceholderImage,
      lat: -36.8361796438452,
      lng: -7166617001451800
    },
    {
      comuna: "Pinto",
      region: "ÑUBLE",
      name: "Supermercado El Escudo",
      addres: "Recinto Km 52",
      image: PlaceholderImage,
      lat: -36.70326445513260,
      lng: -7189315625693390
    },
    {
      comuna: "Portezuelo",
      region: "ÑUBLE",
      name: "Liceo Nibaldo Sepúlda Fernandez",
      addres: "Ruta N-480 340, San Martin 386,",
      image: PlaceholderImage,
      lat: -36.527454879852900,
      lng: -7242945507434370
    },
    {
      comuna: "Portezuelo",
      region: "ÑUBLE",
      name: "Liceo Parroquial Nuestra señora del Carmen",
      addres: "Catedral 496, Portezuelo",
      image: PlaceholderImage,
      lat: -36.52675615769420,
      lng: -724279452418173
    },
    {
      comuna: "San Nicolas",
      region: "ÑUBLE",
      name: "Punto Verde Plaza De Los Niños",
      addres: "Balmaceda Esquina Arturo Prat Sn, San Nicolas",
      image: PlaceholderImage,
      lat: -36.50380095040200,
      lng: -7221306967050960
    },
    {
      comuna: "San Nicolas",
      region: "ÑUBLE",
      name: "Punto Verde Nuevo Amanecer",
      addres: "Angela Valladares Esquina La Quintrala Sn, San Nicolás",
      image: PlaceholderImage,
      lat: -36.49975712127870,
      lng: -7221563353570720
    },
    {
      comuna: "San Nicolas",
      region: "ÑUBLE",
      name: "Punto Verde Puente Ñuble",
      addres: "Avenida Los Tilos Esquina Borde Rio Sn, Puente Ñuble, San Nicolas",
      image: PlaceholderImage,
      lat: -36.54663279156410,
      lng: -7209226841851790
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 01- JV Villa Don Ambrosio",
      addres: "Ganaderos 696",
      image: PlaceholderImage,
      lat: -36.627209910757500,
      lng: -7211095279336870
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 02- Casa del Adulto Mayor",
      addres: "Gacitúa 250",
      image: PlaceholderImage,
      lat: -36.626166553340700,
      lng: -7212020835464890
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 03- Parque Monumental Bernando O'Higgins",
      addres: "Virrey Don Ambrosio 860-832",
      image: PlaceholderImage,
      lat: -36.62106125195430,
      lng: -721313786320076
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 04- CESFAM Michelle Bachelet Jeria",
      addres: "Huambalí 1279",
      image: PlaceholderImage,
      lat: -36.630040752406100,
      lng: -721067233491931
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 05- JV Los Naranjos de Castilla",
      addres: "El Comendador 609",
      image: PlaceholderImage,
      lat: -36.62937836227270,
      lng: -7211249666084210
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 06 - Condominio Enrique Knotte",
      addres: "Luis Araneda 1234",
      image: PlaceholderImage,
      lat: -36.62776114170340,
      lng: -7213776283569850
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 07- Altos de Santa Rita",
      addres: "Baquedano 1570",
      image: PlaceholderImage,
      lat: -36.638066799425,
      lng: -7213172282035740
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 08- Villa Diego Portales",
      addres: "Paula Jaraquemada 202",
      image: PlaceholderImage,
      lat: -36.633848124716600,
      lng: -7212285614919280
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 09 - Edificio Consistorial",
      addres: "Serrano 300",
      image: PlaceholderImage,
      lat: -36.62106419570160,
      lng: -721338256338533
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 10- Centro Comunitario",
      addres: "Juan Martínez de Rosas 498",
      image: PlaceholderImage,
      lat: -36.62423233585110,
      lng: -7212629766268820
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 11 - Liceo Tomás Lago",
      addres: "Pje Serrano 1212",
      image: PlaceholderImage,
      lat: -36.63149475273060,
      lng: -721365108780281
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 12 - Escuela Los Coligues",
      addres: "Escuela Los Coligües, Ruta N-684 s/n",
      image: PlaceholderImage,
      lat: -36.637334654034000,
      lng: -7222997262646940
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 13 -Bellavista/Rucapequén",
      addres: "Plaza Bellavista, ruta N-676 s/n",
      image: PlaceholderImage,
      lat: -36.67215263540620,
      lng: -722795178894918
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 14- Lomas de Maipón",
      addres: "Hurtado de Mendoza 1337",
      image: PlaceholderImage,
      lat: -36.61662500661740,
      lng: -721364776780291
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 15-Villa Santa Inés",
      addres: "Gral Velásquez 1380",
      image: PlaceholderImage,
      lat: -36.61826292726900,
      lng: -721379383338535
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 16- Villa Las Violetas",
      addres: "Pje Las Violetas s/n Prolongación Paula Jaraquemada",
      image: PlaceholderImage,
      lat: -36.63012846330880,
      lng: -7214234883121790
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 17- Hacienda Los Fundadores",
      addres: "Fernando de Alvarado - Pasaje Corregidor, Chillán Viejo, Chillán, Ñuble",
      image: PlaceholderImage,
      lat: -36.615102439211800,
      lng: -7213928342877190
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto Verde 18- Quilmo Bajo",
      addres: "Cornelia Olivares 1266",
      image: PlaceholderImage,
      lat: -36.629544912321900,
      lng: -7213957802035810
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Punto 19 - Villa Los Colihues",
      addres: "Calle los Boldos 5, ruta N-684",
      image: PlaceholderImage,
      lat: -36.63769695545970,
      lng: -7222000544790890
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Jaula PET 1- Plaza Mayor Isabel Riquelme",
      addres: "Juan Martínez de Rosas 801-897",
      image: PlaceholderImage,
      lat: -36.62300675887950,
      lng: -7213164244138460
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Jaula PET1- Ángel Parra",
      addres: "Parque Monumental Bernardo Ohiggins, Ángel Parra 292-392",
      image: PlaceholderImage,
      lat: -36.6218164769556,
      lng: -7213103503200750
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Jaula PET1 - PMBO",
      addres: "Parque Monumental Bernardo Ohiggins, Virrey Don Ambrosio 860-832",
      image: PlaceholderImage,
      lat: -36.62109905115360,
      lng: -7213362648893880
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Ecobotella PET1 - Villa Eduardo Frei",
      addres: "Los Litres 105",
      image: PlaceholderImage,
      lat: -36.630537498410500,
      lng: -7212330240031160
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Ecobotella PET1 - San Esteban",
      addres: "Pje San Pedro 101",
      image: PlaceholderImage,
      lat: -36.631287723287000,
      lng: -7213955867802800
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Ecobotella PET1- Galería Estadio Municipal (eventos deportivos)",
      addres: "Calle Antonio Varas 1409-1271",
      image: PlaceholderImage,
      lat: -36.62364418626120,
      lng: -7213976538066780
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Ecobotella PET1- Ríos del sur (Río Negro)",
      addres: "Calle río negro 1340",
      image: PlaceholderImage,
      lat: -36.63054414263530,
      lng: -7210455563200690
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Ecobotella PET1- Liceo Juan Arturo Pacheco Altamirano",
      addres: "Sotomayor 401",
      image: PlaceholderImage,
      lat: -36.62334614865300,
      lng: -7212533016856450
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Ecobotella PET1-Cancha El Pewen",
      addres: "Pje El Maitén 1316",
      image: PlaceholderImage,
      lat: -36.63206672179540,
      lng: -7210679016663250
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Ecobotella PET1- Sede Barros Arana",
      addres: "Río Diguillín 1627",
      image: PlaceholderImage,
      lat: -36.63316626682640,
      lng: -7210112564919290
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Ecobotella PET1- Plaza Eugenio Araneda",
      addres: "Pje Dos 19-11",
      image: PlaceholderImage,
      lat: -36.629913297828200,
      lng: -721350601451265
    },
    {
      comuna: "Chillán Viejo",
      region: "ÑUBLE",
      name: "Ecobotella PET1 - Veterinaria Municipal",
      addres: "Calle Antonio Varas 1409-1271",
      image: PlaceholderImage,
      lat: -36.62516387943630,
      lng: -7213839286618900
    },
    {
      comuna: "Bulnes",
      region: "ÑUBLE",
      name: "Comité Unión La Estrella Solitaria",
      addres: "Camino Tres Esquinas Km 6,5 Parcela 48 Lote 8",
      image: PlaceholderImage,
      lat: -36.789768277363400,
      lng: -722072967434024
    },
    {
      comuna: "Bulnes",
      region: "ÑUBLE",
      name: "JJVV Las Vegas de Libuy",
      addres: "Sede Comunitaria Villa Santa Luisa, Cruce Los Alemanes",
      image: PlaceholderImage,
      lat: -36.79097497374970,
      lng: -7238708051158910
    },
    {
      comuna: "Bulnes",
      region: "ÑUBLE",
      name: "JJVV Las Carmelitas",
      addres: "Sector Las Carmelitas s/n, frente a Sede comunitaria",
      image: PlaceholderImage,
      lat: -36.74048121510630,
      lng: -7229391822224760
    },
    {
      comuna: "Bulnes",
      region: "ÑUBLE",
      name: "JJVV Las Lomas de Santa Clara",
      addres: "Sede Comunitaria Calle Bdo O'higgins #10, Santa Clara",
      image: PlaceholderImage,
      lat: -36.827190590327300,
      lng: -723299820265438
    },
    {
      comuna: "Bulnes",
      region: "ÑUBLE",
      name: "JJVV El Espinar",
      addres: "Callejón Santa Elsa s/n, Frente a Charquería El Espinar",
      image: PlaceholderImage,
      lat: -36.755055023638100,
      lng: -7232871940039920
    },
    {
      comuna: "Bulnes",
      region: "ÑUBLE",
      name: "JJVV Manuel Bulnes",
      addres: "La Campesina 621, Población Frei",
      image: PlaceholderImage,
      lat: -36.74221303787330,
      lng: -7229721117471250
    },
    {
      comuna: "Bulnes",
      region: "ÑUBLE",
      name: "JJVV El Carmen",
      addres: "Entrada Sector El Carmen",
      image: PlaceholderImage,
      lat: -36.89751554783630,
      lng: -7203021335013250
    },
    {
      comuna: "Bulnes",
      region: "ÑUBLE",
      name: "JJVV Rancho Lindo",
      addres: "Calle Los Acacios 332, Sector Rancho Lindo",
      image: PlaceholderImage,
      lat: -36.755831913932500,
      lng: -7238334353823000
    },
    {
      comuna: "Bulnes",
      region: "ÑUBLE",
      name: "JJVV Milahue",
      addres: "Sede Comunitaria Calle Alegre s/n, Sector Milahue",
      image: PlaceholderImage,
      lat: -36.84160502572880,
      lng: -7223699147657940
    },
    {
      comuna: "Bulnes",
      region: "ÑUBLE",
      name: "JJVV Larqui Poniente",
      addres: "Sede Comunitaria Larqui Poniente",
      image: PlaceholderImage,
      lat: -36.70612207307840,
      lng: -7233761559364490
    },
    {
      comuna: "Bulnes",
      region: "ÑUBLE",
      name: "JJVV Maule",
      addres: "Sector El Quillay s/n, Sector Maule",
      image: PlaceholderImage,
      lat: -36.73723802698010,
      lng: -7230859301143820
    },
    {
      comuna: "Bulnes",
      region: "ÑUBLE",
      name: "JJVV Las Viñas",
      addres: "Sector Las Viñas Km 10",
      image: PlaceholderImage,
      lat: -36.750059405582700,
      lng: -7243710573324060
    },
    {
      comuna: "Bulnes",
      region: "ÑUBLE",
      name: "JJVV Villa El Lucero San José",
      addres: "Sede Comunitaria Calle Reineiro Iraira, Villa El Lucero San José",
      image: PlaceholderImage,
      lat: -36.859342815303600,
      lng: -7217187127332750
    },
    {
      comuna: "Bulnes",
      region: "ÑUBLE",
      name: "Gimnasio Municipal",
      addres: "Calle Arturo Prat 85",
      image: PlaceholderImage,
      lat: -36.74608041185890,
      lng: -7229463361850440
    },
    {
      comuna: "Bulnes",
      region: "ÑUBLE",
      name: "Edificio DIDEL",
      addres: "Calle Santa María 38",
      image: PlaceholderImage,
      lat: -36.74403459195590,
      lng: -723021206915152
    },
    {
      comuna: "San Carlos",
      region: "ÑUBLE",
      name: "Dirección de Medio Ambiente, Aseo y Ornato",
      addres: "Calle de Servicio Nora Pereira Rissetti 1145",
      image: PlaceholderImage,
      lat: -36.43810704992220,
      lng: -7195889131542270
    },
    {
      comuna: "San Carlos",
      region: "ÑUBLE",
      name: "JJVV Monte Blanco",
      addres: "Monte Blanco S/N",
      image: PlaceholderImage,
      lat: -36.425865177795900,
      lng: -7196883168162720
    },
    {
      comuna: "San Carlos",
      region: "ÑUBLE",
      name: "JJVV Cachapoal",
      addres: "Cachapoal S/N (costado de sede comunitaria)",
      image: PlaceholderImage,
      lat: -36.46084442622670,
      lng: -7173010417148260
    },
    {
      comuna: "San Carlos",
      region: "ÑUBLE",
      name: "JJVV Santa Rosa de Ninquihue",
      addres: "Santa Rosa de Ninquihue S/N",
      image: PlaceholderImage,
      lat: -36.503778021329200,
      lng: -719417094530114
    },
    {
      comuna: "San Carlos",
      region: "ÑUBLE",
      name: "JJVV Quilelto",
      addres: "Quilelto S/N",
      image: PlaceholderImage,
      lat: -36.503705502157300,
      lng: -7194216054496000
    },
    {
      comuna: "San Carlos",
      region: "ÑUBLE",
      name: "JJVV Cuadrapangue",
      addres: "Cuadrapangue S/N",
      image: PlaceholderImage,
      lat: -36.43453620320630,
      lng: -7196675918647990
    },
    {
      comuna: "San Ignacio",
      region: "ÑUBLE",
      name: "PL San Ignacio 1",
      addres: "",
      image: PlaceholderImage,
      lat: -36.79965785083450,
      lng: -7202983529673740
    },
    {
      comuna: "San Ignacio",
      region: "ÑUBLE",
      name: "PL San Ignacio 2",
      addres: "",
      image: PlaceholderImage,
      lat: -36.79469598434860,
      lng: -7203256414325660
    },
    {
      comuna: "Coelemu",
      region: "ÑUBLE",
      name: "PL Coelemu",
      addres: "",
      image: PlaceholderImage,
      lat: -36.48748221373180,
      lng: -7270128990559260
    },
    {
      comuna: "Quirihue",
      region: "ÑUBLE",
      name: "Jardín Infantil Semillitas de Amor",
      addres: "Calle Violeta Parra",
      image: PlaceholderImage,
      lat: -36.276362466657000,
      lng: -7253502944699270
    },
    {
      comuna: "Quirihue",
      region: "ÑUBLE",
      name: "Jardín Infantil Hijos del Sol",
      addres: "Carrera N°1213",
      image: PlaceholderImage,
      lat: -36.27829364054700,
      lng: -7254428803236120
    },
    {
      comuna: "Quirihue",
      region: "ÑUBLE",
      name: "Escuela Nueva América",
      addres: "Blanco Encalada N°281",
      image: PlaceholderImage,
      lat: -36.28200417289810,
      lng: -7254079257157110
    },
    {
      comuna: "Quirihue",
      region: "ÑUBLE",
      name: "Escuela Grumete Cortez",
      addres: "Independencia N°399",
      image: PlaceholderImage,
      lat: -36.284972256049900,
      lng: -7254301583482300
    },
    {
      comuna: "Quirihue",
      region: "ÑUBLE",
      name: "Punto Limpio Municipal",
      addres: "Independencia N°258",
      image: PlaceholderImage,
      lat: -36.28272169451750,
      lng: -7254102347508290
    },
    {
      comuna: "SAN CARLOS",
      region: "ÑUBLE",
      name: "PL SAN CARLOS",
      addres: "Cam San Agustín, lote 2, San Carlos, Ñuble",
      image: PlaceholderImage,
      lat: -36.42895748240400,
      lng: -7194541938790860
    },
    {
      comuna: "CONCEPCION",
      region: "BIOBIO",
      name: "PL SODIMAC CONCEPCION",
      addres: "Avenida Los Carrera Poniente 301",
      image: PlaceholderImage,
      lat: -36.829802441058,
      lng: -7306380595962340
    },
    {
      comuna: "CONCEPCION",
      region: "BIOBIO",
      name: "Instituto Teletón Concepción",
      addres: "Los Acacios 1656, Villa San Pedro",
      image: PlaceholderImage,
      lat: -36.84248623288950,
      lng: -7310733819205170
    },
    {
      comuna: "CONCEPCION",
      region: "BIOBIO",
      name: "PL RESIMPLE CONCEPCIÓN COLLAO LIDER",
      addres: "Av San Juan Bosco 2084, Concepción, Chile",
      image: PlaceholderImage,
      lat: -36.81757521770110,
      lng: -7303222594402350
    },
    {
      comuna: "CONCEPCION",
      region: "BIOBIO",
      name: "PL RESIMPLE CONCEPCIÓN ANDALIEN ACUENTA",
      addres: "Av Andalién 950, Concepción, Chile",
      image: PlaceholderImage,
      lat: -36.79317249051190,
      lng: -7303563956988540
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL RESIMPLE LOS ANGELES 1 LIDER",
      addres: "Av Sor Vicenta 2575, Los Angeles, Bío Bío, Chile",
      image: PlaceholderImage,
      lat: -37.44618268627600,
      lng: -7233206070990950
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL RESIMPLE LOS ANGELES 2 SODIMAC",
      addres: "Av Alemania 850, Los Angeles, Chile",
      image: PlaceholderImage,
      lat: -37.46766819778260,
      lng: -7233575825908800
    },
    {
      comuna: "CONCEPCION",
      region: "BIOBIO",
      name: "CPC LORENZO ARENAS",
      addres: "DIEGO DE ALMAGRO 540, LORENZO ARENAS",
      image: PlaceholderImage,
      lat: -36.80800493413500,
      lng: -7306668134296620
    },
    {
      comuna: "CONCEPCION",
      region: "BIOBIO",
      name: "DELEGACIÓN BARRIO NORTE",
      addres: "MANUEL GUTIÉRREZ 1745, BARRIO NORTE",
      image: PlaceholderImage,
      lat: -36.8047211089175,
      lng: -7304750257532420
    },
    {
      comuna: "CONCEPCION",
      region: "BIOBIO",
      name: "CENTRO DE CREACIÓN CONCEPCIÓN - C3",
      addres: "JUAN MARTÍNEZ DE ROZAS 520",
      image: PlaceholderImage,
      lat: -36.82216535876340,
      lng: -7305512345488320
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Sede Bachelet Jeria",
      addres: "San Martin 3065, 4560890 Biobio, Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.26236788214010,
      lng: -7270323484635900
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Sala Cuna Arcofris,",
      addres: "Rucahue #1700 (Esquina San Martin)",
      image: PlaceholderImage,
      lat: -37.26596622396730,
      lng: -7270530310584200
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Sede Social Villa El Pino,",
      addres: "La Araucaria Nº300",
      image: PlaceholderImage,
      lat: -37.27111506606080,
      lng: -7270771729032690
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Costanera Sur",
      addres: "Costanera Sur 499, Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.28033974375040,
      lng: -7270810417683230
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Acceso a pasaje Cancha Santa María",
      addres: "Los Tilos con Manuel Rodriguez (acceso a pasaje Cancha Santa María)",
      image: PlaceholderImage,
      lat: -37.27990442503800,
      lng: -7270080713414940
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Área Verde",
      addres: "C Balmaceda 246, 4560602 Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.27942357040600,
      lng: -7271494910889340
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Sector botes Moyano",
      addres: "Costanera Norte con Calle Gabriela Mistral -",
      image: PlaceholderImage,
      lat: -37.279348000063800,
      lng: -727094341372308
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "sector SkatePark",
      addres: "Costanera 299, Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.27725660268330,
      lng: -7270663920681930
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "sector Biblioteca Municipal",
      addres: "Avenida Los Ríos",
      image: PlaceholderImage,
      lat: -37.279613577599800,
      lng: -727145564626437
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Multicancha calle Los Aromos Pob Waldemar Schütz",
      addres: "calle Los Aromos",
      image: PlaceholderImage,
      lat: -37.2766906021492,
      lng: -7271139315243600
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Plaza Activa sector El Pino Alto/ El Mirador",
      addres: "Insigne 201, 4560861 Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.27216361383180,
      lng: -7270699720340320
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Sede Social Junta de Vecinos Villa Laja",
      addres: "Río Laja 106, Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.257086989704300,
      lng: -7269015083000290
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "JIVV Los Ríos",
      addres: "El Pino 144, 4560196 Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.268542645972200,
      lng: -7271126636638430
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "JJVV Villa Capponi",
      addres: "Nápoles 86, Napoles, Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.285414737807000,
      lng: -726968005387559
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "JIVV Celulosa",
      addres: "Quillayal 1772, Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.26372864111890,
      lng: -7270610274369090
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Calle San Martín con Pasaje San Rafael",
      addres: "San Rafael 23, 4560469 Biobio, Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.26966644135910,
      lng: -7270979947761410
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Escuela Las Ciénagas (DAEM)",
      addres: "Cam a Perales, 4560000 Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.34977064899970,
      lng: -7248642710018350
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "JIV/ Santa Ana - sector Capilla Santa Ana",
      addres: "Pje Sta Ana 3301, Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.261998102452000,
      lng: -7270346125922780
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "JJVV Chorrillos- Al costado de Plaza de Juegos",
      addres: "Chorrillos 309, Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.27412375050870,
      lng: -7271976716196190
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "JVV Lavanderos - Sede Social",
      addres: "Cerrillos 1711, 4560903 Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.26238212614020,
      lng: -7270489955689210
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "JJVV Los Ciénagas - Sede Social",
      addres: "C Las Cienagas 3141, 4560323 Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.261759398908300,
      lng: -7270347678148690
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "JIVV Quiebrafrenos",
      addres: "",
      image: PlaceholderImage,
      lat: -37.342888353907200,
      lng: -7257251663149850
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "JJVV Santa Elena",
      addres: "",
      image: PlaceholderImage,
      lat: -37.368366841108400,
      lng: -7251990471934680
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "JJVV Las Playas",
      addres: "",
      image: PlaceholderImage,
      lat: -37.26559630850880,
      lng: -7270529025456590
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Gimnasio Municipal",
      addres: "Av Los Ríos 860",
      image: PlaceholderImage,
      lat: -37.26230628051580,
      lng: -7270805439081930
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Escuela La Colonia (DAEM)",
      addres: "PC65+CG, Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.28831144682630,
      lng: -7258902938026490
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Escuela Nivequeten",
      addres: "Los Magnolios 14, Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.28011249430020,
      lng: -727017232767133
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Parque Capponi (contenedor maritimo)",
      addres: "Los Arrayanes 247, 4560805 Laja, Bío Bío",
      image: PlaceholderImage,
      lat: -37.281464803567800,
      lng: -7269748174730320
    },
    {
      comuna: "LAJA",
      region: "BIOBIO",
      name: "Escuela Amanda Labarca",
      addres: "Luis Cruz Martínez N° 15",
      image: PlaceholderImage,
      lat: -37.27977012733150,
      lng: -7271668158961430
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Dir Medio Ambiente",
      addres: "Doc Fernando Urra 236",
      image: PlaceholderImage,
      lat: -36.91837658722600,
      lng: -730323671896577
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Dir Seguridad C",
      addres: "Orozimbo Barbosa 104, Parque Los Castaños",
      image: PlaceholderImage,
      lat: -36.91597210397440,
      lng: -7303006351480150
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Altos de Chiguayante",
      addres: "Pasaje 19 con Calle D",
      image: PlaceholderImage,
      lat: -36.92069441698180,
      lng: -7303371189456820
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "La Copa",
      addres: "Emilio Rojas Esg Matte",
      image: PlaceholderImage,
      lat: -36.92074798828810,
      lng: -7302948424213350
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Cochrane (Ex Liceo P)",
      addres: "Cochrane, esg La Marina",
      image: PlaceholderImage,
      lat: -36.92931466637280,
      lng: -7302943819390040
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Punto Reciclaje Santa Isabel",
      addres: "Cochrane con Manuel Rodriguez",
      image: PlaceholderImage,
      lat: -36.922255367345600,
      lng: -7302739555902530
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Chiguay 1",
      addres: "chiguay, esg La Marina",
      image: PlaceholderImage,
      lat: -36.9259357218998,
      lng: -7302940624487040
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Las Condes",
      addres: "Las Condes, esg Manuel Rodriguez",
      image: PlaceholderImage,
      lat: -36.92656275570540,
      lng: -7302513229519410
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "la Marina",
      addres: "La Marina / Las Condes",
      image: PlaceholderImage,
      lat: -36.92856909573780,
      lng: -7302971920792790
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Los Héroes",
      addres: "Los héroes / La Marina",
      image: PlaceholderImage,
      lat: -36.92986763255530,
      lng: -7302682018114230
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Pinares 3 Cesfam",
      addres: "Pinares / La Marina",
      image: PlaceholderImage,
      lat: -36.93349478534700,
      lng: -7302761230130570
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Pinares Plaza",
      addres: "Pinares/ Blindado Blanco",
      image: PlaceholderImage,
      lat: -36.932083560538100,
      lng: -7302383580584240
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Punto Limpio Unimarc",
      addres: "Libertad con Manuel Rodriguez",
      image: PlaceholderImage,
      lat: -36.93408956369010,
      lng: -730218418527735
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Altumalal",
      addres: "Libertad 492",
      image: PlaceholderImage,
      lat: -36.934177600604400,
      lng: -7302350423118110
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "E Pinon",
      addres: "Avda 101 con Progreso",
      image: PlaceholderImage,
      lat: -36.93805319518610,
      lng: -7302735830130540
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "JJVlos Pensamientos",
      addres: "Calle 5, Los Pensamientos",
      image: PlaceholderImage,
      lat: -36.950707429543600,
      lng: -7301768433389900
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Manquimavida",
      addres: "Manquimavida, esc Pasaje 2 al Rio",
      image: PlaceholderImage,
      lat: -36.9433543005913,
      lng: -7302610234917190
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Los Aromos",
      addres: "Los Aromos / Los Radales",
      image: PlaceholderImage,
      lat: -36.94557207863590,
      lng: -730264501204324
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Punto Limpio Acuenta",
      addres: "Manuel Rodriguez/ Coquimbo",
      image: PlaceholderImage,
      lat: -36.94485759901290,
      lng: -7301721192033670
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Ciudad Alpina",
      addres: "Esperanza, pasaje 1, esq Calle 1",
      image: PlaceholderImage,
      lat: -36.94505571009550,
      lng: -7301418986693280
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Villa Manquimavida",
      addres: "Calle 2, esq Pasaje 2, V Manquimavida",
      image: PlaceholderImage,
      lat: -36.94252564050880,
      lng: -7300944903277150
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Villa Alonso de Ercilla",
      addres: "Porvenir / pasaje 9",
      image: PlaceholderImage,
      lat: -36.94883852604590,
      lng: -7301828559393890
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Los Pinos",
      addres: "Yugoeslavia, esg Calle 3",
      image: PlaceholderImage,
      lat: -36.95036672639380,
      lng: -7301877682058350
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Villa Ines Frodden",
      addres: "Calle los Pinos / Graciela Lacoste",
      image: PlaceholderImage,
      lat: -36.94999827526140,
      lng: -7301593716964400
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "JJVV Villa Leonera",
      addres: "Hamburgo con Italia",
      image: PlaceholderImage,
      lat: -36.95326663512070,
      lng: -7301764710910510
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Villa La Union",
      addres: "Pasaje 1, esq Manuel Rodriguez",
      image: PlaceholderImage,
      lat: -36.95629507047760,
      lng: -7301282100257410
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Villa Los Presidentes",
      addres: "Arturo Alessandri / Eduardo Frei",
      image: PlaceholderImage,
      lat: -36.9515619185656,
      lng: -7301365257194390
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Plaza del Sol1",
      addres: "Esperanza, esq Calle A",
      image: PlaceholderImage,
      lat: -36.94472419616570,
      lng: -7301304487616110
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Coquimbo",
      addres: "Coquimbo, esq Avd 105",
      image: PlaceholderImage,
      lat: -36.94284842302260,
      lng: -7301380922033680
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Manquimavida 3",
      addres: "Manquimavida - Avda, 105",
      image: PlaceholderImage,
      lat: -36.94118497106630,
      lng: -7302011027484520
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Bio Bio",
      addres: "Bio Bio con Avda 105",
      image: PlaceholderImage,
      lat: -36.93765139675010,
      lng: -7302130006941430
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Punto Limpio Easy",
      addres: "Av Manuel Rodríguez 1045, Chiguayante, Bío Bío",
      image: PlaceholderImage,
      lat: -36.93050854236270,
      lng: -7302135898774780
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Pinares1, La Estrella",
      addres: "Pinares / Linea Férrea",
      image: PlaceholderImage,
      lat: -36.931593400646100,
      lng: -7302243578309360
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Pedro Medina",
      addres: "Manuel R esq P Medina",
      image: PlaceholderImage,
      lat: -36.925884314186,
      lng: -7302493835697080
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Barrio Comercial",
      addres: "O'Higgins, esq Angamos",
      image: PlaceholderImage,
      lat: -36.913618974920000,
      lng: -7302913647616320
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "El Pantano",
      addres: "El Pantano con E Ramirez",
      image: PlaceholderImage,
      lat: -36.9113347369791,
      lng: -7302116989150380
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Gimnasio",
      addres: "Pedro Aguirre Cerda, lado Gimnasio",
      image: PlaceholderImage,
      lat: -36.910347882607800,
      lng: -7302989127323880
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Plaza de Armas 1",
      addres: "Leinenweber, esq Plaza",
      image: PlaceholderImage,
      lat: -36.90731418901610,
      lng: -7302899920805760
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Poblacion Valdes",
      addres: "O´Higgins, Plazoleta Tomas Solis",
      image: PlaceholderImage,
      lat: -36.92503456861720,
      lng: -730214981575642
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Alhambra",
      addres: "Avda Alhambra, lado colegio Arabe",
      image: PlaceholderImage,
      lat: -36.9035772001263,
      lng: -730268548229216
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Colon",
      addres: "Colon, esq Calle 1",
      image: PlaceholderImage,
      lat: -36.90190689540120,
      lng: -7302485600499910
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Schaub",
      addres: "Wihelm Schaub, esq Los Olivillos",
      image: PlaceholderImage,
      lat: -36.89479321302880,
      lng: -7303069664548370
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Strip Villuco 1",
      addres: "Ignacio Verdugo esq Diego Urrutia",
      image: PlaceholderImage,
      lat: -36.921220710509500,
      lng: -7302495661731950
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Al to Lemu",
      addres: "Ed Alto Lemu, Avda Lonco Parque",
      image: PlaceholderImage,
      lat: -36.86555088499360,
      lng: -7303908625608000
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Lonco Parque",
      addres: "Cinco Poniente S/N, poste N°15",
      image: PlaceholderImage,
      lat: -36.868883489037300,
      lng: -7303640412034180
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Binimellis",
      addres: "Binimellis, esg Eyza guirre",
      image: PlaceholderImage,
      lat: -36.86444952452360,
      lng: -730416335002076
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Lonco Norte",
      addres: "Sotomayor, esg Feo Encina",
      image: PlaceholderImage,
      lat: -36.86503326160510,
      lng: -7304198052203630
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Punto Limpio 8 Oriente",
      addres: "Strip Center 8 Oriente",
      image: PlaceholderImage,
      lat: -36.904189290380900,
      lng: -7303159898511370
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "La Ribera",
      addres: "Central, esq Calle 2",
      image: PlaceholderImage,
      lat: -36.90714452106380,
      lng: -7303287332050870
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Los Boldos",
      addres: "Bilbao lado multi cancha",
      image: PlaceholderImage,
      lat: -36.91107704277830,
      lng: -730325298502671
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Barrio Civico",
      addres: "1 Sur con Manuel Rodríguez",
      image: PlaceholderImage,
      lat: -36.91575301696330,
      lng: -7303001453985890
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Plaza los Bomberos",
      addres: "O'Higginsesq Cochrane",
      image: PlaceholderImage,
      lat: -36.92086347953540,
      lng: -7302476797375220
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Las Vertientes",
      addres: "San Martin, esq Central",
      image: PlaceholderImage,
      lat: -36.92553504176680,
      lng: -730204488608221
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Valle La Piedra 2",
      addres: "San Martin, esq El Castaño",
      image: PlaceholderImage,
      lat: -36.93196510703290,
      lng: -7301416243383220
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Villa Producción y el Comercio",
      addres: "Calle C s/n",
      image: PlaceholderImage,
      lat: -36.93682948327020,
      lng: -7300982926851320
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Villa Santo Domingo",
      addres: "O´higgins, esq Calle 2",
      image: PlaceholderImage,
      lat: -36.94262652241960,
      lng: -7300987134732620
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Villa Pradera",
      addres: "Calle 1, esq Calle 2",
      image: PlaceholderImage,
      lat: -36.941347221902200,
      lng: -7302308374732630
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Villa Lagos de Chiles",
      addres: "Calle 7 Plaza Lagos de Chile",
      image: PlaceholderImage,
      lat: -36.9490805893,
      lng: -730096885162883
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Villa Valle del Sol",
      addres: "Pasaje 3 con Pasaje 2",
      image: PlaceholderImage,
      lat: -36.945459775655500,
      lng: -7301418170499620
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "CENTRO DE RECICLAJE",
      addres: "Herrera 100, sector Papen",
      image: PlaceholderImage,
      lat: -36.92147808720860,
      lng: -730207432622415
    },
    {
      comuna: "CHIGUAYANTE",
      region: "BIOBIO",
      name: "Casa de la Cultura",
      addres: "Orozimbo Barbosa 294",
      image: PlaceholderImage,
      lat: -36.91818873928970,
      lng: -7303433744732790
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL POLIDEPORTIVO",
      addres: "MARCONI CON GABRIELA MISTRAL",
      image: PlaceholderImage,
      lat: -37.47453372297650,
      lng: -723363039472899
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL EL LABRIEGO, VILLA LAS TRANQUERAS",
      addres: "CALLE EL LABRIEGO, LAS TRANQUERAS 5, VILLA LAS TRANQUERAS",
      image: PlaceholderImage,
      lat: -37.45979230871630,
      lng: -7238144709757840
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL BAQUEDANO",
      addres: "BAQUEDANO / AGUSTINOS (SEDE JJVV #33 VILLA GALILEA)",
      image: PlaceholderImage,
      lat: -37.47585942554380,
      lng: -7237508604175270
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL MARIA AUXILIADORA",
      addres: "MARIA AUXILIADORA / JOSÉ SANTOS OSSA (JARDINES DE LA REPÚBLICA)",
      image: PlaceholderImage,
      lat: -37.48192011791320,
      lng: -7234320257331200
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL NUEVA ESPERANZA",
      addres: "CALLE NUEVA ESPERANZA, BLOCK 1 AL 4, CONDOMINIOS MARIA AUXILIADORA Y HERMANOS CARRERA EN EL SECTOR VILLA FILADELFIA",
      image: PlaceholderImage,
      lat: -37.49723582628050,
      lng: -7232945718370090
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL ALTOS DEL RETIRO",
      addres: "SACRAMENTO /MISIONEROS (ALTOS DEL RETIRO, SECTOR GALILEA)",
      image: PlaceholderImage,
      lat: -37.47936274642210,
      lng: -7237663136263000
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL COSTANERA QUILQUE NORTE",
      addres: "COSTANERA QUILQUE NORTE / NAHUELBUTA (PARQUES NACIONALES)",
      image: PlaceholderImage,
      lat: -37.46697812141820,
      lng: -7236075460943010
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL NIEVAS VÁSQUEZ",
      addres: "AVDA NIEVES VÁSQUEZ / LUXEMBURGO (PARQUE SORVICENTA)",
      image: PlaceholderImage,
      lat: -37.44998710641160,
      lng: -7233297191909000
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL CESFAM NUEVO HORIZONTE",
      addres: "LAS TORCAZAS/AGUAS CALIENTES (CESFAM NUEVO HORIZONTE, VILLA LOS PROFESORES)",
      image: PlaceholderImage,
      lat: -37.46733736372240,
      lng: -7238329881291830
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL DELEGACIÓN SALTOS DEL LAJA",
      addres: "AVENIDA EL SALTO DELEGACIÓN MUNICIPAL SALTOS DEL LAJA",
      image: PlaceholderImage,
      lat: -37.491737914997200,
      lng: -7233871240491260
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL DELEGACIÓN SANTA FE",
      addres: "O´HIGGINS S/N, DELEGACIÓN MUNICIPAL SANTA FE",
      image: PlaceholderImage,
      lat: -37.46595892199370,
      lng: -7257969786689710
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL SAN CARLOS PUREN",
      addres: "AVENIDA BIOBÍO S/N RUTA 5 SUR, ENTRE CALLE LAUTARO Y CAUPOLICÁN, EXTERIOR DE JUNTA DE VECINOS",
      image: PlaceholderImage,
      lat: -37.47000814655250,
      lng: -7235389108201660
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL PORTAL MANSO DE VELASCO",
      addres: "COSTANERA PAILLIHUE / AVDA ORIENTE, PORTAL MANSO DE VELASCO",
      image: PlaceholderImage,
      lat: -37.482344248427200,
      lng: -7233412073740220
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL EL PERAL",
      addres: "SECTOR EL PERAL UBICADO EN LA PLAZA DEL PERAL",
      image: PlaceholderImage,
      lat: -37.48415305001650,
      lng: -7232849227797000
    },
    {
      comuna: "LOS ÁNGELES",
      region: "BIOBIO",
      name: "PL CHACAYAL SUR",
      addres: "CALLE ARTURO PRAT CON CALLE SANTA EMILIA",
      image: PlaceholderImage,
      lat: -37.44975766325600,
      lng: -7213996304899280
    },
    {
      comuna: "ARAUCO",
      region: "BIOBIO",
      name: "ESTADIO MUNICIPAL",
      addres: "Av Arturo Prat 183, 4360740 Arauco, Bío Bío",
      image: PlaceholderImage,
      lat: -37.24122211352620,
      lng: -7332117844234370
    },
    {
      comuna: "TALCAHUANO",
      region: "BIOBIO",
      name: "PLAZA DE ARMAS",
      addres: "SAN MARTÍN ESQUINA ANIBAL PINTO",
      image: PlaceholderImage,
      lat: -36.71425089297060,
      lng: -7311427882036200
    },
    {
      comuna: "TALCAHUANO",
      region: "BIOBIO",
      name: "COLEGIO LOS CÓNDORES",
      addres: "Las Hortensias 4850, 4290239 Talcahuano, Bío Bío",
      image: PlaceholderImage,
      lat: -36.755028408675800,
      lng: -730927226418019
    },
    {
      comuna: "TALCAHUANO",
      region: "BIOBIO",
      name: "PUNTO LIMPIO FUNDACIÓN DE LAS FAMILIAS",
      addres: "BAHÍA SAN VICENTE 1000, NUEVA LOS LOBOS",
      image: PlaceholderImage,
      lat: -36.71192996138190,
      lng: -7313323510558690
    },
    {
      comuna: "FLORIDA",
      region: "BIOBIO",
      name: "Sector Estadio",
      addres: "Javiera Carrera 554-552, Florida, Bío Bío",
      image: PlaceholderImage,
      lat: -36.82386225654970,
      lng: -7266784077027870
    },
    {
      comuna: "FLORIDA",
      region: "BIOBIO",
      name: "Sector Escuela F 634",
      addres: "C Carlos Condell 668, Florida, Bío Bío",
      image: PlaceholderImage,
      lat: -36.825835001816800,
      lng: -7266145739839270
    },
    {
      comuna: "FLORIDA",
      region: "BIOBIO",
      name: "Plaza El Esfuerzo",
      addres: "Plaza El Esfuerzo",
      image: PlaceholderImage,
      lat: -36.80523345893710,
      lng: -726322608252735
    },
    {
      comuna: "FLORIDA",
      region: "BIOBIO",
      name: "Plaza Villa Esperanza",
      addres: "Plaza Villa Esperanza",
      image: PlaceholderImage,
      lat: -36.82439787866930,
      lng: -7265112707801500
    },
    {
      comuna: "FLORIDA",
      region: "BIOBIO",
      name: "Punto Copiulemu",
      addres: "Punto Copiulemu",
      image: PlaceholderImage,
      lat: -36.89409500523820,
      lng: -7280589276182940
    },
    {
      comuna: "FLORIDA",
      region: "BIOBIO",
      name: "Plazoleta Sgto Aldea",
      addres: "Plazoleta Sgto Aldea",
      image: PlaceholderImage,
      lat: -36.82257791698600,
      lng: -7265208435710190
    },
    {
      comuna: "FLORIDA",
      region: "BIOBIO",
      name: "Plazoleta Villalobos",
      addres: "Plazoleta Villalobos",
      image: PlaceholderImage,
      lat: -36.825094407688600,
      lng: -72663681204129
    },
    {
      comuna: "FLORIDA",
      region: "BIOBIO",
      name: "Plazoleta Puchacay",
      addres: "Plazoleta Puchacay",
      image: PlaceholderImage,
      lat: -36.825094407688600,
      lng: -72663681204129
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Pez Pet caleta lenga",
      addres: "Pez Pet caleta lenga",
      image: PlaceholderImage,
      lat: -36.76667808663900,
      lng: -7317219884013600
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Junta de Vecinos Prados De La Floresta",
      addres: "Junta de Vecinos Prados De La Floresta",
      image: PlaceholderImage,
      lat: -36.80349158149900,
      lng: -7309788169540760
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Junta De Vecinos Osvaldo Muños Calle silesia",
      addres: "Junta De Vecinos Osvaldo Muños Calle silesia",
      image: PlaceholderImage,
      lat: -36.78701078986110,
      lng: -7311643863645910
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Frutillar con Mafil",
      addres: "Frutillar con Mafil",
      image: PlaceholderImage,
      lat: -36.79370886889960,
      lng: -7308974158395160
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Condominio Isabel La Católica",
      addres: "Condominio Isabel La Católica",
      image: PlaceholderImage,
      lat: -36.73320677049470,
      lng: -7311102453764810
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Alemparte con Los Zorzales",
      addres: "Alemparte con Los Zorzales",
      image: PlaceholderImage,
      lat: -36.795841010252600,
      lng: -730821349933574
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Escuela Alonkura",
      addres: "Escuela Alonkura",
      image: PlaceholderImage,
      lat: -36.79116053701750,
      lng: -7309282990500660
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Escuela Thomas Jefferson",
      addres: "Escuela Thomas Jefferson",
      image: PlaceholderImage,
      lat: -36.78446080896470,
      lng: -7309792679151240
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Escuela Blanca Estela",
      addres: "Escuela Blanca Estela",
      image: PlaceholderImage,
      lat: -36.789267043208300,
      lng: -7310390017248030
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Escuela Villa Acero",
      addres: "Escuela Villa Acero",
      image: PlaceholderImage,
      lat: -36.796990333492200,
      lng: -7309379250316060
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Escuela República del Perú",
      addres: "Escuela República del Perú",
      image: PlaceholderImage,
      lat: -36.785670999500600,
      lng: -731156720743263
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Escuela Cristóbal Colón",
      addres: "Escuela Cristóbal Colón",
      image: PlaceholderImage,
      lat: -36.8007711630682,
      lng: -7308360414733580
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Escuela Hellen Keller",
      addres: "Escuela Hellen Keller",
      image: PlaceholderImage,
      lat: -36.784459416310900,
      lng: -7309852230500720
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Liceo Pedro del Río Zañartu",
      addres: "Liceo Pedro del Río Zañartu",
      image: PlaceholderImage,
      lat: -36.79008897597180,
      lng: -7309450047547570
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Liceo Lucila Godoy Alcayaga",
      addres: "Liceo Lucila Godoy Alcayaga",
      image: PlaceholderImage,
      lat: -36.78145726339160,
      lng: -7310893361665630
    },
    {
      comuna: "HUALPÉN",
      region: "BIOBIO",
      name: "Liceo Simón Bolivar",
      addres: "Liceo Simón Bolivar",
      image: PlaceholderImage,
      lat: -36.794299524409900,
      lng: -7308970057063430
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 1 SANTA ROSA",
      addres: "JAULA 1 SANTA ROSA",
      image: PlaceholderImage,
      lat: -37.64422089858680,
      lng: -7363139105158960
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 2 CERRO LA CRUZ",
      addres: "JAULA 2 CERRO LA CRUZ",
      image: PlaceholderImage,
      lat: -37.61412507770240,
      lng: -7365097220918620
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 3 TEGUALDA CON LEPIÑANCO",
      addres: "JAULA 3 TEGUALDA CON LEPIÑANCO",
      image: PlaceholderImage,
      lat: -37.6087074752788,
      lng: -7364785857611580
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 4 BOCA LEBU",
      addres: "JAULA 4 BOCA LEBU",
      image: PlaceholderImage,
      lat: -37.59724350523310,
      lng: -7366322098150360
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 5 CALLE FRESIA POB ARAUCANÍA",
      addres: "JAULA 5 CALLE FRESIA POB ARAUCANÍA",
      image: PlaceholderImage,
      lat: -37.60877389112940,
      lng: -7365655488760260
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 6 LOS HEROES",
      addres: "JAULA 6 LOS HEROES",
      image: PlaceholderImage,
      lat: -37.607868968168500,
      lng: -7365256098961060
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 7 GIMNASIO LEBU NORTE",
      addres: "JAULA 7 GIMNASIO LEBU NORTE",
      image: PlaceholderImage,
      lat: -37.594663257804000,
      lng: -7364300125596700
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 8 CALLE ROBERTO IGLESIAS (3 jaulas en la misma calle)",
      addres: "JAULA 8 CALLE ROBERTO IGLESIAS (3 jaulas en la misma calle)",
      image: PlaceholderImage,
      lat: -37.59620834447740,
      lng: -736421621591972
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 9 CEDUC",
      addres: "JAULA 9 CEDUC",
      image: PlaceholderImage,
      lat: -37.611085381279200,
      lng: -7365924403989780
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "PUNTO DE RECICLAJE GIMNASIO LEBU NORTE",
      addres: "PUNTO DE RECICLAJE GIMNASIO LEBU NORTE",
      image: PlaceholderImage,
      lat: -37.59473126541480,
      lng: -7364299757057970
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "PUNTO DE RECICLAJE SANTA ROSA",
      addres: "PUNTO DE RECICLAJE SANTA ROSA",
      image: PlaceholderImage,
      lat: -37.64422089858680,
      lng: -7363139105158960
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "PUNTO DE RECICLAJE VILLA ARAUCANIA",
      addres: "PUNTO DE RECICLAJE VILLA ARAUCANIA",
      image: PlaceholderImage,
      lat: -37.60877389112940,
      lng: -7365655488760260
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "PUNTO DE RECICLAJE SEDE O'HIGGINS",
      addres: "PUNTO DE RECICLAJE SEDE O'HIGGINS",
      image: PlaceholderImage,
      lat: -37.60953680635720,
      lng: -7365280772743210
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "PUNTO DE RECICLAJE BULNES ESQUINA",
      addres: "PUNTO DE RECICLAJE BULNES ESQUINA",
      image: PlaceholderImage,
      lat: -37.606071839252700,
      lng: -7365730389414060
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 10 PLAZA LOS VIENTOS",
      addres: "JAULA 10 PLAZA LOS VIENTOS",
      image: PlaceholderImage,
      lat: -37.609420045063400,
      lng: -7365954440764440
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 11 PLAYA BOCALEBU",
      addres: "JAULA 11 PLAYA BOCALEBU",
      image: PlaceholderImage,
      lat: -37.59625392482750,
      lng: -7366483128855700
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 12 PLAZA ANGAMOS POBL ESMERALDA",
      addres: "JAULA 12 PLAZA ANGAMOS POBL ESMERALDA",
      image: PlaceholderImage,
      lat: -37.602127522828,
      lng: -7365848821256760
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 13 PUERTO PESQUERO",
      addres: "JAULA 13 PUERTO PESQUERO",
      image: PlaceholderImage,
      lat: -37.60097217178760,
      lng: -73654838190186
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 14 CALLE MANUEL RODRIGUEZ",
      addres: "JAULA 14 CALLE MANUEL RODRIGUEZ",
      image: PlaceholderImage,
      lat: -37.603170472291600,
      lng: -7365913622824930
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 15 POBLACIÓN EBENNEZER",
      addres: "JAULA 15 POBLACIÓN EBENNEZER",
      image: PlaceholderImage,
      lat: -37.60583858080480,
      lng: -736409583068039
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 16 CALLE LUIS URIBE ESQ LAUTARO",
      addres: "JAULA 16 CALLE LUIS URIBE ESQ LAUTARO",
      image: PlaceholderImage,
      lat: -37.61430447847550,
      lng: -7364230174912600
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 17 PLAYA QUIAPO",
      addres: "JAULA 17 PLAYA QUIAPO",
      image: PlaceholderImage,
      lat: -37.44729357673490,
      lng: -7359385183195140
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 18 MINA COSTA",
      addres: "JAULA 18 MINA COSTA",
      image: PlaceholderImage,
      lat: -37.64226493040630,
      lng: -7366483718776250
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 19 SEDE TRANCALCO",
      addres: "JAULA 19 SEDE TRANCALCO",
      image: PlaceholderImage,
      lat: -37.645930331870900,
      lng: -7359710296034630
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 20 CURICO LAS VEGAS",
      addres: "JAULA 20 CURICO LAS VEGAS",
      image: PlaceholderImage,
      lat: -37.60947344200650,
      lng: -7365285159171270
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 21 SECTOR YENECO",
      addres: "JAULA 21 SECTOR YENECO",
      image: PlaceholderImage,
      lat: -37.608091845869600,
      lng: -7365118580525660
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 22 SECTOR PEHUEN ORILLA RUTA 160",
      addres: "JAULA 22 SECTOR PEHUEN ORILLA RUTA 160",
      image: PlaceholderImage,
      lat: -37.66913156200670,
      lng: -7354108841531710
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 23 SECTOR RUCA RAQUI",
      addres: "JAULA 23 SECTOR RUCA RAQUI",
      image: PlaceholderImage,
      lat: -37.74193972033630,
      lng: -735244307975647
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 24 SECTOR PLAYA LARGA PUNTILLA",
      addres: "JAULA 24 SECTOR PLAYA LARGA PUNTILLA",
      image: PlaceholderImage,
      lat: -37.62521733294510,
      lng: -7367821070839380
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 25 SECTOR ISLA MOCHA DELEGACIÓN",
      addres: "JAULA 25 SECTOR ISLA MOCHA DELEGACIÓN",
      image: PlaceholderImage,
      lat: -38.35938560278090,
      lng: -7391943455329880
    },
    {
      comuna: "LEBU",
      region: "BIOBIO",
      name: "JAULA 26 DELEGACIÓN LEBU NORTE",
      addres: "JAULA 26 DELEGACIÓN LEBU NORTE",
      image: PlaceholderImage,
      lat: -37.59561984706360,
      lng: -736402607609235
    },
    {
      comuna: "CORONEL",
      region: "BIOBIO",
      name: "POBLACIÓN POCURÓ ESCUADRÓN",
      addres: "AVDA FEDERICO SCHWAGER S/N",
      image: PlaceholderImage,
      lat: -36.967453513831700,
      lng: -7316502033786460
    },
    {
      comuna: "CORONEL",
      region: "BIOBIO",
      name: "POBLACIÓN LAGUNILLAS NORTE",
      addres: "CALLE BERNARDO HURAULT S/N",
      image: PlaceholderImage,
      lat: -36.9762563143998,
      lng: -7315634267431330
    },
    {
      comuna: "CORONEL",
      region: "BIOBIO",
      name: "ESQUINA MONTT CON CHIFLONES",
      addres: "ESQUINA AVDA MANUEL MONTT CON CALLE LOS CHIFLONES",
      image: PlaceholderImage,
      lat: -36.99963591906760,
      lng: -7316186661664160
    },
    {
      comuna: "SAN PEDRO DE LA PAZ",
      region: "BIOBIO",
      name: "San Pedro de La Paz",
      addres: "",
      image: PlaceholderImage,
      lat: -36.83231750773830,
      lng: -7311680618545420
    },
    {
      comuna: "NACIMIENTO",
      region: "BIOBIO",
      name: "Nacimiento",
      addres: "",
      image: PlaceholderImage,
      lat: -37.50616164557500,
      lng: -7267538499984040
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 1",
      addres: "",
      image: PlaceholderImage,
      lat: -36.672531015461000,
      lng: -7296082211401200
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 2",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.64323978952680,
      lng: -7294939540591470
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 3",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.641945887672200,
      lng: -7294876502720890
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 4",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.636873507029800,
      lng: -7295145405354230
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 5",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.635003161434500,
      lng: -7295734922985620
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 6",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.6339076507623,
      lng: -729568006011538
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 7",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.620027275881700,
      lng: -7295796550814370
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 8",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.61752604074510,
      lng: -7295785851286590
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 9",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.61725978295090,
      lng: -7296320445353470
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 10",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.60886534328150,
      lng: -7294774039706140
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 11",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.6029113929564,
      lng: -7295930373570020
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 12",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.60370240716700,
      lng: -7295831836377260
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 13",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.62944774235670,
      lng: -7279331134734750
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 14",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.56359488858260,
      lng: -7294520329879760
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 15",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.55257639456030,
      lng: -7295720039886230
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 16",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.550074843946300,
      lng: -7293322158287470
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 17",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.5469395092812,
      lng: -729372828088043
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 18",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.548874705675300,
      lng: -7292941748927270
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 19",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.54001139507780,
      lng: -7292857260548980
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 20",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.539503588504700,
      lng: -7293309443313260
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 21",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.5341691709266,
      lng: -729235277478512
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 22",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.5297045952312,
      lng: -729338903335025
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 23",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.527488076220100,
      lng: -7296266964564140
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 24",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.55551199637710,
      lng: -7286521329887130
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 25",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.60569839894970,
      lng: -7296529968598740
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 26",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.61207937389490,
      lng: -7296484936881980
    },
    {
      comuna: "TOMÉ",
      region: "BIOBIO",
      name: "Punto Tome 27",
      addres: "Tomé, Bio Bio",
      image: PlaceholderImage,
      lat: -36.60920774857110,
      lng: -7294754158204250
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Municipio Mulchen 1",
      addres: "Anibal Pinto 495, Mulchén",
      image: PlaceholderImage,
      lat: -37.72077925888890,
      lng: -722442587052416
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Municipio Mulchen 2",
      addres: "Balmaceda, 4531093 Mulchen, Negrete, Bío Bío",
      image: PlaceholderImage,
      lat: -37.71262177356070,
      lng: -7223676886579990
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Municipio Mulchen 3",
      addres: "Parque Las Peñas, Mulchen",
      image: PlaceholderImage,
      lat: -37.71184147051260,
      lng: -72246002022125
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Municipio Mulchen 4",
      addres: "Parque Quinta Venecia",
      image: PlaceholderImage,
      lat: -37.71393602264470,
      lng: -7224925006320510
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Municipio Mulchen 5",
      addres: "Salvo 795, Mulchen",
      image: PlaceholderImage,
      lat: -37.71736555284250,
      lng: -7223641266894670
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Municipio Mulchen 6",
      addres: "Anibal Pinto & Av Matta",
      image: PlaceholderImage,
      lat: -37.71920353900920,
      lng: -7224960564333000
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Municipio Mulchen 7",
      addres: "Barros Arana 320",
      image: PlaceholderImage,
      lat: -37.723679536628000,
      lng: -7225371399748670
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Municipio Mulchen 8",
      addres: "Mulchén - Alto Caledonia - Estero Pehuenco",
      image: PlaceholderImage,
      lat: -37.7282311580795,
      lng: -7223217879759280
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Municipio Mulchen 9",
      addres: "Jose Miguel Carrera & Condell",
      image: PlaceholderImage,
      lat: -37.7266458861367,
      lng: -7223829960546540
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Municipio Mulchen 10",
      addres: "Cancha Sintetica Los Lleuques, Mulchén",
      image: PlaceholderImage,
      lat: -37.73261299867090,
      lng: -7223826233198360
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Punto Mulchen 11",
      addres: "Mulchen, Bio Bio",
      image: PlaceholderImage,
      lat: -37.72206966401270,
      lng: -7223720778313140
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Punto Mulchen 12",
      addres: "Mulchen, Bio Bio",
      image: PlaceholderImage,
      lat: -37.718920873445800,
      lng: -722340435989869
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Punto Mulchen 13",
      addres: "Mulchen, Bio Bio",
      image: PlaceholderImage,
      lat: -37.73004580138210,
      lng: -7223546341795910
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Punto Mulchen 14",
      addres: "Mulchen, Bio Bio",
      image: PlaceholderImage,
      lat: -37.72257143516860,
      lng: -7224082330554960
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Punto Mulchen 15",
      addres: "Mulchen, Bio Bio",
      image: PlaceholderImage,
      lat: -37.71077973451910,
      lng: -7223867083208760
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Punto Mulchen 16",
      addres: "Mulchen, Bio Bio",
      image: PlaceholderImage,
      lat: -37.729200447740900,
      lng: -7224940540093520
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Punto Mulchen 17",
      addres: "Mulchen, Bio Bio",
      image: PlaceholderImage,
      lat: -37.71896878963010,
      lng: -722333940673825
    },
    {
      comuna: "MULCHÉN",
      region: "BIOBIO",
      name: "Punto Mulchen 18",
      addres: "Mulchen, Bio Bio",
      image: PlaceholderImage,
      lat: -37.71565395674320,
      lng: -7223925933145630
    },
    {
      comuna: "CONTULMO",
      region: "BIOBIO",
      name: "PUNTO PENDIENTE",
      addres: "Nahuelbuta 109, Contulmo, Bío Bío",
      image: PlaceholderImage,
      lat: -38.01536018235570,
      lng: -732298193066149
    },
    {
      comuna: "CURANILAHUE",
      region: "BIOBIO",
      name: "PUNTO PENDIENTE",
      addres: "",
      image: PlaceholderImage,
      lat: -37.72275667343710,
      lng: -7225559478619480
    },
    {
      comuna: "TEMUCO",
      region: "LA ARAUCANIA",
      name: "PL SODIMAC TEMUCO",
      addres: "Caupolicán 0457",
      image: PlaceholderImage,
      lat: -38.74374524802380,
      lng: -726095760611391
    },
    {
      comuna: "TEMUCO",
      region: "LA ARAUCANIA",
      name: "PL SODIMAC TEMUCO II",
      addres: "Avenida Las Encinas 02457",
      image: PlaceholderImage,
      lat: -38.74178118332180,
      lng: -7264013222970760
    },
    {
      comuna: "TEMUCO",
      region: "LA ARAUCANIA",
      name: "Instituto Teletón Temuco",
      addres: "General Aldunate 0390",
      image: PlaceholderImage,
      lat: -38.72986802506410,
      lng: -7258601176136780
    },
    {
      comuna: "VILLARICA",
      region: "LA ARAUCANIA",
      name: "PL RESIMPLE VILLARRICA ACUENTA",
      addres: "Aviador Acevedo 1258, 4931032 Villarrica, Araucanía, Chile",
      image: PlaceholderImage,
      lat: -39.291107561204200,
      lng: -722298965346559
    },
    {
      comuna: "Pitrufquén",
      region: "LA ARAUCANIA",
      name: "Pitrufquen",
      addres: "Francisco Bilbao #593",
      image: PlaceholderImage,
      lat: -38.98713406504710,
      lng: -7263754678740360
    },
    {
      comuna: "Cunco",
      region: "LA ARAUCANIA",
      name: "Punto Limpio Comuna de Cunco",
      addres: "Calle Alessandri N°398, Cunco",
      image: PlaceholderImage,
      lat: -38.92978887496440,
      lng: -7203125226271680
    },
    {
      comuna: "Cunco",
      region: "LA ARAUCANIA",
      name: "Delegación Municipal de Los Laureles",
      addres: "Calle Marcelino Huichamil S/N (Esquina Calle Artemio Gutiérrez)",
      image: PlaceholderImage,
      lat: -38.98705506484300,
      lng: -7224904939661750
    },
    {
      comuna: "FREIRE",
      region: "LA ARAUCANIA",
      name: "CASA DE INCLUSION FREIRE",
      addres: "SCHLEYER 344, FREIRE",
      image: PlaceholderImage,
      lat: -38.95293709420280,
      lng: -7262525023184650
    },
    {
      comuna: "Gorbea",
      region: "LA ARAUCANIA",
      name: "GIMNASIO MUNICIPAL",
      addres: "Andres Bello 485",
      image: PlaceholderImage,
      lat: -39.10216403611010,
      lng: -726754473183413
    },
    {
      comuna: "Melipeuco",
      region: "LA ARAUCANIA",
      name: "Centro de acopio transitorio de residuos",
      addres: "Aurelio Letelier Nº 80",
      image: PlaceholderImage,
      lat: -38.8556412067656,
      lng: -716972501785463
    },
    {
      comuna: "Saavedra",
      region: "LA ARAUCANIA",
      name: "Punto Limpio Costanera",
      addres: "Chorrillos S/N esquina Esmeralda",
      image: PlaceholderImage,
      lat: -38.793444123636600,
      lng: -7339550342112270
    },
    {
      comuna: "Saavedra",
      region: "LA ARAUCANIA",
      name: "Punto Verde Terminal Buses Playa Maule",
      addres: "Mirarar S/N",
      image: PlaceholderImage,
      lat: -38.80948547306680,
      lng: -7339841293551460
    },
    {
      comuna: "Saavedra",
      region: "LA ARAUCANIA",
      name: "Punto Verde Camping Bomberos Playa Maule",
      addres: "Mirarar S/N",
      image: PlaceholderImage,
      lat: -38.813374479604000,
      lng: -7340097225874930
    },
    {
      comuna: "Saavedra",
      region: "LA ARAUCANIA",
      name: "Punto Verde Banco Estado",
      addres: "Avenida Ejercito 1434",
      image: PlaceholderImage,
      lat: -38.792605008372900,
      lng: -7339597927576840
    },
    {
      comuna: "Saavedra",
      region: "LA ARAUCANIA",
      name: "Punto Verde Dominguez Plaza",
      addres: "Alessandri S/N",
      image: PlaceholderImage,
      lat: -38.89839932315180,
      lng: -7325639415981970
    },
    {
      comuna: "Saavedra",
      region: "LA ARAUCANIA",
      name: "Punto Verde Dominguez La mision",
      addres: "La Misión S/N esquina Alessandri",
      image: PlaceholderImage,
      lat: -38.89618565909190,
      lng: -7325536453980120
    },
    {
      comuna: "Saavedra",
      region: "LA ARAUCANIA",
      name: "Punto Verde Dominguez Suarez",
      addres: "RUTA S-46 S/N",
      image: PlaceholderImage,
      lat: -38.89912554745310,
      lng: -7325518678539770
    },
    {
      comuna: "Saavedra",
      region: "LA ARAUCANIA",
      name: "Punto Verde Dominguez PDTI",
      addres: "Zambrano S/N",
      image: PlaceholderImage,
      lat: -38.89766084804400,
      lng: -7325590539217110
    },
    {
      comuna: "Angol",
      region: "LA ARAUCANIA",
      name: "Punto Verde Banco de Chile",
      addres: "Lautaro 02, Angol",
      image: PlaceholderImage,
      lat: -37.79791691598300,
      lng: -7270577145482100
    },
    {
      comuna: "Vilcún",
      region: "LA ARAUCANIA",
      name: "Oficina Medio Ambiente",
      addres: "Camilo Henriquez 125",
      image: PlaceholderImage,
      lat: -38.671718148185700,
      lng: -7222567650487690
    },
    {
      comuna: "Renaico",
      region: "LA ARAUCANIA",
      name: "Punto Verde Teleton Renaico",
      addres: "Caupolicán con Comercio S/N",
      image: PlaceholderImage,
      lat: -38.983642631270600,
      lng: -7264404518067680
    },
    {
      comuna: "VILCUN",
      region: "LA ARAUCANIA",
      name: "PL VILCUN",
      addres: "S-31, 394 Vilcún",
      image: PlaceholderImage,
      lat: -38.67028877857900,
      lng: -7222441322579280
    },
    {
      comuna: "VALDIVIA",
      region: "LOS RIOS",
      name: "Instituto Teletón Valdivia",
      addres: "Av René Schneider 2631, Valdivia",
      image: PlaceholderImage,
      lat: -39.83633626355900,
      lng: -7321992273068390
    },
    {
      comuna: "Lanco",
      region: "LOS RIOS",
      name: "PL RESIMPLE Lanco",
      addres: "Unión #406",
      image: PlaceholderImage,
      lat: -39.45046209406820,
      lng: -7277151009116780
    },
    {
      comuna: "Lanco",
      region: "LOS RIOS",
      name: "Punto Verde (Lanco, Línea Ferrea)",
      addres: "Yungay",
      image: PlaceholderImage,
      lat: -39.44936794236920,
      lng: -7277628376299870
    },
    {
      comuna: "Lanco",
      region: "LOS RIOS",
      name: "Punto Verde (Lanco, Nueva Norte)",
      addres: "Intersección Nueva Norte y Santiago",
      image: PlaceholderImage,
      lat: -39.45214960294530,
      lng: -727672358336568
    },
    {
      comuna: "Lanco",
      region: "LOS RIOS",
      name: "Punto Verde (Lanco, Centro de Reciclaje)",
      addres: "Unión #406",
      image: PlaceholderImage,
      lat: -39.45045141801190,
      lng: -7277148187598660
    },
    {
      comuna: "Lanco",
      region: "LOS RIOS",
      name: "Punto Verde (Aylin)",
      addres: "Aylin",
      image: PlaceholderImage,
      lat: -39.51952546737260,
      lng: -7258803674777940
    },
    {
      comuna: "Lanco",
      region: "LOS RIOS",
      name: "Punto Verde (Villorio Quilche)",
      addres: "Villorio Quilche",
      image: PlaceholderImage,
      lat: -39.526663043415300,
      lng: -7253161058947590
    },
    {
      comuna: "Lanco",
      region: "LOS RIOS",
      name: "Punto Verde (Malalhue)",
      addres: "Los Carreras, frente al Estadio Malalhue",
      image: PlaceholderImage,
      lat: -39.54194160489520,
      lng: -7250255556959190
    },
    {
      comuna: "Lanco",
      region: "LOS RIOS",
      name: "Punto Verde (Malalhue)",
      addres: "Balmaceda, entre Gimnasio Municipal Malalhue y Sala Cuna Caminito de Luna",
      image: PlaceholderImage,
      lat: -39.54351677412440,
      lng: -7250398979001300
    },
    {
      comuna: "Lanco",
      region: "LOS RIOS",
      name: "Punto Verde (Malalhue)",
      addres: "Intersección Manuel Rodríguez e Isabel Riquelme",
      image: PlaceholderImage,
      lat: -39.54298972456620,
      lng: -7251070799132040
    },
    {
      comuna: "Lanco",
      region: "LOS RIOS",
      name: "Punto Verde (Malalhue)",
      addres: "Los Maitenes",
      image: PlaceholderImage,
      lat: -39.537276674827500,
      lng: -7250827862015590
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Baquedano con independencia",
      addres: "Baquedano con independencia",
      image: PlaceholderImage,
      lat: -40.06630339606160,
      lng: -728779284317669
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "18 de octubre Caupolicán y Rodríguez",
      addres: "18 de octubre Caupolicán y Rodríguez",
      image: PlaceholderImage,
      lat: -40.069341419120000,
      lng: -7287727079352540
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Municipalidad Paillaco",
      addres: "Municipalidad",
      image: PlaceholderImage,
      lat: -40.069854296119800,
      lng: -7287293488546010
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Plaza Manuel Bulnes",
      addres: "Plaza Manuel Bulnes",
      image: PlaceholderImage,
      lat: -40.06387446753890,
      lng: -7286865804229110
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Cesfam",
      addres: "Cesfam",
      image: PlaceholderImage,
      lat: -40.06757030775020,
      lng: -7286652766302250
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Mac iver",
      addres: "Mac iver",
      image: PlaceholderImage,
      lat: -40.069927168117100,
      lng: -7286951750293150
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Copec",
      addres: "Copec",
      image: PlaceholderImage,
      lat: -40.07424708160500,
      lng: -728716791886139
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Cochrane con Mac Iver",
      addres: "Cochrane con Mac Iver",
      image: PlaceholderImage,
      lat: -40.074105696128,
      lng: -728694335185892
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Mac iver (Cochrane con Pérez Rosales)",
      addres: "Mac iver (Cochrane con Pérez Rosales)",
      image: PlaceholderImage,
      lat: -40.07495021907540,
      lng: -7286942826509170
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Entrada Vicuña Mackenna",
      addres: "Entrada Vicuña Mackenna",
      image: PlaceholderImage,
      lat: -40.06960014410110,
      lng: -7286140105719680
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Vicuña Mackenna altura Colegio Cardenal",
      addres: "Vicuña Mackenna altura Colegio Cardenal",
      image: PlaceholderImage,
      lat: -40.069457911829200,
      lng: -7286292137040500
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Vicuña Mackenna frente a gimnasio",
      addres: "Vicuña Mackenna frente a gimnasio",
      image: PlaceholderImage,
      lat: -40.06978889531990,
      lng: -7286838937701000
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Hospital",
      addres: "Hospital",
      image: PlaceholderImage,
      lat: -40.07170450019150,
      lng: -7286661386728690
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Pérez Rosales esquina Gabriela Mistral",
      addres: "Pérez Rosales esquina Gabriela Mistral",
      image: PlaceholderImage,
      lat: -40.07526428118840,
      lng: -7286694798943650
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Pérez Rosales altura 1050",
      addres: "Pérez Rosales altura 1050",
      image: PlaceholderImage,
      lat: -40.07630194711340,
      lng: -7286372690293100
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Pérez Rosales esquina Bulnes",
      addres: "Pérez Rosales esquina Bulnes",
      image: PlaceholderImage,
      lat: -40.07489172278260,
      lng: -7286828867778740
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Radio diferencia",
      addres: "Radio diferencia",
      image: PlaceholderImage,
      lat: -40.07443024418040,
      lng: -728729983262293
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Barros Arana con San Martín",
      addres: "Barros Arana con San Martín",
      image: PlaceholderImage,
      lat: -40.07615142354160,
      lng: -7287182999312770
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Jardín Infantil Raíces de mi Pueblo",
      addres: "Jardín Infantil Raíces de mi Pueblo",
      image: PlaceholderImage,
      lat: -40.0678072500363,
      lng: -7286649517467380
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Colon altura 680",
      addres: "Colon altura 680",
      image: PlaceholderImage,
      lat: -40.076937440203700,
      lng: -7286825563303750
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Malaquías concha",
      addres: "Malaquías concha",
      image: PlaceholderImage,
      lat: -39.991561083448200,
      lng: -728333951035457
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "San Martín",
      addres: "San Martín",
      image: PlaceholderImage,
      lat: -40.07602827955150,
      lng: -7287157250108540
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "18 de septiembre",
      addres: "18 de septiembre",
      image: PlaceholderImage,
      lat: -40.07310842170800,
      lng: -7287988230477700
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Cruce Pichirropulli",
      addres: "Cruce Pichirropulli",
      image: PlaceholderImage,
      lat: -40.148002983719500,
      lng: -7289339407409800
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "San Martín 663",
      addres: "San Martín 663",
      image: PlaceholderImage,
      lat: -40.075997191004400,
      lng: -7286969244576790
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Villa Bicentenario",
      addres: "Villa Bicentenario",
      image: PlaceholderImage,
      lat: -40.07438622893020,
      lng: -7286211251757570
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Callejón Quezada",
      addres: "Callejón Quezada",
      image: PlaceholderImage,
      lat: -40.075840700381400,
      lng: -7286325272011710
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Ingreso Paillaco Ruta 5 Sur",
      addres: "Ingreso Paillaco Ruta 5 Sur",
      image: PlaceholderImage,
      lat: -40.07644662474230,
      lng: -7286254823480830
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Posta Reumén",
      addres: "Posta Reumén",
      image: PlaceholderImage,
      lat: -39.99155117638090,
      lng: -7283440491636880
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Escuela Roberto Ojeda Torres Reumén",
      addres: "Escuela Roberto Ojeda Torres Reumén",
      image: PlaceholderImage,
      lat: -39.992932085785800,
      lng: -7283590695328260
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Sala Cuna Mis Pequeño Sueño Reumén",
      addres: "Sala Cuna Mis Pequeño Sueño Reumén",
      image: PlaceholderImage,
      lat: -39.99396774951760,
      lng: -7283640047969710
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Posta Pichirropulli",
      addres: "Posta Pichirropulli",
      image: PlaceholderImage,
      lat: -40.148669760029200,
      lng: -728993570095338
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Escuela Nueva Aurora Pichirropulli",
      addres: "Escuela Nueva Aurora Pichirropulli",
      image: PlaceholderImage,
      lat: -40.154674339819200,
      lng: -7290291902396060
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Sala Cuna Mis pequeños corazones Pichirropulli",
      addres: "Sala Cuna Mis pequeños corazones Pichirropulli",
      image: PlaceholderImage,
      lat: -40.15413536658540,
      lng: -7290048367466530
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Itropulli",
      addres: "Itropulli",
      image: PlaceholderImage,
      lat: -40.126095706242200,
      lng: -72770822196435
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Sala cuna itropulli",
      addres: "Sala cuna itropulli",
      image: PlaceholderImage,
      lat: -40.12610083303920,
      lng: -727736727319323
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Villa el progreso",
      addres: "Villa el progreso",
      image: PlaceholderImage,
      lat: -40.125851297230300,
      lng: -7277425887004130
    },
    {
      comuna: "Paillaco",
      region: "LOS RIOS",
      name: "Villa padre hurtado",
      addres: "Villa padre hurtado",
      image: PlaceholderImage,
      lat: -40.12679880611210,
      lng: -7277342738532120
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Los Rosales",
      addres: "Villa Los Rosales",
      image: PlaceholderImage,
      lat: -40.12843548102370,
      lng: -723852713497042
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Riñinahue",
      addres: "Centro Comunitario",
      image: PlaceholderImage,
      lat: -40.32038964972490,
      lng: -7220703232877590
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Calle Santiago",
      addres: "Frente Liceo Antonio Varas",
      image: PlaceholderImage,
      lat: -40.32189009980740,
      lng: -7248020586636410
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Pitriuco",
      addres: "Camino Principal",
      image: PlaceholderImage,
      lat: -40.28063869824930,
      lng: -7262244959553350
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Illahuapi",
      addres: "Camino Principal",
      image: PlaceholderImage,
      lat: -40.27832655160960,
      lng: -7226600385071170
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Ignao",
      addres: "Mercadito",
      image: PlaceholderImage,
      lat: -40.33039876334000,
      lng: -7255630509267730
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Costanera",
      addres: "Calle Viña del Mar",
      image: PlaceholderImage,
      lat: -40.3199438690992,
      lng: -724790918164081
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Calcurrupe Bajo",
      addres: "Camino Principal",
      image: PlaceholderImage,
      lat: -40.22145359881220,
      lng: -7222943911087820
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Arenal",
      addres: "Sede Junta de Vecinos",
      image: PlaceholderImage,
      lat: -40.264835907213500,
      lng: -7224814752879690
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Rupumieca Bajo",
      addres: "Camino Principal",
      image: PlaceholderImage,
      lat: -40.31548305666920,
      lng: -719913148349239
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Tringlo B",
      addres: "Camino Principal",
      image: PlaceholderImage,
      lat: -40.326015660061300,
      lng: -7249887925689180
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Quillin",
      addres: "Camino Principal",
      image: PlaceholderImage,
      lat: -40.28626415899560,
      lng: -7254437294814300
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Carpa Costanera",
      addres: "Esquina ancud",
      image: PlaceholderImage,
      lat: -40.319627591282700,
      lng: -7247490665931280
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Pocura",
      addres: "Camino Principal",
      image: PlaceholderImage,
      lat: -40.352401085742000,
      lng: -7209691432990050
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Alucema",
      addres: "Camino Principal",
      image: PlaceholderImage,
      lat: -40.28726277842190,
      lng: -725796961703894
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "ilihue",
      addres: "Camino Principal",
      image: PlaceholderImage,
      lat: -40.360893547817100,
      lng: -7237272794555190
    },
    {
      comuna: "Lago Ranco",
      region: "LOS RIOS",
      name: "Liceo Bicentenario Ignao",
      addres: "Liceo",
      image: PlaceholderImage,
      lat: -40.332593571445800,
      lng: -7255377506058270
    },
    {
      comuna: "Panguipulli",
      region: "LOS RIOS",
      name: "Estación de Transferencia de residuos sólidos domiciliarios (Salida Lanco Panguipulli)",
      addres: "Estación de transferencia de residuos sólidos domiciliarios salida Lanco Panguipulli S/N",
      image: PlaceholderImage,
      lat: -39.64388453595210,
      lng: -7232840807167300
    },
    {
      comuna: "VALDIVIA",
      region: "LOS RIOS",
      name: "PL PLAZA DE LA REPUBLICA",
      addres: "Plaza de la República, Valdivia",
      image: PlaceholderImage,
      lat: -39.81427554916310,
      lng: -7324595601779110
    },
    {
      comuna: "CHILOE",
      region: "LOS LAGOS",
      name: "PL SODIMAC CASTRO",
      addres: "Ruta 5 norte N° 2456, sector Tentén Castro",
      image: PlaceholderImage,
      lat: -42.45736990692660,
      lng: -737724293306442
    },
    {
      comuna: "PUERTO MONTT",
      region: "LOS LAGOS",
      name: "Instituto Teletón Puerto Montt",
      addres: "Egaña 650",
      image: PlaceholderImage,
      lat: -41.47012746055910,
      lng: -7292461585397410
    },
    {
      comuna: "Futaleufú",
      region: "LOS LAGOS",
      name: "Centro de Tratamiento Integral de RSD Futaleufú",
      addres: "Km 11 sector Lonconao",
      image: PlaceholderImage,
      lat: -43.18710917794150,
      lng: -7186324000157080
    },
    {
      comuna: "Frutillar",
      region: "LOS LAGOS",
      name: "Dirección Desarrollo Comunitario Frutillar",
      addres: "Recinto estacion son numero Dideco Frutillar",
      image: PlaceholderImage,
      lat: -41.12661316231670,
      lng: -7306014346719710
    },
    {
      comuna: "Puyehue",
      region: "LOS LAGOS",
      name: "Punto Verde Municipal",
      addres: "Intersección Calles Bernardo O´Higgins con Manuel Rodríguez, Entre Lagos",
      image: PlaceholderImage,
      lat: -40.68395272469770,
      lng: -7259887456384320
    },
    {
      comuna: "Puyehue",
      region: "LOS LAGOS",
      name: "Jaula de plásticos Plaza Pública 1",
      addres: "Intersección Pasaje Guillatún con Calle Trabun, Porvenir",
      image: PlaceholderImage,
      lat: -40.694613532537,
      lng: -7260413023563410
    },
    {
      comuna: "Puyehue",
      region: "LOS LAGOS",
      name: "Jaula de plásticos Plaza Pública 2",
      addres: "Intersección Calle Salto El Puma con Calle Lago Puyehue, Porvenir",
      image: PlaceholderImage,
      lat: -40.69469717248000,
      lng: -7260256528601870
    },
    {
      comuna: "Quemchi",
      region: "LOS LAGOS",
      name: "Centro de Reciclaje Municipal",
      addres: "Calle Mechuque s/n, Población Padre Hurtado",
      image: PlaceholderImage,
      lat: -42.140975950223700,
      lng: -734731372089587
    },
    {
      comuna: "Puerto Montt",
      region: "LOS LAGOS",
      name: "Punto Verde Municipal",
      addres: "Amunategui 018",
      image: PlaceholderImage,
      lat: -41.46733225868600,
      lng: -7294561598860330
    },
    {
      comuna: "Castro",
      region: "LOS LAGOS",
      name: "Edificio Municipal I Municipalidad de Castro",
      addres: "Blanco 273, Castro",
      image: PlaceholderImage,
      lat: -42.48281085248590,
      lng: -737629263727153
    },
    {
      comuna: "Castro",
      region: "LOS LAGOS",
      name: "Centro de valorización de residuos",
      addres: "Galvarino Riveros S/N, cerca de las canchas de los seniors, Castro",
      image: PlaceholderImage,
      lat: -42.46649441977990,
      lng: -7377467776382270
    },
    {
      comuna: "COYHAIQUE",
      region: "AYSEN",
      name: "Instituto Teletón Aysén",
      addres: "Calle Ejército 567, Coyhaique",
      image: PlaceholderImage,
      lat: -45.56474246940600,
      lng: -7207476863068390
    },
    {
      comuna: "Coyhaique",
      region: "AYSEN",
      name: "Plaza de Armas",
      addres: "Plaza de armas de Coyhaique s/n",
      image: PlaceholderImage,
      lat: -45.572919695243900,
      lng: -7207516727166500
    },
    {
      comuna: "Aysén",
      region: "AYSEN",
      name: "Edificio consistorial municipal",
      addres: "Calle esmeralda s/n",
      image: PlaceholderImage,
      lat: -45.406344576446000,
      lng: -7269851358637540
    },
    {
      comuna: "Rio Ibañez",
      region: "AYSEN",
      name: "Punto limpio municipal rio ibañez",
      addres: "Calle anibal contreras s/n",
      image: PlaceholderImage,
      lat: -46.293498302368900,
      lng: -719332189709505
    },
    {
      comuna: "Rio Ibañez",
      region: "AYSEN",
      name: "Punto limpio municipal cerro castillo",
      addres: "Carretera austral s/n salida sur",
      image: PlaceholderImage,
      lat: -46.2893371537095,
      lng: -7193898211733100
    },
    {
      comuna: "PTA ARENAS",
      region: "MAGALLANES",
      name: "PL PUNTA ARENAS",
      addres: "Av Dos Sur 230, Punta Arenas, Magallanes y la Antártica Chilena",
      image: PlaceholderImage,
      lat: -53.13292268921180,
      lng: -7087717218596450
    },
    {
      comuna: "LA GRANJA",
      region: "RM",
      name: "PL RESIMPLE LA GRANJA",
      addres: "Calle Vicuña Mackenna 0820 - E, La Granja",
      image: PlaceholderImage,
      lat: -33.54648583895970,
      lng: -706204580263316
    },
    {
      comuna: "MAIRA PINTO",
      region: "RM",
      name: "PL RESIMPLE MARIA PINTO",
      addres: "Calle Baracaldo a 2km camino Melipilla, Marío Pinto",
      image: PlaceholderImage,
      lat: -33.53026127320210,
      lng: -7118928702240540
    },
    {
      comuna: "RENCA",
      region: "RM",
      name: "PL RESIMPLE RENCA 2",
      addres: "Av Miraflores 8412, Renca",
      image: PlaceholderImage,
      lat: -33.39934488649830,
      lng: -7075834425668100
    },
    {
      comuna: "LOS LAGOS",
      region: "LOS LAGOS",
      name: "PL RESIMPLE LOS LAGOS",
      addres: "Serrano 424, Los Lagos",
      image: PlaceholderImage,
      lat: -39.85813405543210,
      lng: -7280869449368200
    },
  ]);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const parseCoordinates = (coordinates) => {
    if (typeof coordinates === "string") {
      const [lat, lng] = coordinates
        .replace("°", "")
        .replace("S", "")
        .replace("W", "")
        .split(/\D+/)
        .filter((part) => part !== "")
        .map(parseFloat);

      return { lat, lng };
    }

    return { lat: coordinates, lng: coordinates };
  };

  const handleLocationSelect = (location) => {
    const { lat, lng, coordinates } = location;

    let selectedCoordinates;

    if (coordinates) {
      selectedCoordinates = parseCoordinates(coordinates);
    } else if (lat !== null && lng !== null) {
      selectedCoordinates = { lat, lng };
    } else {
      console.error("Coordenadas no válidas.");
      return;
    }

    if (document.querySelector(".content-search-location.open")) {
      document
        .querySelector(".content-search-location.open")
        .classList.remove("open");
    }

    // Agrega la clase "active" al elemento de la lista que se ha hecho clic
    const listItems = document.querySelectorAll(".location-item");
    listItems.forEach((item) => {
      item.classList.remove("active");
    });

    const clickedListItem = document.getElementById(location.name);
    clickedListItem.classList.add("active");

    mapRef.current.map.panTo(selectedCoordinates);
    mapRef.current.map.setZoom(15);

    setSelectedLocation(selectedCoordinates);
  };

  const filteredData = locations.filter((item) => {
    const comuna = item.comuna ? item.comuna.toLowerCase() : "";
    const name = item.name ? item.name.toLowerCase() : "";
    const addres = item.addres ? item.addres.toLowerCase() : "";
    const region = item.region ? item.region.toLowerCase() : "";

    return (
      comuna.includes(comunaFilter.toLowerCase()) ||
      name.includes(comunaFilter.toLowerCase()) ||
      addres.includes(comunaFilter.toLowerCase()) ||
      region.includes(comunaFilter.toLowerCase())
    );
  });

  return (
    <div className="container-location">
      <div
        className="content-search-location"
        onClick={(e) => e.target.classList.add("open")}
      >
        <img className="chevronTop" src={Arrow} alt="" />
        <label>Buscar un punto limpio</label>
        <img className="chevronBot" src={Arrow} alt="" />
        <input
          type="text"
          placeholder="Buscar un punto limpio"
          value={comunaFilter}
          onChange={(e) => setComunaFilter(e.target.value)}
        />

        <ul>
          {filteredData.map((location, index) => (
            <li
              className="location-item"
              key={index}
              id={location.name}
              onClick={() => handleLocationSelect(location)}
            >
              <div className="wrapper-img">
                <img src={location.image} alt="" />
              </div>
              <div className="wrapper-text">
                <h4>{location.name}</h4>
                <h5>{location.addres}</h5>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <Map
        className="googleMap"
        google={google}
        zoom={13}
        initialCenter={{ lat: -33.45614673683549, lng: -70.69897578465805 }}
        ref={mapRef}
      >
        {selectedLocation && (
          <Marker
            position={{
              lat: selectedLocation.lat,
              lng: selectedLocation.lng,
            }}
            icon={{
              url: ImagePin, // Reemplaza con la URL de tu imagen
              scaledSize: new window.google.maps.Size(40, 50), // Tamaño de la imagen
            }}
            title={selectedLocation.name}
          />
        )}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCYIHTek0d-vXQCSLupAeR29TaZaC4o-tk",
})(MapContainer);
