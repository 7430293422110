import React, { useState } from "react";
import { SucessBrigadeForm } from "../Recycling/BrigadeForm/SucessBrigadeForm";
import { NavLink } from "react-router-dom";
import "./childrensdayform.scss";

export const ChildrensDayForm = () => {
  //funtion modal
  const [modalShow, setModalShow] = useState(false);

  const modalSucess = () => {
    setModalShow(!modalShow);
  };

  const closeModal = () => {
    setModalShow(false);
  };

  const [datos, setDatos] = useState({
    nameForm: "",
    emailForm: "",
    dateForm: "",
  });

  const inputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const enviarDatos = async (event) => {
    event.preventDefault();
    event.target.reset();

    fetch("https://hooks.zapier.com/hooks/catch/2792106/bt0g9z0/", {
      method: "POST",
      body: JSON.stringify(datos),
    })
      .then(async (response) => {
        modalSucess();
        console.log("exito");
      })
      .catch((err) => {
        console.log("Algo sucedió");
      });
  };

  return (
    <div className="container__childrensday">
      <NavLink activeClassName="is-active" to="/">
        <div
          className={`modalSucess${modalShow ? " active" : ""}`}
          onClick={closeModal}
        >
          <SucessBrigadeForm />
        </div>
      </NavLink>
      <div className="container__childrensday__comment">
        <img
          src="https://user-images.githubusercontent.com/62721491/183207270-52790a5e-ff78-480f-8df2-5e7fa5ed6895.png"
          alt=""
          className="img__challenge"
        />

        <div className="container__childrensday__comment__suscribete">
          <img
            src="https://user-images.githubusercontent.com/62721491/183207997-f9645040-b12b-415b-bc80-3211c347caca.png"
            alt=""
            className="img__suscribete"
          />
        </div>
      </div>
      <form className="form" onSubmit={enviarDatos} action="">
        <div className="group-input">
          <label htmlFor="">Nombre Completo:</label>
          <input
            type="text"
            name="nameForm"
            id=""
            onChange={inputChange}
            required
          />
        </div>
        <div className="group-input">
          <label htmlFor="">Fecha de nacimiento:</label>
          <input
            type="date"
            name="dateForm"
            id=""
            onChange={inputChange}
            required
          />
        </div>
        <div className="group-input">
          <label htmlFor="">Mail:</label>
          <input
            type="email"
            name="emailform"
            id=""
            onChange={inputChange}
            required
          />
        </div>
        <div className="group-checkbox">
          <input
            type="checkbox"
            name=""
            id=""
            onChange={inputChange}
            required
          />
          <label htmlFor="">
            <a
              href="https://sitio.bilzypap.cl/bases/bases.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Acepto los términos y condiciones
            </a>
          </label>
        </div>
        <div className="form__wrap__button">
          <button type="submit">Enviar</button>
        </div>
      </form>
      <NavLink to="/">
        <button className="return">
          <img
            src="https://user-images.githubusercontent.com/55818246/138624865-eb5ec3e3-586d-4a60-8364-b1747c38174c.png"
            alt=""
          />
        </button>
      </NavLink>
    </div>
  );
};
