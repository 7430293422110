import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

import logobyp from "../../assets/img/logobyp.png";
import "./header.scss";

export const Header = ({ headerFlag }) => {
  const [showHeader, setShowHeader] = useState(false);

  let toggleMenu = () => {
    setShowHeader(!showHeader);
  };

  const closeMenu = () => {
    setShowHeader(false);
  };

  let useClickOutSide = (handler) => {
    let domNode = useRef();

    useEffect(() => {
      let beHandler = (event) => {
        if (domNode.current && !domNode.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", beHandler);

      return () => {
        document.removeEventListener("mousedown", beHandler);
      };
    });
    return domNode;
  };

  let domNode = useClickOutSide(() => {
    setShowHeader(false);
  });

  return headerFlag ? (
    <header className={`header${showHeader ? " active" : ""}`}>
      <div ref={domNode} className="header__content">
        <button onClick={toggleMenu} type="button">
          <span></span>
          <span></span>
          <span></span>
        </button>

        <nav className={`header__navigation${showHeader ? " active" : ""}`}>
          <div className="menu" onClick={() => setShowHeader(true)}></div>
          <ul>
            <li onClick={toggleMenu}>
              <NavLink
                exact
                activeClassName="is-active"
                onClick={closeMenu}
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="is-active"
                onClick={closeMenu}
                to="/luna-de-juegos"
              >
                Luna de juegos
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="is-active"
                onClick={closeMenu}
                to="/academia-solar"
              >
                Academia Solar
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="is-active"
                onClick={closeMenu}
                to="/newsletter"
              >
                Suscríbete
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <div className="header__logo">
        <NavLink to="/">
          <img loading="lazy" src={logobyp} alt="logo bilz y pap" />
        </NavLink>
      </div>
    </header>
  ) : null;
};
