import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Context } from "../../../Hooks/Context";

import imgWorkshop from "../../../assets/img/workshop.png";
import imgCrafts from "../../../assets/img/img-crafts.png";
import imgTiktok from "../../../assets/img/img-tiktok.png";
import imgScience from "../../../assets/img/img-science.png";
import imgkitchen from "../../../assets/img/img-kitchen.png";
import "./courses.scss";
// import check from "../../../assets/img/check.webp";

export const Courses = () => {
  const { courseMode, activateCourseMode } = useContext(Context);

  const handleScience = () => {
    activateCourseMode("science");
  };

  const handleCrafts = () => {
    activateCourseMode("crafts");
  };

  const handleTiktok = () => {
    activateCourseMode("tiktok");
  };

  const handleKitchen = () => {
    activateCourseMode("kitchen");
  };

  return (
    <div className="academy__course">
      <div className="academy__course__container">
        <img src={imgWorkshop} alt="" className="img__academy" />
        <div className="academy__course__container__grid">
          <div className="course__group">
            <div className="course">
              <NavLink to="/taller-de-ciencias">
                <img
                  src={imgScience}
                  alt=""
                  className="course__img"
                  onClick={handleScience}
                  courseMode={courseMode}
                />
                {/* <img src={check} alt="" className="check" /> */}
              </NavLink>
              <p className="course__name">Taller de ciencias</p>
            </div>

            <div className="course">
              <NavLink to="/taller-de-manualidades">
                <img
                  src={imgCrafts}
                  alt=""
                  onClick={handleCrafts}
                  courseMode={courseMode}
                />
              </NavLink>
              <p className="course__name">Taller de manualidades</p>
            </div>
          </div>
          <div className="course__group">
            <div className="course">
              <NavLink to="/taller-de-baile-tiktok">
                <img
                  src={imgTiktok}
                  alt=""
                  courseMode={courseMode}
                  onClick={handleTiktok}
                />
              </NavLink>
              <p className="course__name">Taller de baile tiktok</p>
            </div>

            <div className="course">
              <NavLink to="/taller-de-cocina">
                <img
                  src={imgkitchen}
                  alt=""
                  courseMode={courseMode}
                  onClick={handleKitchen}
                />
              </NavLink>
              <p className="course__name">Taller de cocina</p>
            </div>
          </div>
        </div>
        <NavLink to="/academia-solar">
          <button className="return">
            <img
              src="https://user-images.githubusercontent.com/55818246/138624865-eb5ec3e3-586d-4a60-8364-b1747c38174c.png"
              alt=""
            />
          </button>
        </NavLink>
      </div>
    </div>
  );
};
