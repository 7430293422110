export const dogs = [
  {
    id: 1,
    name: "Sharpi",
    photo: require("../../assets/img/dogs/perritos/sharpi-min.png").default,
    audio: require("../../assets/audios-perritos/sharpi.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 2,
    name: "Oldy",
    photo: require("../../assets/img/dogs/perritos/oldy-min.png").default,
    audio: require("../../assets/audios-perritos/oldy.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 3,
    name: "Boss",
    photo: require("../../assets/img/dogs/perritos/boss-min.png").default,
    audio: require("../../assets/audios-perritos/boss.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 4,
    name: "Canosita",
    photo: require("../../assets/img/dogs/perritos/canosita-min.png").default,
    audio: require("../../assets/audios-perritos/canosita.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 5,
    name: "Husky",
    photo: require("../../assets/img/dogs/perritos/husky-min.png").default,
    audio: require("../../assets/audios-perritos/husky.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 6,
    name: "Estrellita",
    photo: require("../../assets/img/dogs/perritos/estrellita-min.png").default,
    audio: require("../../assets/audios-perritos/estrellita.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 7,
    name: "Arenita",
    photo: require("../../assets/img/dogs/perritos/arenita-min.png").default,
    audio: require("../../assets/audios-perritos/arenita.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 8,
    name: "Mía",
    photo: require("../../assets/img/dogs/perritos/mia-min.png").default,
    audio: require("../../assets/audios-perritos/mia.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 9,
    name: "Oliver",
    photo: require("../../assets/img/dogs/perritos/oliver-min.png").default,
    audio: require("../../assets/audios-perritos/oliver.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 10,
    name: "Kuyén",
    photo: require("../../assets/img/dogs/perritos/kuyen-min.png").default,
    audio: require("../../assets/audios-perritos/kuyen.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 11,
    name: "Polar",
    photo: require("../../assets/img/dogs/perritos/polar-min.png").default,
    audio: require("../../assets/audios-perritos/polar.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 12,
    name: "Grizzly",
    photo: require("../../assets/img/dogs/perritos/grizzly-min.png").default,
    audio: require("../../assets/audios-perritos/grizzly.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 13,
    name: "Coral",
    photo: require("../../assets/img/dogs/perritos/coral-min.png").default,
    audio: require("../../assets/audios-perritos/coral.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 14,
    name: "Balaguer",
    photo: require("../../assets/img/dogs/perritos/balager-min.png").default,
    audio: require("../../assets/audios-perritos/balaguer.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 15,
    name: "Aukan",
    photo: require("../../assets/img/dogs/perritos/aukan-min.png").default,
    audio: require("../../assets/audios-perritos/aukan.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 16,
    name: "Jazmín",
    photo: require("../../assets/img/dogs/perritos/jazmin-min.png").default,
    audio: require("../../assets/audios-perritos/jazmin.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 17,
    name: "Tiana",
    photo: require("../../assets/img/dogs/perritos/tiana-min.png").default,
    audio: require("../../assets/audios-perritos/tiana.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 18,
    name: "Mono",
    photo: require("../../assets/img/dogs/perritos/mono-min.png").default,
    audio: require("../../assets/audios-perritos/mono.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 19,
    name: "Demi",
    photo: require("../../assets/img/dogs/perritos/demi-min.png").default,
    audio: require("../../assets/audios-perritos/demi.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  {
    id: 20,
    name: "Tenna",
    photo: require("../../assets/img/dogs/perritos/tenna-min.png").default,
    audio: require("../../assets/audios-perritos/tenna.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
        .default,
  },
  // {
  //   id: 21,
  //   name: "Francis",
  //   photo: require("../../assets/img/dogs/perritos/francis-min.png").default,
  //   audio: require("../../assets/audios-perritos/francis.mp3").default,
  //   foundationImage:
  //     require("../../assets/img/dogs/fundaciones/fundacion-mirada-animal.png")
  //       .default,
  // },
  {
    id: 22,
    name: "Ares",
    photo: require("../../assets/img/dogs/perritos/ares-min.png").default,
    audio: require("../../assets/audios-perritos/ares.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-huellas-unidas.png")
        .default,
  },
  {
    id: 23,
    name: "Elliot",
    photo: require("../../assets/img/dogs/perritos/elliot-min.png").default,
    audio: require("../../assets/audios-perritos/elliot.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-huellas-unidas.png")
        .default,
  },
  {
    id: 24,
    name: "Mara",
    photo: require("../../assets/img/dogs/perritos/mara-min.png").default,
    audio: require("../../assets/audios-perritos/mara.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-huellas-unidas.png")
        .default,
  },
  {
    id: 25,
    name: "Botitas",
    photo: require("../../assets/img/dogs/perritos/botitas-min.png").default,
    audio: require("../../assets/audios-perritos/botitas.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-huellas-unidas.png")
        .default,
  },
  {
    id: 26,
    name: "Max",
    photo: require("../../assets/img/dogs/perritos/max-min.png").default,
    audio: require("../../assets/audios-perritos/max.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-huellas-unidas.png")
        .default,
  },
  {
    id: 27,
    name: "Tango",
    photo: require("../../assets/img/dogs/perritos/tango-min.png").default,
    audio: require("../../assets/audios-perritos/tango.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-huellas-unidas.png")
        .default,
  },
  {
    id: 28,
    name: "Dalí",
    photo: require("../../assets/img/dogs/perritos/dali-min.png").default,
    audio: require("../../assets/audios-perritos/dali.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-huellas-unidas.png")
        .default,
  },
  {
    id: 29,
    name: "Gus",
    photo: require("../../assets/img/dogs/perritos/gus-min.png").default,
    audio: require("../../assets/audios-perritos/gus.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-huellas-unidas.png")
        .default,
  },
  {
    id: 30,
    name: "Romeo",
    photo: require("../../assets/img/dogs/perritos/romeo-min.png").default,
    audio: require("../../assets/audios-perritos/romeo.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-huellas-unidas.png")
        .default,
  },
  {
    id: 31,
    name: "Yuki",
    photo: require("../../assets/img/dogs/perritos/yuki-min.png").default,
    audio: require("../../assets/audios-perritos/yuki.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-huellas-unidas.png")
        .default,
  },
  {
    id: 32,
    name: "Sabrina",
    photo: require("../../assets/img/dogs/perritos/sabrina-min.png").default,
    audio: require("../../assets/audios-perritos/sabrina.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-adopta.png").default,
  },
  {
    id: 33,
    name: "Violeta",
    photo: require("../../assets/img/dogs/perritos/violeta-min.png").default,
    audio: require("../../assets/audios-perritos/violeta.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-adopta.png").default,
  },
  {
    id: 34,
    name: "Plutón",
    photo: require("../../assets/img/dogs/perritos/pluton-min.png").default,
    audio: require("../../assets/audios-perritos/pluton.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-adopta.png").default,
  },
  {
    id: 35,
    name: "Panda",
    photo: require("../../assets/img/dogs/perritos/panda-min.png").default,
    audio: require("../../assets/audios-perritos/panda.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-adopta.png").default,
  },
  {
    id: 36,
    name: "Moi",
    photo: require("../../assets/img/dogs/perritos/moi-min.png").default,
    audio: require("../../assets/audios-perritos/moi.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-adopta.png").default,
  },
  {
    id: 37,
    name: "Mercurio",
    photo: require("../../assets/img/dogs/perritos/mercurio-min.png").default,
    audio: require("../../assets/audios-perritos/mercurio.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-adopta.png").default,
  },
  {
    id: 38,
    name: "Manjula",
    photo: require("../../assets/img/dogs/perritos/manjula-min.png").default,
    audio: require("../../assets/audios-perritos/manjula.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-adopta.png").default,
  },
  {
    id: 39,
    name: "Koi",
    photo: require("../../assets/img/dogs/perritos/koi-min.png").default,
    audio: require("../../assets/audios-perritos/koi.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-adopta.png").default,
  },
  {
    id: 40,
    name: "Hallie",
    photo: require("../../assets/img/dogs/perritos/hallie-min.png").default,
    audio: require("../../assets/audios-perritos/hallie.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-adopta.png").default,
  },
  {
    id: 41,
    name: "Estrella",
    photo: require("../../assets/img/dogs/perritos/estrella-min.png").default,
    audio: require("../../assets/audios-perritos/estrella.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-adopta.png").default,
  },
  {
    id: 42,
    name: "Cuca",
    photo: require("../../assets/img/dogs/perritos/cuca-min.png").default,
    audio: require("../../assets/audios-perritos/cuca.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-adopta.png").default,
  },
  {
    id: 43,
    name: "Bart",
    photo: require("../../assets/img/dogs/perritos/bart-min.png").default,
    audio: require("../../assets/audios-perritos/bart.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-adopta.png").default,
  },
  {
    id: 44,
    name: "Annie",
    photo: require("../../assets/img/dogs/perritos/annie-min.png").default,
    audio: require("../../assets/audios-perritos/annie.mp3").default,
    foundationImage:
      require("../../assets/img/dogs/fundaciones/fundacion-adopta.png").default,
  },
];
