import React from "react";
import { NavLink } from "react-router-dom";
import "./footer.scss";

import facebook from "../../assets/img/home/facebook-icon-min.png";
import instagram from "../../assets/img/home/instagram-icon-min.png";
import youtube from "../../assets/img/home/youtube-icon-min.png";
import tiktok from "../../assets/img/home/tiktok-icon-min.png";

import tyc from "../../assets/Sitio-Web-bilzypapcl-Bilz-y-Pap-2022.pdf";
import PyP from "../../assets/[Politica-de-Privacidad]-SitioWeb-bilzypap.cl-Bilz-y-Pap-2022.pdf"

export const Footer = ({ footerFlag }) => {
  return footerFlag ? (
    <footer className="footer">
      <div className="footer__bg">
        <div className="footer__rrss">
          <a
            href="https://www.instagram.com/bilzypapcl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="" />
          </a>
          <a
            href="https://www.tiktok.com/@bilzypapcl?"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={tiktok} alt="" />
          </a>
          <a
            href="https://www.facebook.com/BilzyPapcl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="" />
          </a>
          <a
            href="https://www.youtube.com/bilyymaik"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={youtube} alt="" />
          </a>
        </div>
      </div>
      <div className="footer__info">
        <div className="footer__info--top">
        <a href={PyP} target="_blank" rel="noopener noreferrer">
            Política de privacidad 
          </a>
          <a href={tyc} target="_blank" rel="noopener noreferrer">
            Terminos y condiciones
          </a>
          <NavLink to="bases-legales">
          <p>
            Bases Legales
          </p>
          </NavLink>
        </div>
        <div className="footer__info--bottom">
          <a href="tel:+56-2-2427-3000"> (56-2) 2427 3000</a>
          <div className="disorder__addres">
            <a
              href="https://goo.gl/maps/txyjsPqSNsFZfg1Q8"
              target="_blank"
              rel="noopener noreferrer"
              className="map"
            >
              Vitacura 2670, Las Condes, Región Metropolitana, Chile
            </a>
          </div>
        </div>
      </div>
    </footer>
  ) : null;
};
