import React from "react";
import "./bases.scss";

import base1 from "../../assets/Bases-legales-Arena-Teleton-2023.pdf";
import base2 from "../../assets/Bases-legales-Concurso-Maquina-Nabilzpap-2023.pdf";
import base3 from "../../assets/Bases-legales-Arena-Nabilzpap-Roblox-2023.pdf";
import base4 from "../../assets/Bases-legales-Cumpleanos-2024.pdf";


export const Bases = () => {
  return (
    <div className="layout-bases">
      <div className="container__bases">
        <div className="container__bases__sections">
          <h3>Bases y Condiciones</h3>
        </div>
        <div className="container__bases__sections">
          <a href={base1} target="_blank" rel="noopener noreferrer">
            Bases legales - Arena Teletón 2023
          </a>
          <br />
          <a href={base2} target="_blank" rel="noopener noreferrer">
            Bases legales - Concurso Maquina Nabilzpap 2023
          </a>
          <br />
          <a href={base3} target="_blank" rel="noopener noreferrer">
            Bases legales - Arena Nabilzpap Roblox 2023
          </a>
          <br />
          <a href={base4} target="_blank" rel="noopener noreferrer">
            Bases legales - Cumpleaños 2024
          </a>
        </div>
      </div>
    </div>
  );
};
