import React, { useState } from "react";
import { QuestionaryTiktok } from "../../../../SmartComponents/QuestionaryTiktok/QuestionaryTiktok";
import { Form } from "../../../../SmartComponents/Form/Form";
import { TiktokSlider } from "../../TiktokSlider/TiktokSlider";
import "./tiktok.scss";

export const Tiktok = (props) => {
  const [currentItem, setCurrentItem] = useState("workshop");

  return (
    <div className="tiktok">
      <div className="workshop__tiktok">
        <img
          src="https://user-images.githubusercontent.com/55818246/138815071-4af645d0-490c-4b72-94e3-f76b185b2918.png"
          alt=""
        />
      </div>
      {currentItem === "workshop" && (
        <TiktokSlider onFinished={setCurrentItem} />
      )}
      {currentItem === "questionary" && (
        <QuestionaryTiktok onFinished={setCurrentItem} />
      )}
      {currentItem === "form" && <Form onFinished={setCurrentItem} />}
    </div>
  );
};
