import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Card } from "../../../SmartComponents/Card/Card";
import "./workshop.scss";

export const Workshop = ({ onFinished }) => {
  const [nextButton, setNextButton] = useState(false);

  const nextPage = () => {
    onFinished("questionary");
    setNextButton(!nextButton);
  };

  return (
    <div className="workshop">
      <div className="workshop__swiper">
        <Card
          workshopVideo="https://www.youtube.com/embed/9zqAwvW7fqQ?rel=0"
          workshopTitleText="EXPERIMENTOS DIVERTIDOS"
          workshoptDescriptionText="¡En este taller aprenderás cómo hacer increíbles experimentos y
              lo mejor es que podrás hacerlos en tu casa!"
        />

        <button className="next" onClick={nextPage}>
          Ir al cuestionario
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44.25"
            height="49.25"
            viewBox="0 0 44.25 49.25"
          >
            <path
              id="Triángulo_699"
              data-name="Triángulo 699"
              className="cls-1"
              d="M757.939,1577.76a6,6,0,0,1,9-5.19l32.24,18.62a6,6,0,0,1,0,10.39l-32.233,18.61a6,6,0,0,1-9-5.2Z"
              transform="translate(-757.938 -1571.75)"
            />
          </svg>
        </button>
        <NavLink to="/cursos">
          <button className="return">
            <img
              src="https://user-images.githubusercontent.com/55818246/138624865-eb5ec3e3-586d-4a60-8364-b1747c38174c.png"
              alt=""
            />
          </button>
        </NavLink>
      </div>
    </div>
  );
};
