import React from "react";
import { NavLink } from "react-router-dom";
import thank from "../../assets/img/img-thank.png";
import "./sucessNewsletter.scss";

export const SucessNewsletter = () => {
  return (
    <div className="sucessNewsletter">
      <img src={thank} alt="" />
      <NavLink to="/">
        <button className="sucessNewsletter__return">volver</button>
      </NavLink>
    </div>
  );
};
