import React, { useState } from "react";
import { Workshop } from "../../Workshop/Workshop";
import { data } from "../../../../assets/const/data";
import { Questionary } from "../../../../SmartComponents/Questionary/Questionary";
import { Form } from "../../../../SmartComponents/Form/Form";
import "./Science.scss";

export const Science = () => {
  const title = data.courses[0].titleImg;

  const [currentItem, setCurrentItem] = useState("workshop");

  return (
    <div className="science">
      <div className="workshop__title">
        <img src={title} alt="" />
      </div>
      {currentItem === "workshop" && <Workshop onFinished={setCurrentItem} />}
      {currentItem === "questionary" && (
        <Questionary onFinished={setCurrentItem} />
      )}
      {currentItem === "form" && <Form onFinished={setCurrentItem} />}
    </div>
  );
};
