import React from "react";
import "./sucessBrigadeForm.scss";

export const SucessBrigadeForm = () => {
  return (
    <div className="sucessBrigadeForm">
      {/* <button onClick={closeModal}>X</button> */}
      <img
        src="https://user-images.githubusercontent.com/62721491/183220036-6d049a42-45cf-4c53-b515-a86001db6bb6.jpg"
        alt=""
      />
    </div>
  );
};
