import React from "react";
import "./moongames.scss";
import { NavLink } from "react-router-dom";
import { PlanetHome } from "../../SmartComponents/PlanetHome/PlanetHome";
import Luna from "../../assets/img/home/luna-de-juegos-min.png";

export const MoonGames = () => {
  return (
    <div className="container__academy">
      <div className="container__academy__hero">
        <NavLink to="/juegos">
          <PlanetHome
            planet={Luna}
            text="¡Disfruta en este increible lugar lleno de los juegos más divertidos del espacio y conviértete en un jugador de otro mundo!"
          />
        </NavLink>
      </div>
      <NavLink to="/">
        <button className="return">
          <img
            src="https://user-images.githubusercontent.com/55818246/138624865-eb5ec3e3-586d-4a60-8364-b1747c38174c.png"
            alt=""
          />
        </button>
      </NavLink>
    </div>
  );
};
