import React, { useEffect, useRef, useState } from "react";
import { dogs } from "../Dogs";
import "./DogDetails.scss";
import Slider from "./slider/Slider";
import ControlPanel from "./controls/ControlPanel";
import logobyp from "../../../assets/img/logobyp.png";
import sonido from "../../../assets/img/animales/sonido.png";

const DogDetails = ({ handle, hideHeader, match }) => {
  // Ocultar header - footer
  useEffect(() => {
    handle(false);
    return () => handle(true);
  }, [handle]);

  useEffect(() => {
    hideHeader(false);
    return () => hideHeader(true);
  }, [hideHeader]);

  //player
  const [percentage, setPercentage] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const audioRef = useRef();

  const onChange = (e) => {
    const audio = audioRef.current;
    const newCurrentTime = (audio.duration / 100) * e.target.value;

    if (Number.isFinite(newCurrentTime)) {
      audio.currentTime = newCurrentTime;
      setPercentage(e.target.value);
    }
  };

  const play = () => {
    const audio = audioRef.current;
    audio.volume = 0.1;

    if (!isPlaying) {
      setIsPlaying(true);
      audio.play();
    }

    if (isPlaying) {
      setIsPlaying(false);
      audio.pause();
    }
  };

  const getCurrDuration = (e) => {
    const percent = (
      (e.currentTarget.currentTime / e.currentTarget.duration) *
      100
    ).toFixed(2);
    const time = e.currentTarget.currentTime;

    setPercentage(+percent);
    setCurrentTime(time.toFixed(2));
  };

  const dog = dogs.find(
    (p) => p.name.toLowerCase() === match.params.name.toLowerCase()
  );

  if (!dog) {
    return <div>Perrito no encontrado</div>;
  }

  return (
    <div className="dog-details">
      <div>
        <img className="dog-details__image" src={dog.photo} alt={dog.name} />
        <h2 className="dog-details__title">
          Hola, <br /> Soy {dog.name}
        </h2>
        <hr className="dog-details__hr" />
        <p className="dog-details__sub">¡Escucha mi historia aquí!</p>
      </div>

      <div className="dog-details__container-sound">
        <img className="dog-details__sound" src={sonido} alt="" />
        <div className="audioplayer">
          <Slider percentage={percentage} onChange={onChange} />
          <audio
            ref={audioRef}
            onTimeUpdate={getCurrDuration}
            onLoadedData={(e) => {
              setDuration(e.currentTarget.duration.toFixed(2));
            }}
            src={dog.audio}
          ></audio>
          <ControlPanel
            play={play}
            isPlaying={isPlaying}
            duration={duration}
            currentTime={currentTime}
          />
        </div>
      </div>

      <div className="dog-details__logos">
        <img
          className="dog-details__logos__logo"
          src={logobyp}
          alt="Bilz y Pap"
        />
        <img
          className="dog-details__logos__logo"
          src={dog.foundationImage}
          alt="Fundación"
        />
      </div>
    </div>
  );
};

export default DogDetails;
