import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-3d-carousel";

import MarkersMap from "./Markers&map";

import LogoHeader from './assets/images/logo-byp.png'
import BgMobile from './assets/images/ByP_Landing-Teleton_Hero-Mobile.png'
import PuntosLimpios from './assets/images/puntos-limpios.png'
import CountBottle from './assets/images/contador-alone.png'
import TitleDevices from './assets/images/devices.png'
import ModoBilzyPap from './assets/images/modobilzypap.png'
import ManoBilly from './assets/images/mano-bily.png'
import ManoMike from './assets/images/mano-mike.png'
import FooterImage from './assets/images/bym.png'
import BgDesktop from './assets/images/image-hero.png'
import PtosLimpiosDesktop from './assets/images/contadorDesktop.png'
import titlePtsLimpios from './assets/images/puntoslimpios.png'
import SkateMike from './assets/images/mikeskate.png'
import SkateBilly from './assets/images/bilyskate.png'
import BgVideo from './assets/images/bgVideo.png'
import ContadorAlone from './assets/images/contador-alone.png'

import IconFb from './assets/images/FB.png'
import IconIg from './assets/images/IG.png'
import IconTt from './assets/images/TikTok.png'
import IconYt from './assets/images/Youtube.png'

import Gallery1 from './assets/images/Fotos-Carrusel-01.jpg'
import Gallery2 from "./assets/images/Fotos-Carrusel-02.jpg";
import Gallery3 from "./assets/images/Fotos-Carrusel-03.jpg";
import Gallery4 from "./assets/images/Fotos-Carrusel-04.jpg";
import Gallery5 from "./assets/images/Fotos-Carrusel-5.jpg";
import Gallery6 from "./assets/images/Fotos-Carrusel-6.jpg";
import Gallery7 from "./assets/images/Fotos-Carrusel-7.jpg";
import Gallery8 from "./assets/images/Fotos-Carrusel-8.jpg";

import './index.scss'

export const Teleton2023 = ({ hideHeader, handle }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [cellData, setCellData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = "AIzaSyDC3JnpK2QPw4eqH2EOpclXadMljtn-21c";

        const spreadsheetId = "1ClbiFI7K46wlaLUVdCt0b_Oau__GDfhV-rs1LqYoYRo";
        const range = "Contador!A2";

        const response = await fetch(
          `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?key=${apiKey}`
        );

        if (response.ok) {
          const data = await response.json();
          const cellValue = data.values[0][0];

          setCellData(cellValue);
        } else {
          console.error("Error al obtener datos de Google Sheets");
        }
      } catch (error) {
        console.error("Error en la solicitud a Google Sheets API:", error);
      }
    };

    fetchData();

    handle(false);
    return () => handle(true);
  }, [handle]);

  useEffect(() => {
    hideHeader(false);
    return () => hideHeader(true);
  }, [hideHeader]);

  return (
    <>
      <header className="header-teleton">
        <div className="content-header-teleton">
          <Link to="/">
            <img src={LogoHeader} alt="" />
          </Link>

          <nav className={openMenu ? "active" : ""}>
            <ul>
              <li className="active" onClick={(e) => setOpenMenu(!openMenu)}>
                Tarea teleton
              </li>
              <li className="desktop">
                <a href="#contador" onClick={(e) => setOpenMenu(!openMenu)}>
                  Contador de botellas
                </a>
              </li>

              <li className="mobile">
                <a
                  href="#countBottleMobile"
                  onClick={(e) => setOpenMenu(!openMenu)}
                >
                  Contador de botellas
                </a>
              </li>
              <li>
                <a
                  href="#puntosLimpios"
                  onClick={(e) => setOpenMenu(!openMenu)}
                >
                  Puntos limpios
                </a>
              </li>
              <li>
                <a href="#devices" onClick={(e) => setOpenMenu(!openMenu)}>
                  {" "}
                  Dispositivos
                </a>
              </li>
              <li>
                <a href="#modobilzypap" onClick={(e) => setOpenMenu(!openMenu)}>
                  ModoBilzyPap
                </a>
              </li>
            </ul>
          </nav>

          <button onClick={(e) => setOpenMenu(!openMenu)}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </header>

      {/* Mobiles */}
      <section className="hero-teleton">
        <div className="content-hero-teleton">
          <img src={BgMobile} alt="" />

          {/* <div className="logo-campania">
            <img src={LogoCampania} alt="" />
          </div> */}
        </div>
      </section>

      {/* deksopts */}
      <div className="section-hero-teletonDesktop">
        <div className="content-hero-teleton">
          <img className="background" src={BgDesktop} alt="" />
        </div>
      </div>

      <section className="sector-clean" id="contador">
        <div className="content-sector-clean">
          <img className="ImageDesktop" src={PtosLimpiosDesktop} alt="" />
          <img className="ContadorFloat" src={ContadorAlone} alt="" />
          <span className="numberCountBottleFloat">{cellData}</span>
        </div>

        <div className="text-pts-limpios">
          <img src={titlePtsLimpios} alt="" />
          <p>
            Encuentra en el mapa tu punto limpio más cercano y únete con tu
            familia para reciclar en #ModoBilzyPap.
          </p>
        </div>
      </section>

      <section className="map-sector-clean" id="puntosLimpios">
        <div className="content-map-section-clean">
          <MarkersMap />
        </div>
      </section>

      <section className="sector-clean">
        <div className="content-sector-clean">
          <img className="ImageMobile" src={PuntosLimpios} alt="" />
        </div>
      </section>

      <section className="counter-bottle" id="devices">
        <img
          className="countBottle"
          src={CountBottle}
          alt=""
          id="countBottleMobile"
        />
        <span className="numberCountBottle">{cellData}</span>
        <img src={SkateMike} alt="" className="skate-mike" />
        <img className="titleDevices" src={TitleDevices} alt="" />
        <img src={SkateBilly} alt="" className="skate-billy" />

        <p>
          ¡Recicla tus botellas para ayudar a los niños de la Teleton! <br />{" "}
          Con cada una y la ayuda de una impresora 3D, haremos implementos que
          les darán la posibilidad de maniobrar más fácilmente elementos como un
          lápiz para dibujar.
        </p>

        <img className="bgVideo" src={BgVideo} alt="" />

        <div className="container-video">
          {/* <img src={PlaceholderVideo} alt="" /> */}
          <div className="video-responsive">
            <iframe
              src="https://www.youtube.com/embed/MR1MzkoJLdw?si=KOk7mjr-VNiY7Txy&amp;controls=0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>

      <section className="gallery-influencers" id="modobilzypap">
        <div className="content-gallery-influencers">
          <img className="HandBilly" src={ManoBilly} alt="" />
          <img className="titleModo" src={ModoBilzyPap} alt="" />
          <img className="HandMike" src={ManoMike} alt="" />

          <p>
            Nuestros influencers se sumaron a la tarea y activaron el
            #ModoBilzyPap <br /> ¡Súmate tú también usando nuestro hashtag!
          </p>

          <Carousel showStatus={false} autoPlay={false} width="300px">
            <div className="content-image">
              <img src={Gallery1} alt="example-image-1" />
              <div className="envolve-yellow"></div>
              <div className="envolver-username">
                <a
                  href="https://instagram.com/exhuevo"
                  target="_blank"
                  rel="noreferrer"
                >
                  @Daniel Fuenzalida
                </a>
              </div>
            </div>

            <div className="content-image">
              <img src={Gallery2} alt="example-image-2" />
              <div className="envolve-yellow"></div>
              <div className="envolver-username">
                <a
                  href="https://instagram.com/franciscosaavedr"
                  target="_blank"
                  rel="noreferrer"
                >
                  @Francisco Saavedra
                </a>
              </div>
            </div>

            <div className="content-image">
              <img src={Gallery3} alt="example-image-3" />
              <div className="envolve-yellow"></div>
              <div className="envolver-username">
                <a
                  href="https://instagram.com/tatiifernandez"
                  target="_blank"
                  rel="noreferrer"
                >
                  @Tati Fernandez
                </a>
                <a
                  href="https://instagram.com/oliverbornercerda"
                  target="_blank"
                  rel="noreferrer"
                >
                  @Oliver Borner
                </a>
              </div>
            </div>

            <div className="content-image">
              <img src={Gallery4} alt="example-image-4" />
              <div className="envolve-yellow"></div>
              <div className="envolver-username">
                <a
                  href="https://instagram.com/karendtv"
                  target="_blank"
                  rel="noreferrer"
                >
                  @Karen Doggenweiler
                </a>
              </div>
            </div>

            <div className="content-image">
              <img src={Gallery5} alt="example-image-5" />
              <div className="envolve-yellow"></div>
              <div className="envolver-username">
                <a
                  href="https://instagram.com/jaimeproox"
                  target="_blank"
                  rel="noreferrer"
                >
                  @Jaime Proox
                </a>
                <a
                  href="https://instagram.com/rooosinelli"
                  target="_blank"
                  rel="noreferrer"
                >
                  @Rosinelli
                </a>
              </div>
            </div>

            <div className="content-image">
              <img src={Gallery6} alt="example-image-5" />
              <div className="envolve-yellow"></div>
              <div className="envolver-username">
                <a
                  href="https://instagram.com/rodrigogallina"
                  target="_blank"
                  rel="noreferrer"
                >
                  @Rodrigo Aviles
                </a>
              </div>
            </div>

            <div className="content-image">
              <img src={Gallery7} alt="example-image-5" />
              <div className="envolve-yellow"></div>
              <div className="envolver-username">
                <a
                  href="https://instagram.com/ignaciaa_antonia"
                  target="_blank"
                  rel="noreferrer"
                >
                  @Ignacia Antonia
                </a>
              </div>
            </div>

            <div className="content-image">
              <img src={Gallery8} alt="example-image-5" />
              <div className="envolve-yellow"></div>
              <div className="envolver-username">
                <a
                  href="https://instagram.com/marigodoyibanez"
                  target="_blank"
                  rel="noreferrer"
                >
                  @Maria Luisa Godoy
                </a>
              </div>
            </div>
          </Carousel>
        </div>
      </section>

      <footer>
        <div className="content-footer">
          <img src={FooterImage} alt="" />
        </div>

        <div className="content-rrss">
          <ul>
            <li>
              <a
                href="https://www.instagram.com/bilzypapcl/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={IconIg} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@bilzypapcl?"
                target="_blank"
                rel="noreferrer"
              >
                <img src={IconTt} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/BilzyPapcl"
                target="_blank"
                rel="noreferrer"
              >
                <img src={IconFb} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/bilyymaik"
                target="_blank"
                rel="noreferrer"
              >
                <img src={IconYt} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};
