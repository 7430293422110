import React from "react";
import "./planetHome.scss";

export const PlanetHome = (props) => {
  return (
    <div className="planet__home">
       
      <img className="academy__img" src={props.planet} alt="" />
      <div className="academy__text">
        <p>{props.text}</p>
      </div>
      
      <img className="recycling__img" src={props.planetrecycling} alt="" />
      <div className="recycling__text">
        <p>{props.textrecycling}</p>
      </div>  
      
    </div>
  );
};
