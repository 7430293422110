import React from "react";
import './styles/style.scss';
import { Routes } from './Router/Routes';

function App() {
  return (
    <Routes />
  );
}

export default App;
