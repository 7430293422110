import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

// import emailjs from "emailjs-com";

import { Sucess } from "./Sucess";

import "./form.scss";

export const Form = ({ onFinished }) => {
  const [modalShow, setModalShow] = useState(false);
  const [datos, setDatos] = useState({
    nameForm: "",
    emailForm: "",
  });

  const courseInLs = localStorage.getItem("courseMode");
  const imgKitchen = "../../assets/img/diploma-cocina.jpg";
  const imgScience = "../../assets/img/diploma-ciencias.jpg";
  const imgTikTok = "../../assets/img/diploma-tiktok.jpg";
  const imgCraft = "../../assets/img/diploma-manualidades.jpg";

  // const sendEmail = async () => {
  //   await emailjs
  //     .send(
  //       "service_gwvbyxb",
  //       "template_owu6p9l",
  //       "asdasd",
  //       "user_YytPzSHhiybT2h3gEvUUR"
  //     )
  //     .then(console.log("exito email"))
  //     .catch(console.log("error email"));
  // };

  const modalSucess = () => {
    setModalShow(!modalShow);
  };

  const closeModal = () => {
    setModalShow(false);
  };

  const getFormName = () => {
    const storedValue = sessionStorage.getItem("nameForm");
    if (!storedValue) return { nameForm: "" };
    const storeData = JSON.parse(storedValue);
    return storeData.nameForm;
  };

  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const enviarDatos = async (event) => {
    event.preventDefault();
    event.target.reset();

    fetch("https://hooks.zapier.com/hooks/catch/2792106/bt0gtoo/", {
      method: "POST",
      body: JSON.stringify(datos),
    })
      .then(async (response) => {
        modalSucess();
        renderCertified(courseInLs);
      })
      .catch((err) => {
        console.log("Algo sucedió");
      });
  };

  const renderCertified = (param) => {
    const nameInLS = getFormName();

    switch (param) {
      case "kitchen":
        <div className="container__certificate">
          <h2 id="name">{nameInLS}</h2>
          <canvas
            id="canvas"
            style={{
              backgroundImage: imgKitchen,
            }}
          />
        </div>;

        return console.log(imgKitchen, nameInLS);
      case "science":
        <div className="container__certificate">
          <h2 id="name">{nameInLS}</h2>
          <canvas
            id="canvas"
            style={{
              backgroundImage: imgScience,
            }}
          />
        </div>;

        return console.log(imgScience, nameInLS);
      case "crafts":
        <div className="container__certificate">
          <h2 id="name">{nameInLS}</h2>
          <canvas
            id="canvas"
            style={{
              backgroundImage: imgCraft,
            }}
          />
        </div>;

        return console.log(imgCraft, nameInLS);
      case "tiktok":
        <div className="container__certificate">
          <h2 id="name">{nameInLS}</h2>
          <canvas
            id="canvas"
            style={{
              backgroundImage: imgTikTok,
            }}
          />
        </div>;

        return console.log(imgTikTok, nameInLS);
      default:
        return "";
    }
  };

  useEffect(() => {
    sessionStorage.setItem("nameForm", JSON.stringify(datos));
  }, [datos]);

  return (
    <div className="layout__formAcademy">
      <NavLink activeClassName="is-active" to="/cursos">
        <div
          className={`modalSucess${modalShow ? " active" : ""}`}
          onClick={closeModal}
        >
          <Sucess />
        </div>
      </NavLink>

      <form className="form" onSubmit={enviarDatos} action="">
        <div className="group-input">
          <label htmlFor="">Nombre:</label>
          <input
            type="text"
            name="nameForm"
            id=""
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="group-input">
          <label htmlFor="">Mail:</label>
          <input
            type="email"
            name="emailForm"
            id=""
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="group-checkbox">
          <input
            type="checkbox"
            name=""
            id=""
            onChange={handleInputChange}
            required
          />
          <label htmlFor="">
            <a
              href="https://sitio.bilzypap.cl/bases/bases.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Acepto los términos y condiciones
            </a>
          </label>
        </div>
        <div className="form__wrap__button">
          <button type="submit">Enviar</button>
        </div>
      </form>

      <button
        onClick={() => onFinished("questionary")}
        className="return return--form"
      >
        <img
          src="https://user-images.githubusercontent.com/55818246/138624865-eb5ec3e3-586d-4a60-8364-b1747c38174c.png"
          alt=""
        />
      </button>
    </div>
  );
};
