import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import "./mascotas.scss";
// Aquí se importan las imagenes (son aprox 15 y 3 por css) ir descomentando a medida de uso
import backBtn from "../../assets/img/mascotas/back-btn-min.png";
import collaborationTitle from "../../assets/img/mascotas/collaboration-title-min.png";
import collaborationImg from "../../assets/img/mascotas/collaboration-img-min.png";
import logoBYP from "../../assets/img/animales/logobyp.png";
import logoMascotas from "../../assets/img/mascotas/logo-mascotas-min.png";
// import bajadaLogoMascotas from "../../assets/img/mascotas/bajada-logo-mascotas-min.png";
import burgerIcon from "../../assets/img/mascotas/burger-icon.png";
import fecha from "../../assets/img/mascotas/fecha-min.png";
import fechaHora from "../../assets/img/mascotas/lugar-hora.png";
import texto from "../../assets/img/mascotas/texto-min.png";
import asistencia from "../../assets/img/mascotas/asistencia-min.png";
import bgForm from "../../assets/img/mascotas/bg-form-min.png";
import bilyDog from "../../assets/img/mascotas/Bily-dog-min.png";
import maikCat from "../../assets/img/mascotas/Maik-cat-min.png";
import checkIcon from "../../assets/img/mascotas/check-btn-min.png";
import visita from "../../assets/img/mascotas/visita-min.png";
import fundacionAdopta from "../../assets/img/mascotas/fundacion-adopta-min.png";
import miradaAnimal from "../../assets/img/mascotas/mirada-animal-min.png";
import huellasUnidas from "../../assets/img/mascotas/fundacion-huellas-unidas-min.png";
import perrito from "../../assets/img/mascotas/perrito-footer-min.png";
import gatito from "../../assets/img/mascotas/gatito-footer-min.png";
import TYC from "../../assets/T&C-BilzyPap.pdf";
import ReactPixel from "react-facebook-pixel";

export const Mascotas = ({ handle, hideHeader }) => {
  useEffect(() => {
    const options = {
      autoConfig: false,
      debug: true,
    };
    ReactPixel.init("351626500304749", null, options);
    ReactPixel.pageView();
  }, []);
  const [showMenu, setShowMenu] = useState(false);

  let menuOpen = () => {
    setShowMenu(!showMenu);
  };

  const menuClose = () => {
    setShowMenu(false);
  };
  let useClickOutSide = (handler) => {
    let domNode = useRef();

    useEffect(() => {
      let beHandler = (event) => {
        if (domNode.current && !domNode.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", beHandler);
      return () => {
        document.removeEventListener("mousedown", beHandler);
      };
    });
    return domNode;
  };

  let domNode = useClickOutSide(() => {
    setShowMenu(false);
  });

  useEffect(() => {
    handle(false);
    return () => handle(true);
  }, [handle]);

  useEffect(() => {
    hideHeader(false);
    return () => hideHeader(true);
  }, [hideHeader]);

  const optionsDia = Array.from({ length: 31 }, (_, i) => i + 1); //opciones del 1 al 31
  const optionsMes = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const anioActual = new Date().getFullYear();
  const optionsAnio = Array.from(
    { length: anioActual - 1899 },
    (_, i) => anioActual - i
  ); //opciones desde el año actual hasta 1900

  const [dia, setDia] = useState("");
  const [mes, setMes] = useState("");
  const [anio, setAnio] = useState("");
  const [datos, setDatos] = useState({});

  const handleDiaChange = (event) => {
    event.preventDefault();
    setDia(event.target.value);
  };

  const handleMesChange = (event) => {
    setMes(event.target.value);
  };

  const handleAnioChange = (event) => {
    setAnio(event.target.value);
  };
  const handleInput = (e) => {
    console.log(e.target.name + " " + e.target.value);
    setDatos({ ...datos, [e.target.name]: e.target.value });
  };

  const sendData = (e) => {
    e.preventDefault(); //evita recargar la pagina
    const fechaNacimiento = `${dia}-${mes}-${anio}`; // crea la cadena de fecha en el formato "dia-mes-año"
    setDatos({
      ...datos,
      fecha_nacimiento: fechaNacimiento, // agrega la fecha de nacimiento al objeto datos
    });
    e.target.reset();
    console.log(datos);
    fetch("https://hooks.zapier.com/hooks/catch/2792106/3by1y4w/", {
      method: "POST",
      body: JSON.stringify(datos),
    })
      .then(async (response) => {
        window.location.href = "/mascotas-gracias";
        console.log("participando");
      })
      .catch((err) => {
        console.log("Error al enviar la informacion");
      });
  };

  return (
    <div className="bg-mascotas">
      <header
        className={`header-mascotas nav-menu${showMenu ? " actives" : ""}`}
        ref={domNode}
      >
        <a href="/" className="header-mascotas__logo">
          <img src={logoBYP} alt="Logo Bilz y Pap" />
        </a>
        <div className="header-mascotas__container-btn">
          <a href="/" className="header-mascotas__return">
            <img src={backBtn} alt="volver al home" /> Volver al home
          </a>
          <button
            title="menu"
            className="header-mascotas__btn-menu"
            onClick={menuOpen}
          >
            <img src={burgerIcon} alt="" />
          </button>
        </div>

        <nav className={`menu-navigation${showMenu ? " actives" : ""}`}>
          <div className="menu-teleton" onClick={() => setShowMenu(true)}></div>
          <ul>
            <li onClick={menuOpen}>
              <NavLink
                aria-current="page"
                activeclassname="is-active"
                onClick={menuClose}
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                activeclassname="is-active"
                onClick={menuClose}
                to="/luna-de-juegos"
              >
                Luna de juegos
              </NavLink>
            </li>
            <li>
              <NavLink
                activeclassname="is-active"
                onClick={menuClose}
                to="/academia-solar"
              >
                Academia Solar
              </NavLink>
            </li>
            <li>
              <NavLink
                activeclassname="is-active"
                onClick={menuClose}
                to="/kidzapalooza"
              >
                kidzapalooza
              </NavLink>
            </li>
            <li>
              <NavLink
                activeclassname="is-active"
                onClick={menuClose}
                to="/newsletter"
              >
                Suscríbete
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
      <main className="main-mascotas">
        <section className="section-mascotas--info">
          <div className="section-mascotas--info__top">
            <img
              src={logoMascotas}
              alt="Jornada de adopción - mascotas de otro mundo"
              className="section-mascotas--info__logo-mascotas"
            />
          </div>
          <div className="section-mascotas--info__bottom">
            <img
              src={fecha}
              alt="Sábado 11 de Marzo"
              className="section-mascotas--info__fecha"
            />
            <img
              src={fechaHora}
              alt="Parque padre hurtado de 11:00 a 17:00 horas"
              className="section-mascotas--info__direccion"
            />
            <img
              src={texto}
              alt="Cientos de mascotas en adopción - bandas en vivo - juegos para mascotas y muchas sorpresas"
              className="section-mascotas--info__text"
            />
          </div>
          <hr className="dotted-line" />
        </section>
        <section className="section-mascotas--form">
          <img
            src={asistencia}
            alt=""
            className="section-mascotas--form__text"
          />
          <div className="container">
            <form
              className="section-mascotas--form__form"
              onSubmit={sendData}
              required="required"
            >
              <img
                src={bgForm}
                alt=""
                className="section-mascotas--form__bg-form"
              />
              <div className="section-mascotas--form__container-inputs">
                <div className="group-input">
                  <label htmlFor="name">Nombre</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    onChange={handleInput}
                    required="required"
                  />
                </div>
                <div className="group-input">
                  <label htmlFor="birthdate">Fecha de nacimiento</label>
                  <div className="group-input__birthdate">
                    <select
                      value={dia}
                      name="day"
                      id="day"
                      onChange={(e) => {
                        handleInput(e);
                        handleDiaChange(e);
                      }}
                      required="required"
                    >
                      <option value="">Día</option>
                      {optionsDia.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <select
                      value={mes}
                      name="month"
                      id="month"
                      onChange={(e) => {
                        handleInput(e);
                        handleMesChange(e);
                      }}
                      required="required"
                    >
                      <option value="">Mes</option>
                      {optionsMes.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <select
                      value={anio}
                      name="year"
                      id="year"
                      onChange={(e) => {
                        handleInput(e);
                        handleAnioChange(e);
                      }}
                      required="required"
                    >
                      <option value="">Año</option>
                      {optionsAnio.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="group-input">
                  <label htmlFor="email">E-Mail</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={handleInput}
                    required="required"
                  />
                </div>
                <div className="group-input">
                  <label htmlFor="phone">Teléfono</label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    onChange={handleInput}
                    required="required"
                  />
                </div>
                <div className="group-check">
                  <label htmlFor="check">
                    Acepto los{" "}
                    <a href={TYC} target="_blank" rel="noopener noreferrer">
                      términos y condiciones.
                    </a>
                  </label>
                  <input
                    type="checkbox"
                    name="check"
                    id="check"
                    onChange={handleInput}
                    required="required"
                  />
                </div>
              </div>
              <button type="submit" className="section-mascotas--form__submit">
                Confirmar asistencia
                <img src={checkIcon} alt="" />
              </button>
            </form>
            <img
              src={bilyDog}
              alt=""
              className="section-mascotas--form__img section-mascotas--form__img--bily"
            />
            <img
              src={maikCat}
              alt=""
              className="section-mascotas--form__img section-mascotas--form__img--maik"
            />
          </div>
        </section>
        <section className="section-mascotas--foundations">
          <img
            src={visita}
            alt=""
            className="section-mascotas--foundations__text"
          />
          <ul className="section-mascotas--foundations__list">
            <li className="section-mascotas--foundations__list-item">
              <img src={fundacionAdopta} alt="" />
            </li>
            <li className="section-mascotas--foundations__list-item">
              <img src={miradaAnimal} alt="" />
            </li>
            <li className="section-mascotas--foundations__list-item">
              <img src={huellasUnidas} alt="" />
            </li>
          </ul>
        </section>
        <section className="section-mascotas--collaboration">
          <img
            src={collaborationTitle}
            alt=""
            className="section-mascotas--collaboration__title"
          />
          <img
            src={collaborationImg}
            alt=""
            className="section-mascotas--collaboration__img"
          />
        </section>
      </main>
      <footer className="footer-mascotas">
        <img src={perrito} alt="Perrito" className="footer-mascotas__dog" />
        <div className="footer-mascotas__container-main">
          <img src={logoBYP} alt="" className="footer-mascotas__logo" />
        </div>
        <img src={gatito} alt="Gatito" className="footer-mascotas__cat" />
      </footer>
    </div>
  );
};
